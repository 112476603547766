import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Geopoint as [longigute, latitude] (as float). */
  GeoPoint: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: string;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: number;
  /** The distance in meters (as float). */
  Distance: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: string;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: string;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * The upload of a file.
   *
   * Variables of this type must be set to null in mutations. They will be
   * replaced with a filename from a following multipart part containing a
   * binary file.
   *
   * See: https://github.com/jaydenseric/graphql-multipart-request-spec
   */
  UploadType: any;
};

/** Batch update input. */
export type BatchUpdateInput = {
  /** The attribute to update. */
  attr?: Maybe<Scalars['String']>;
  /** The new value as a json string. */
  value?: Maybe<Scalars['JSONString']>;
};

/** An enumeration. */
export enum CityOrigin {
  /** Manual Input */
  Manual = 'MANUAL',
  /** Correios */
  Correios = 'CORREIOS',
  /** Integration Import */
  Integration = 'INTEGRATION',
}

/** A city in a state. */
export type CityType = Node & {
  __typename?: 'CityType';
  /** The ID of the object. */
  id: Scalars['ID'];
  origin: CityOrigin;
  state: StateType;
  name: Scalars['String'];
  slug: Scalars['String'];
  geopoint?: Maybe<Scalars['GeoPoint']>;
};

export type CityTypeConnection = {
  __typename?: 'CityTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CityTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CityType` and its cursor. */
export type CityTypeEdge = {
  __typename?: 'CityTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CityType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A country in the world. */
export type CountryType = Node & {
  __typename?: 'CountryType';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
  slug: Scalars['String'];
};

export type CrmAlertArchiveMutationInput = {
  /** ID of the "CrmAlertType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Archive an alert. */
export type CrmAlertArchiveMutationPayload = {
  __typename?: 'CrmAlertArchiveMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  crmAlert?: Maybe<CrmAlertType>;
};

/** An enumeration. */
export enum CrmAlertCategory {
  /** Commercial: Building */
  CBuilding = 'C_BUILDING',
  /** Commercial: Clinic */
  CClinic = 'C_CLINIC',
  /** Commercial: Farm / Grange */
  CFarmGrange = 'C_FARM_GRANGE',
  /** Commercial: Hangar / Deposit / Warehouse */
  CHangarDepositWarehouse = 'C_HANGAR_DEPOSIT_WAREHOUSE',
  /** Commercial: Lot / Land */
  CLotLand = 'C_LOT_LAND',
  /** Commercial: Realty */
  CRealty = 'C_REALTY',
  /** Commercial: Room */
  CRoom = 'C_ROOM',
  /** Commercial: Store */
  CStore = 'C_STORE',
  /** Residential: Apartment */
  RApartment = 'R_APARTMENT',
  /** Residential: Condominium House */
  RCondominiumHouse = 'R_CONDOMINIUM_HOUSE',
  /** Residential: Condominium Land */
  RCondominiumLand = 'R_CONDOMINIUM_LAND',
  /** Residential: Country House / Villa */
  RCountryHouseVilla = 'R_COUNTRY_HOUSE_VILLA',
  /** Residential: Flat */
  RFlat = 'R_FLAT',
  /** Residential: House */
  RHouse = 'R_HOUSE',
  /** Residential: Kitnet */
  RKitnet = 'R_KITNET',
  /** Residential: Land */
  RLand = 'R_LAND',
  /** Residential: Loft */
  RLoft = 'R_LOFT',
  /** Vacation: Apartment */
  VApartment = 'V_APARTMENT',
  /** Vacation: House */
  VHouse = 'V_HOUSE',
}

export type CrmAlertCreateMutationInput = {
  fingerprint?: Maybe<Scalars['UUID']>;
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  district?: Maybe<Scalars['String']>;
  category?: Maybe<CrmAlertCategory>;
  bedrooms?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
  garages?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Decimal']>;
  maxPrice?: Maybe<Scalars['Decimal']>;
  minArea?: Maybe<Scalars['Decimal']>;
  maxArea?: Maybe<Scalars['Decimal']>;
  city: Scalars['ID'];
  forSale?: Maybe<Scalars['Boolean']>;
  forRent?: Maybe<Scalars['Boolean']>;
  forExchange?: Maybe<Scalars['Boolean']>;
  forVacation?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Alert create mutation. */
export type CrmAlertCreateMutationPayload = {
  __typename?: 'CrmAlertCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  crmAlert?: Maybe<CrmAlertType>;
};

/** Crm alert. */
export type CrmAlertType = Node & {
  __typename?: 'CrmAlertType';
  /** The ID of the object. */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isArchived: Scalars['Boolean'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  ipAddress?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['UUID']>;
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  city: CityType;
  district?: Maybe<Scalars['String']>;
  category?: Maybe<CrmAlertCategory>;
  forSale: Scalars['Boolean'];
  forRent: Scalars['Boolean'];
  forExchange: Scalars['Boolean'];
  forVacation: Scalars['Boolean'];
  bedrooms?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
  garages?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Decimal']>;
  maxPrice?: Maybe<Scalars['Decimal']>;
  minArea?: Maybe<Scalars['Decimal']>;
  maxArea?: Maybe<Scalars['Decimal']>;
};

export type CrmInterestAnnounceCreateMutationInput = {
  fingerprint?: Maybe<Scalars['UUID']>;
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  kind?: Maybe<CrmInterestAnnounceKind>;
  phone?: Maybe<Scalars['String']>;
  cellphone?: Maybe<Scalars['String']>;
  doc?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Interested in announce create mutation. */
export type CrmInterestAnnounceCreateMutationPayload = {
  __typename?: 'CrmInterestAnnounceCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  crmInterestAnnounce?: Maybe<CrmInterestAnnounceType>;
};

/** An enumeration. */
export enum CrmInterestAnnounceKind {
  /** Individual */
  Individual = 'INDIVIDUAL',
  /** Broker */
  Broker = 'BROKER',
  /** Realestate */
  Realestate = 'REALESTATE',
  /** Incorporator */
  Incorporator = 'INCORPORATOR',
}

/** An interested in announce. */
export type CrmInterestAnnounceType = Node & {
  __typename?: 'CrmInterestAnnounceType';
  /** The ID of the object. */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isArchived: Scalars['Boolean'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  isEmailed: Scalars['Boolean'];
  isNotified: Scalars['Boolean'];
  ipAddress?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['UUID']>;
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  kind?: Maybe<CrmInterestAnnounceKind>;
  plan?: Maybe<PlanType>;
  phone?: Maybe<Scalars['String']>;
  cellphone?: Maybe<Scalars['String']>;
  doc?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type CrmInterestAnnounceTypeConnection = {
  __typename?: 'CrmInterestAnnounceTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CrmInterestAnnounceTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CrmInterestAnnounceType` and its cursor. */
export type CrmInterestAnnounceTypeEdge = {
  __typename?: 'CrmInterestAnnounceTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CrmInterestAnnounceType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CrmNewsletterArchiveMutationInput = {
  /** ID of the "CrmNewsletterType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Archive a newsletter. */
export type CrmNewsletterArchiveMutationPayload = {
  __typename?: 'CrmNewsletterArchiveMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  crmNewsletter?: Maybe<CrmNewsletterType>;
};

export type CrmNewsletterCreateMutationInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Newsletter create mutation. */
export type CrmNewsletterCreateMutationPayload = {
  __typename?: 'CrmNewsletterCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  crmNewsletter?: Maybe<CrmNewsletterType>;
};

/** Crm alert. */
export type CrmNewsletterType = Node & {
  __typename?: 'CrmNewsletterType';
  /** The ID of the object. */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isArchived: Scalars['Boolean'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
};

export type DistrictBatchUpdateInput = {
  /** List of values to normalize to. */
  updates: Array<Maybe<BatchUpdateInput>>;
  /** List of object ids. */
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Update products. */
export type DistrictBatchUpdatePayload = {
  __typename?: 'DistrictBatchUpdatePayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  district?: Maybe<Array<Maybe<DistrictType>>>;
};

export type DistrictNormBatchDeleteInput = {
  /** List of object ids. */
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Delete product kind norms. */
export type DistrictNormBatchDeletePayload = {
  __typename?: 'DistrictNormBatchDeletePayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  districtNorm?: Maybe<Array<Maybe<DistrictNormType>>>;
};

export type DistrictNormCreateInput = {
  norm: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Create a district norm. */
export type DistrictNormCreatePayload = {
  __typename?: 'DistrictNormCreatePayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  districtNorm?: Maybe<DistrictNormType>;
};

export type DistrictNormDeleteInput = {
  /** ID of the "DistrictNormType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Delete a district norm. */
export type DistrictNormDeletePayload = {
  __typename?: 'DistrictNormDeletePayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  districtNorm?: Maybe<DistrictNormType>;
};

/** District normalization. */
export type DistrictNormType = Node & {
  __typename?: 'DistrictNormType';
  /** The ID of the object. */
  id: Scalars['ID'];
  city: CityType;
  norm: Scalars['String'];
  normSlug?: Maybe<Scalars['String']>;
  /** The number of related objects. */
  relatedCount?: Maybe<Scalars['Int']>;
};

export type DistrictNormTypeConnection = {
  __typename?: 'DistrictNormTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DistrictNormTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DistrictNormType` and its cursor. */
export type DistrictNormTypeEdge = {
  __typename?: 'DistrictNormTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DistrictNormType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type DistrictNormUpdateInput = {
  norm?: Maybe<Scalars['String']>;
  /** ID of the "DistrictNormType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Update a district norm. */
export type DistrictNormUpdatePayload = {
  __typename?: 'DistrictNormUpdatePayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  districtNorm?: Maybe<DistrictNormType>;
};

/** A district in a city. */
export type DistrictType = Node & {
  __typename?: 'DistrictType';
  /** The ID of the object. */
  id: Scalars['ID'];
  origin: Scalars['String'];
  city: CityType;
  district: Scalars['String'];
  norm?: Maybe<Scalars['String']>;
  /** If this district is normalized. */
  isNormalized?: Maybe<Scalars['Boolean']>;
};

export type DistrictTypeConnection = {
  __typename?: 'DistrictTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DistrictTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DistrictType` and its cursor. */
export type DistrictTypeEdge = {
  __typename?: 'DistrictTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DistrictType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename?: 'DjangoDebug';
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename?: 'DjangoDebugSQL';
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String'];
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']>;
  /** Duration of this database query in seconds. */
  duration: Scalars['Float'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String'];
  /** JSON encoded database query parameters. */
  params: Scalars['String'];
  /** Start time of this database query. */
  startTime: Scalars['Float'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean'];
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']>;
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']>;
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']>;
};

export type IntegrationArchiveMutationInput = {
  /** ID of the "IntegrationType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Integration archive mutation. */
export type IntegrationArchiveMutationPayload = {
  __typename?: 'IntegrationArchiveMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  integration?: Maybe<IntegrationType>;
};

export type IntegrationCreateMutationInput = {
  name?: Maybe<Scalars['String']>;
  origin?: Maybe<IntegrationOrigin>;
  key?: Maybe<Scalars['String']>;
  preferredDistricts?: Maybe<Array<Scalars['String']>>;
  salePercentage?: Maybe<Scalars['Float']>;
  rentPercentage?: Maybe<Scalars['Float']>;
  priority?: Maybe<IntegrationPriority>;
  priceMinSale?: Maybe<Scalars['Int']>;
  priceMaxSale?: Maybe<Scalars['Int']>;
  priceMinRent?: Maybe<Scalars['Int']>;
  priceMaxRent?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  forSale?: Maybe<Scalars['Boolean']>;
  forRent?: Maybe<Scalars['Boolean']>;
  file?: Maybe<Scalars['UploadType']>;
  user: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Integration create mutation. */
export type IntegrationCreateMutationPayload = {
  __typename?: 'IntegrationCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  integration?: Maybe<IntegrationType>;
};

/** An enumeration. */
export enum IntegrationKind {
  /** Xml */
  Xml = 'XML',
  /** Json */
  Json = 'JSON',
}

/** An enumeration. */
export enum IntegrationOrigin {
  /** Generic */
  Generic = 'GENERIC',
  /** Imoview */
  Imoview = 'IMOVIEW',
  /** Ingaia */
  Ingaia = 'INGAIA',
  /** Jetimob */
  Jetimob = 'JETIMOB',
  /** Kurole */
  Kurole = 'KUROLE',
  /** RemaxPT */
  Remaxpt = 'REMAXPT',
  /** Simbo */
  Simbo = 'SIMBO',
  /** Union */
  Union = 'UNION',
  /** Vista */
  Vista = 'VISTA',
  /** Vrsync */
  Vrsync = 'VRSYNC',
  /** Zap */
  Zap = 'ZAP',
  /** MaisCorretores */
  MaisCorretores = 'MAISCORRETORES',
}

/** Integration origin. */
export type IntegrationOriginType = {
  __typename?: 'IntegrationOriginType';
  /** The integration name. */
  name?: Maybe<Scalars['String']>;
  /** The integration origin. */
  origin?: Maybe<Scalars['String']>;
  /** The integration kind. */
  kind?: Maybe<Scalars['String']>;
  /** If this integration needs a key. */
  key?: Maybe<Scalars['Boolean']>;
  /** The integration url. */
  url?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum IntegrationPriority {
  /** Last */
  Last = 'LAST',
  /** First */
  First = 'FIRST',
}

/** Integration status. */
export enum IntegrationStatus {
  Unknown = 'UNKNOWN',
  Working = 'WORKING',
  Empty = 'EMPTY',
  MissingPostalCodes = 'MISSING_POSTAL_CODES',
  Error = 'ERROR',
}

/** An integration with ERP systems. */
export type IntegrationType = Node & {
  __typename?: 'IntegrationType';
  /** The ID of the object. */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isArchived: Scalars['Boolean'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  kind: IntegrationKind;
  origin: IntegrationOrigin;
  key?: Maybe<Scalars['String']>;
  preferredDistricts: Array<Scalars['String']>;
  forSale: Scalars['Boolean'];
  forRent: Scalars['Boolean'];
  salePercentage?: Maybe<Scalars['Float']>;
  rentPercentage?: Maybe<Scalars['Float']>;
  priority: IntegrationPriority;
  priceMinSale: Scalars['Int'];
  priceMaxSale: Scalars['Int'];
  priceMinRent: Scalars['Int'];
  priceMaxRent: Scalars['Int'];
  processedAt?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user: UserType;
  data: Scalars['JSONString'];
  leadsData: Scalars['JSONString'];
  realties: RealtyTypeConnection;
  /** Integration status. */
  status: IntegrationStatus;
  /** Error message for latest processing. */
  error?: Maybe<Scalars['String']>;
  /** How many realties found in the lastest processing. */
  foundCount: Scalars['Int'];
  /** How many realties found without postal code in the lastest processing. */
  foundNoPostalCodeCount: Scalars['Int'];
};

/** An integration with ERP systems. */
export type IntegrationTypeRealtiesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  status_Istartswith?: Maybe<Scalars['String']>;
  status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  code?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  salePrice?: Maybe<Scalars['Float']>;
  salePrice_Lte?: Maybe<Scalars['Float']>;
  salePrice_Gte?: Maybe<Scalars['Float']>;
  rentPrice?: Maybe<Scalars['Float']>;
  rentPrice_Lte?: Maybe<Scalars['Float']>;
  rentPrice_Gte?: Maybe<Scalars['Float']>;
  condominiumPrice?: Maybe<Scalars['Float']>;
  condominiumPrice_Lte?: Maybe<Scalars['Float']>;
  condominiumPrice_Gte?: Maybe<Scalars['Float']>;
  iptuPrice?: Maybe<Scalars['Float']>;
  iptuPrice_Lte?: Maybe<Scalars['Float']>;
  iptuPrice_Gte?: Maybe<Scalars['Float']>;
  area?: Maybe<Scalars['Float']>;
  area_Lte?: Maybe<Scalars['Float']>;
  area_Gte?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Scalars['Int']>;
  bedrooms_Lte?: Maybe<Scalars['Int']>;
  bedrooms_Gte?: Maybe<Scalars['Int']>;
  suites?: Maybe<Scalars['Int']>;
  suites_Lte?: Maybe<Scalars['Int']>;
  suites_Gte?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
  bathrooms_Lte?: Maybe<Scalars['Int']>;
  bathrooms_Gte?: Maybe<Scalars['Int']>;
  garages?: Maybe<Scalars['Int']>;
  garages_Lte?: Maybe<Scalars['Int']>;
  garages_Gte?: Maybe<Scalars['Int']>;
  energyEfficiency?: Maybe<Scalars['String']>;
  isForSale?: Maybe<Scalars['Boolean']>;
  isForRent?: Maybe<Scalars['Boolean']>;
  isForExchange?: Maybe<Scalars['Boolean']>;
  isForVacation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<Scalars['ID']>;
  user_Kind?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  category_Istartswith?: Maybe<Scalars['String']>;
  category_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<Scalars['ID']>;
  location_Street?: Maybe<Scalars['String']>;
  location_City?: Maybe<Scalars['ID']>;
  location_City_State?: Maybe<Scalars['ID']>;
  location_City_State_Code?: Maybe<Scalars['String']>;
  location_City_State_Code_Iexact?: Maybe<Scalars['String']>;
  location_City_State_Slug?: Maybe<Scalars['String']>;
  venture?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  location_DistrictSlug?: Maybe<Scalars['String']>;
  location_DistrictSlug_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  location_City_Slug?: Maybe<Scalars['String']>;
};

export type IntegrationTypeConnection = {
  __typename?: 'IntegrationTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntegrationTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `IntegrationType` and its cursor. */
export type IntegrationTypeEdge = {
  __typename?: 'IntegrationTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<IntegrationType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type IntegrationUpdateMutationInput = {
  name?: Maybe<Scalars['String']>;
  origin?: Maybe<IntegrationOrigin>;
  key?: Maybe<Scalars['String']>;
  preferredDistricts?: Maybe<Array<Scalars['String']>>;
  salePercentage?: Maybe<Scalars['Float']>;
  rentPercentage?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  /** ID of the "IntegrationType" to mutate */
  id: Scalars['ID'];
  forSale?: Maybe<Scalars['Boolean']>;
  forRent?: Maybe<Scalars['Boolean']>;
  file?: Maybe<Scalars['UploadType']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Integration update mutation. */
export type IntegrationUpdateMutationPayload = {
  __typename?: 'IntegrationUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  integration?: Maybe<IntegrationType>;
};

/** Realty media optimized urls. */
export type LocationKeywordsType = {
  __typename?: 'LocationKeywordsType';
  /** The category of realty used for sitemap generation. */
  category?: Maybe<Scalars['String']>;
  /** The city. */
  city?: Maybe<Scalars['String']>;
  /** The district. */
  district?: Maybe<Scalars['String']>;
  /** How many realties in this location. */
  quantity?: Maybe<Scalars['Int']>;
  /** The district slug. */
  districtSlug?: Maybe<Scalars['String']>;
  /** The city slug. */
  citySlug?: Maybe<Scalars['String']>;
  /** The state slug. */
  stateSlug?: Maybe<Scalars['String']>;
  /** The finality of the realties in the location. */
  finality?: Maybe<Scalars['String']>;
};

/** Realty media optimized urls. */
export type LocationSitemapType = {
  __typename?: 'LocationSitemapType';
  /** The district slug. */
  districtSlug?: Maybe<Scalars['String']>;
  /** The city slug. */
  citySlug?: Maybe<Scalars['String']>;
  /** The state slug. */
  stateSlug?: Maybe<Scalars['String']>;
  /** The category of realty used for sitemap generation. */
  category?: Maybe<Scalars['String']>;
  /** The finality of the realties in the location. */
  finality?: Maybe<Scalars['String']>;
  /** When this result was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** An address in a city. */
export type LocationType = Node & {
  __typename?: 'LocationType';
  /** The ID of the object. */
  id: Scalars['ID'];
  postalCode?: Maybe<Scalars['String']>;
  city: CityType;
  street?: Maybe<Scalars['String']>;
  district: Scalars['String'];
  districtSlug: Scalars['String'];
  geopoint?: Maybe<Scalars['GeoPoint']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Update products. */
  ventureNameBatchUpdate?: Maybe<VentureNameBatchUpdatePayload>;
  /** Create a venture. */
  ventureCreate?: Maybe<VentureCreatePayload>;
  /** Update a venture. */
  ventureUpdate?: Maybe<VentureUpdatePayload>;
  /** Delete a venture. */
  ventureDelete?: Maybe<VentureDeletePayload>;
  /** Delete ventures in batch. */
  ventureBatchDelete?: Maybe<VentureBatchDeletePayload>;
  /** Search hit create mutation. */
  searchHitCreate?: Maybe<SearchHitCreateMutationPayload>;
  /**
   * Authenticates a user and returns token and user data.
   *
   * It overrides the default graphql_jwt.GObtainJSONWebToken to wrap potential
   * authentication errors in our Error type, which is consistent to how rest of
   * the mutation works.
   */
  tokenAuth?: Maybe<UserTokenCreatePayload>;
  /** Confirm if token is valid and also return user data. */
  tokenVerify?: Maybe<UserTokenVerifyPayload>;
  /** Generates a token to login the user. */
  userLogin?: Maybe<UserLoginMutationPayload>;
  /** User validate slug mutation. */
  userValidateSlug?: Maybe<UserValidateSlugMutationPayload>;
  /** Create a new user. */
  userCreate?: Maybe<UserCreateMutationPayload>;
  /** Change the user's password. */
  userChangePassword?: Maybe<UserChangePasswordMutationPayload>;
  /** User update mutation. */
  userUpdate?: Maybe<UserUpdateMutationPayload>;
  /** Activate a user's registration. */
  userActivate?: Maybe<UserActivateMutationPayload>;
  /** Change or create a new plan for the user. */
  userNewPlan?: Maybe<UserNewPlanMutationPayload>;
  /** Change the payment method of a user plan. */
  userChangePaymentMethod?: Maybe<UserChangePaymentMethodMutationPayload>;
  /** Request to reset a user's password. */
  userResetPassword?: Maybe<UserResetPasswordMutationPayload>;
  /** Confirm the request to reset a user's password. */
  userPasswordConfirm?: Maybe<UserResetPasswordConfirmMutationPayload>;
  /** Mark user's notification as read. */
  userNotificationsMarkRead?: Maybe<UserNotificationsMarkReadMutationPayload>;
  /** User plan create mutation. */
  userPlanCreate?: Maybe<UserPlanCreateMutationPayload>;
  /** User plan create mutation. */
  userPlanUpdate?: Maybe<UserPlanUpdateMutationPayload>;
  /** User plan create mutation. */
  userPlanCreateUser?: Maybe<UserPlanCreateUserMutationPayload>;
  /** User plan set password mutation. */
  userPlanSetPassword?: Maybe<UserPlanSetPasswordMutationPayload>;
  /** User plan set password mutation. */
  userPaymentMethodCreate?: Maybe<UserPaymentMethodCreateMutationPayload>;
  /** User plan set password mutation. */
  userPaymentMethodDelete?: Maybe<UserPaymentMethodDeleteMutationPayload>;
  /** Realty create mutation. */
  realtyCreate?: Maybe<RealtyCreateMutationPayload>;
  /** Realty update mutation. */
  realtyUpdate?: Maybe<RealtyUpdateMutationPayload>;
  /** Realty update mutation. */
  realtyUpdateManager?: Maybe<RealtyUpdateManagerMutationPayload>;
  /** Realty activate mutation. */
  realtyCurationSkip?: Maybe<RealtyCurationSkipMutationPayload>;
  /** Realty activate mutation. */
  realtyActivate?: Maybe<RealtyActivateMutationPayload>;
  /** Realty inactivate mutation. */
  realtyInactivate?: Maybe<RealtyInactivateMutationPayload>;
  /** Realty publish mutation. */
  realtyPublish?: Maybe<RealtyPublishMutationPayload>;
  /** Realty publish mutation. */
  realtyUnpublish?: Maybe<RealtyUnpublishMutationPayload>;
  /** Realty media create mutation. */
  realtyMediaCreate?: Maybe<RealtyMediaCreateMutationPayload>;
  /** Realty media update mutation. */
  realtyMediaUpdate?: Maybe<RealtyMediaUpdateMutationPayload>;
  /** Realty media delete mutation. */
  realtyMediaDelete?: Maybe<RealtyMediaDeleteMutationPayload>;
  /** Realty hit create mutation. */
  realtyHitCreate?: Maybe<RealtyHitCreateMutationPayload>;
  /** Realty lead create mutation. */
  realtyLeadCreate?: Maybe<RealtyLeadCreateMutationPayload>;
  /** Realty thread create mutation. */
  realtyThreadCreate?: Maybe<RealtyThreadCreateMutationPayload>;
  /** Realty thread mark as read mutation. */
  realtyThreadMarkRead?: Maybe<RealtyThreadMarkReadMutationPayload>;
  /** Realty thread archive mutation. */
  realtyThreadArchive?: Maybe<RealtyThreadArchiveMutationPayload>;
  /** Realty thread archive mutation. */
  realtyThreadUnarchive?: Maybe<RealtyThreadUnarchiveMutationPayload>;
  /** Realty thread message create mutation. */
  realtyThreadMessageCreate?: Maybe<RealtyThreadMessageCreateMutationPayload>;
  /** Realty thread message mark as read mutation. */
  realtyThreadMessageMarkRead?: Maybe<RealtyThreadMessageMarkReadMutationPayload>;
  /** Integration create mutation. */
  integrationCreate?: Maybe<IntegrationCreateMutationPayload>;
  /** Integration update mutation. */
  integrationUpdate?: Maybe<IntegrationUpdateMutationPayload>;
  /** Integration archive mutation. */
  integrationArchive?: Maybe<IntegrationArchiveMutationPayload>;
  /** Alert create mutation. */
  crmAlertCreate?: Maybe<CrmAlertCreateMutationPayload>;
  /** Archive an alert. */
  crmAlertArchive?: Maybe<CrmAlertArchiveMutationPayload>;
  /** Newsletter create mutation. */
  crmNewsletterCreate?: Maybe<CrmNewsletterCreateMutationPayload>;
  /** Archive a newsletter. */
  crmNewsletterArchive?: Maybe<CrmNewsletterArchiveMutationPayload>;
  /** Interested in announce create mutation. */
  crmInterestAnnounceCreate?: Maybe<CrmInterestAnnounceCreateMutationPayload>;
  /** Validate a phonenumber using libphonenumber. */
  phonenumberValidate?: Maybe<PhoneNumberValidateMutationPayload>;
  /** Update products. */
  districtBatchUpdate?: Maybe<DistrictBatchUpdatePayload>;
  /** Create a district norm. */
  districtNormCreate?: Maybe<DistrictNormCreatePayload>;
  /** Update a district norm. */
  districtNormUpdate?: Maybe<DistrictNormUpdatePayload>;
  /** Delete a district norm. */
  districtNormDelete?: Maybe<DistrictNormDeletePayload>;
  /** Delete product kind norms. */
  districtNormBatchDelete?: Maybe<DistrictNormBatchDeletePayload>;
  node?: Maybe<Node>;
};

export type MutationVentureNameBatchUpdateArgs = {
  input: VentureNameBatchUpdateInput;
};

export type MutationVentureCreateArgs = {
  input: VentureCreateInput;
};

export type MutationVentureUpdateArgs = {
  input: VentureUpdateInput;
};

export type MutationVentureDeleteArgs = {
  input: VentureDeleteInput;
};

export type MutationVentureBatchDeleteArgs = {
  input: VentureBatchDeleteInput;
};

export type MutationSearchHitCreateArgs = {
  input: SearchHitCreateMutationInput;
};

export type MutationTokenAuthArgs = {
  input: UserTokenCreateInput;
};

export type MutationTokenVerifyArgs = {
  input: UserTokenVerifyInput;
};

export type MutationUserLoginArgs = {
  input: UserLoginMutationInput;
};

export type MutationUserValidateSlugArgs = {
  input: UserValidateSlugMutationInput;
};

export type MutationUserCreateArgs = {
  input: UserCreateMutationInput;
};

export type MutationUserChangePasswordArgs = {
  input: UserChangePasswordMutationInput;
};

export type MutationUserUpdateArgs = {
  input: UserUpdateMutationInput;
};

export type MutationUserActivateArgs = {
  input: UserActivateMutationInput;
};

export type MutationUserNewPlanArgs = {
  input: UserNewPlanMutationInput;
};

export type MutationUserChangePaymentMethodArgs = {
  input: UserChangePaymentMethodMutationInput;
};

export type MutationUserResetPasswordArgs = {
  input: UserResetPasswordMutationInput;
};

export type MutationUserPasswordConfirmArgs = {
  input: UserResetPasswordConfirmMutationInput;
};

export type MutationUserNotificationsMarkReadArgs = {
  input: UserNotificationsMarkReadMutationInput;
};

export type MutationUserPlanCreateArgs = {
  input: UserPlanCreateMutationInput;
};

export type MutationUserPlanUpdateArgs = {
  input: UserPlanUpdateMutationInput;
};

export type MutationUserPlanCreateUserArgs = {
  input: UserPlanCreateUserMutationInput;
};

export type MutationUserPlanSetPasswordArgs = {
  input: UserPlanSetPasswordMutationInput;
};

export type MutationUserPaymentMethodCreateArgs = {
  input: UserPaymentMethodCreateMutationInput;
};

export type MutationUserPaymentMethodDeleteArgs = {
  input: UserPaymentMethodDeleteMutationInput;
};

export type MutationRealtyCreateArgs = {
  input: RealtyCreateMutationInput;
};

export type MutationRealtyUpdateArgs = {
  input: RealtyUpdateMutationInput;
};

export type MutationRealtyUpdateManagerArgs = {
  input: RealtyUpdateManagerMutationInput;
};

export type MutationRealtyCurationSkipArgs = {
  input: RealtyCurationSkipMutationInput;
};

export type MutationRealtyActivateArgs = {
  input: RealtyActivateMutationInput;
};

export type MutationRealtyInactivateArgs = {
  input: RealtyInactivateMutationInput;
};

export type MutationRealtyPublishArgs = {
  input: RealtyPublishMutationInput;
};

export type MutationRealtyUnpublishArgs = {
  input: RealtyUnpublishMutationInput;
};

export type MutationRealtyMediaCreateArgs = {
  input: RealtyMediaCreateMutationInput;
};

export type MutationRealtyMediaUpdateArgs = {
  input: RealtyMediaUpdateMutationInput;
};

export type MutationRealtyMediaDeleteArgs = {
  input: RealtyMediaDeleteMutationInput;
};

export type MutationRealtyHitCreateArgs = {
  input: RealtyHitCreateMutationInput;
};

export type MutationRealtyLeadCreateArgs = {
  input: RealtyLeadCreateMutationInput;
};

export type MutationRealtyThreadCreateArgs = {
  input: RealtyThreadCreateMutationInput;
};

export type MutationRealtyThreadMarkReadArgs = {
  input: RealtyThreadMarkReadMutationInput;
};

export type MutationRealtyThreadArchiveArgs = {
  input: RealtyThreadArchiveMutationInput;
};

export type MutationRealtyThreadUnarchiveArgs = {
  input: RealtyThreadUnarchiveMutationInput;
};

export type MutationRealtyThreadMessageCreateArgs = {
  input: RealtyThreadMessageCreateMutationInput;
};

export type MutationRealtyThreadMessageMarkReadArgs = {
  input: RealtyThreadMessageMarkReadMutationInput;
};

export type MutationIntegrationCreateArgs = {
  input: IntegrationCreateMutationInput;
};

export type MutationIntegrationUpdateArgs = {
  input: IntegrationUpdateMutationInput;
};

export type MutationIntegrationArchiveArgs = {
  input: IntegrationArchiveMutationInput;
};

export type MutationCrmAlertCreateArgs = {
  input: CrmAlertCreateMutationInput;
};

export type MutationCrmAlertArchiveArgs = {
  input: CrmAlertArchiveMutationInput;
};

export type MutationCrmNewsletterCreateArgs = {
  input: CrmNewsletterCreateMutationInput;
};

export type MutationCrmNewsletterArchiveArgs = {
  input: CrmNewsletterArchiveMutationInput;
};

export type MutationCrmInterestAnnounceCreateArgs = {
  input: CrmInterestAnnounceCreateMutationInput;
};

export type MutationPhonenumberValidateArgs = {
  input: PhoneNumberValidateMutationInput;
};

export type MutationDistrictBatchUpdateArgs = {
  input: DistrictBatchUpdateInput;
};

export type MutationDistrictNormCreateArgs = {
  input: DistrictNormCreateInput;
};

export type MutationDistrictNormUpdateArgs = {
  input: DistrictNormUpdateInput;
};

export type MutationDistrictNormDeleteArgs = {
  input: DistrictNormDeleteInput;
};

export type MutationDistrictNormBatchDeleteArgs = {
  input: DistrictNormBatchDeleteInput;
};

export type MutationNodeArgs = {
  id: Scalars['ID'];
};

/** An error that happened in a mutation. */
export type MutationErrorType = {
  __typename?: 'MutationErrorType';
  /** The field that caused the error, or `null` if it isn't associated with any particular field. */
  field?: Maybe<Scalars['String']>;
  /** The error message. */
  message?: Maybe<Scalars['String']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** Notification kind. */
export enum NotificationKind {
  Contact = 'CONTACT',
  Message = 'MESSAGE',
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type PhoneNumberValidateMutationInput = {
  /** The phonenumber to validate. */
  phonenumber: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Validate a phonenumber using libphonenumber. */
export type PhoneNumberValidateMutationPayload = {
  __typename?: 'PhoneNumberValidateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the phonenumber is valid or not. */
  isValid?: Maybe<Scalars['Boolean']>;
  /** The country code for the phonenumber. */
  countryCode?: Maybe<Scalars['String']>;
  /** The raw number without the country code. */
  nationalNumber?: Maybe<Scalars['String']>;
  /** The formatted phonenumber in national format. */
  national?: Maybe<Scalars['String']>;
  /** The formatted phonenumber in international format. */
  international?: Maybe<Scalars['String']>;
  /** The formatted phonenumber in E164 format. */
  e164?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PlanKind {
  /** Individual */
  Individual = 'INDIVIDUAL',
  /** Broker */
  Broker = 'BROKER',
  /** Realestate */
  Realestate = 'REALESTATE',
  /** Incorporator */
  Incorporator = 'INCORPORATOR',
}

/** A plan available for users to sign-up. */
export type PlanType = Node & {
  __typename?: 'PlanType';
  /** The ID of the object. */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isArchived: Scalars['Boolean'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  kind: PlanKind;
  code?: Maybe<Scalars['String']>;
  country?: Maybe<CountryType>;
  name: Scalars['String'];
  slug: Scalars['String'];
  public: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  price: Scalars['Decimal'];
  quantity: Scalars['Int'];
  featured?: Maybe<Scalars['Boolean']>;
  userPlans: UserPlanTypeConnection;
  interestAnnounces: CrmInterestAnnounceTypeConnection;
  /** The promotional price, if exists. */
  promotionPrice?: Maybe<Scalars['Decimal']>;
};

/** A plan available for users to sign-up. */
export type PlanTypeUserPlansArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  userKind?: Maybe<Scalars['String']>;
};

/** A plan available for users to sign-up. */
export type PlanTypeInterestAnnouncesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_Range?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
};

export type PlanTypeConnection = {
  __typename?: 'PlanTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PlanTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PlanType` and its cursor. */
export type PlanTypeEdge = {
  __typename?: 'PlanTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PlanType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Public user. */
export type PublicUserType = Node & {
  __typename?: 'PublicUserType';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  kind: UserKind;
  advertisingName?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  cellphone?: Maybe<Scalars['String']>;
  creci?: Maybe<Scalars['String']>;
  hasWhatsapp: Scalars['Boolean'];
  optimized: Scalars['Boolean'];
  city?: Maybe<CityType>;
  realtiesCached: Scalars['Int'];
  allowScheduling: Scalars['Boolean'];
  /** The user's full name. */
  fullName?: Maybe<Scalars['String']>;
  /** The user's avatar. */
  avatarUrl?: Maybe<Scalars['String']>;
  /** Optimized urls for different screen sizes. */
  optimizedAvatarUrls?: Maybe<UserOptimizedAvatarUrlType>;
  /** Latest realty modified date (only for sitemaps). */
  lastRealtyDate?: Maybe<Scalars['DateTime']>;
  /** Realties counters. */
  realtiesCounts: UserRealtiesCountType;
};

export type PublicUserTypeConnection = {
  __typename?: 'PublicUserTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PublicUserTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PublicUserType` and its cursor. */
export type PublicUserTypeEdge = {
  __typename?: 'PublicUserTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PublicUserType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** A venture given a slug. */
  venture?: Maybe<VentureType>;
  /** All districts in the database */
  ventures?: Maybe<VentureTypeConnection>;
  /** All users in the database without pagination. */
  venturesSitemap?: Maybe<Array<Maybe<VentureType>>>;
  /** All venture names in the database */
  ventureNames?: Maybe<VentureNameTypeConnection>;
  /** Search for addresses. */
  search?: Maybe<SearchTypeConnection>;
  /** Get a list of all available districts in the search. */
  searchDistricts?: Maybe<Array<Maybe<SearchType>>>;
  /** Top search results in the latest 30 days. */
  searchTopResults?: Maybe<Array<Maybe<SearchTopResultsType>>>;
  /** The currently logged user. */
  me?: Maybe<UserType>;
  /** User's payment methods. */
  myPaymentMethods?: Maybe<Array<Maybe<UserPaymentMethodType>>>;
  /** Simulate a change of plan. */
  userPlanChangeSimulation?: Maybe<UserPlanChangeSimulationType>;
  /** All user for the logged in salesperson. */
  userPlansSalesperson?: Maybe<UserPlanTypeConnection>;
  /** All active users in the database. */
  users?: Maybe<PublicUserTypeConnection>;
  /** The public profile of an user. */
  user?: Maybe<PublicUserType>;
  /** Private user's information for support. */
  userSupport?: Maybe<UserType>;
  /** The user's plan given a token. */
  userPlan?: Maybe<UserPlanType>;
  /** Unread notifications for the user. */
  userNotifications?: Maybe<Array<Maybe<UserNotificationType>>>;
  /** All users in the database without pagination. */
  usersSitemap?: Maybe<Array<Maybe<PublicUserType>>>;
  /** All realties in the database. */
  myRealties?: Maybe<RealtyTypeConnection>;
  /** All realties in the database. */
  realties?: Maybe<RealtyTypeConnection>;
  /** All realties in the database without pagination. */
  realtiesSitemap?: Maybe<Array<Maybe<RealtyType>>>;
  /** A realty in the database. */
  realty?: Maybe<RealtyType>;
  /** The realty's information for support. */
  realtySupport?: Maybe<RealtyType>;
  /** All realty medias in the database. */
  realtyMedias?: Maybe<RealtyMediaTypeConnection>;
  /** All realty contacts in the database. */
  realtyLeads?: Maybe<RealtyLeadTypeConnection>;
  /** All realty threads in the database. */
  realtyThreads?: Maybe<RealtyThreadTypeConnection>;
  /** All realty thread messages in the database. */
  realtyThreadMessages?: Maybe<RealtyThreadMessageTypeConnection>;
  /** Get a task to curate a realty. */
  realtyCurationTask?: Maybe<RealtyCurationTaskType>;
  /** Hits in the realty by a given period. */
  realtySummary?: Maybe<RealtySummaryType>;
  /** All realties next to a given location. */
  realtiesByProximity?: Maybe<RealtyByProximityType>;
  /** All plans in the database. */
  plans?: Maybe<PlanTypeConnection>;
  /** All plans in the database. */
  plansForCity?: Maybe<Array<Maybe<PlanType>>>;
  /** Get a plan by it's slug. */
  planBySlug?: Maybe<PlanType>;
  /** All integrations in the database. */
  integrations?: Maybe<IntegrationTypeConnection>;
  /** All available integrations. */
  integrationOrigins?: Maybe<Array<Maybe<IntegrationOriginType>>>;
  /** All cities in the database. */
  cities?: Maybe<CityTypeConnection>;
  /** All cities in the database for sales. */
  citiesForSales?: Maybe<Array<Maybe<CityType>>>;
  /** All locations in the database without pagination. */
  locationsSitemap?: Maybe<Array<Maybe<LocationSitemapType>>>;
  /** Locations for pagination. */
  locationsKeywords?: Maybe<Array<Maybe<LocationKeywordsType>>>;
  /** A location in. */
  locationByPostalCode?: Maybe<LocationType>;
  /** All districts in the database */
  districts?: Maybe<DistrictTypeConnection>;
  /** All district norms in the database */
  districtNorms?: Maybe<DistrictNormTypeConnection>;
  _debug?: Maybe<DjangoDebug>;
};

export type QueryVentureArgs = {
  id: Scalars['ID'];
};

export type QueryVenturesArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  isNormalized?: Maybe<Scalars['Boolean']>;
  location_City_Name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryVentureNamesArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  isNormalized?: Maybe<Scalars['Boolean']>;
  city_Name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QuerySearchArgs = {
  q: Scalars['String'];
  geosort?: Maybe<Scalars['GeoPoint']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  kind?: Maybe<Scalars['String']>;
  kind_Istartswith?: Maybe<Scalars['String']>;
  kind_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  city_Slug?: Maybe<Scalars['String']>;
  state_Slug?: Maybe<Scalars['String']>;
};

export type QuerySearchDistrictsArgs = {
  stateSlug: Scalars['String'];
  citySlug: Scalars['String'];
};

export type QuerySearchTopResultsArgs = {
  kind: SearchTopResultsKind;
  first?: Maybe<Scalars['Int']>;
};

export type QueryUserPlanChangeSimulationArgs = {
  userPlan: Scalars['ID'];
  plan: Scalars['ID'];
};

export type QueryUserPlansSalespersonArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  userKind?: Maybe<Scalars['String']>;
};

export type QueryUsersArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  kind?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryUserArgs = {
  slug: Scalars['String'];
};

export type QueryUserSupportArgs = {
  doc: Scalars['String'];
};

export type QueryUserPlanArgs = {
  id?: Maybe<Scalars['ID']>;
  token?: Maybe<Scalars['UUID']>;
};

export type QueryMyRealtiesArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  status_Istartswith?: Maybe<Scalars['String']>;
  status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  code?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  salePrice?: Maybe<Scalars['Float']>;
  salePrice_Lte?: Maybe<Scalars['Float']>;
  salePrice_Gte?: Maybe<Scalars['Float']>;
  rentPrice?: Maybe<Scalars['Float']>;
  rentPrice_Lte?: Maybe<Scalars['Float']>;
  rentPrice_Gte?: Maybe<Scalars['Float']>;
  condominiumPrice?: Maybe<Scalars['Float']>;
  condominiumPrice_Lte?: Maybe<Scalars['Float']>;
  condominiumPrice_Gte?: Maybe<Scalars['Float']>;
  iptuPrice?: Maybe<Scalars['Float']>;
  iptuPrice_Lte?: Maybe<Scalars['Float']>;
  iptuPrice_Gte?: Maybe<Scalars['Float']>;
  area?: Maybe<Scalars['Float']>;
  area_Lte?: Maybe<Scalars['Float']>;
  area_Gte?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Scalars['Int']>;
  bedrooms_Lte?: Maybe<Scalars['Int']>;
  bedrooms_Gte?: Maybe<Scalars['Int']>;
  suites?: Maybe<Scalars['Int']>;
  suites_Lte?: Maybe<Scalars['Int']>;
  suites_Gte?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
  bathrooms_Lte?: Maybe<Scalars['Int']>;
  bathrooms_Gte?: Maybe<Scalars['Int']>;
  garages?: Maybe<Scalars['Int']>;
  garages_Lte?: Maybe<Scalars['Int']>;
  garages_Gte?: Maybe<Scalars['Int']>;
  energyEfficiency?: Maybe<Scalars['String']>;
  isForSale?: Maybe<Scalars['Boolean']>;
  isForRent?: Maybe<Scalars['Boolean']>;
  isForExchange?: Maybe<Scalars['Boolean']>;
  isForVacation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<Scalars['ID']>;
  user_Kind?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  category_Istartswith?: Maybe<Scalars['String']>;
  category_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<Scalars['ID']>;
  location_Street?: Maybe<Scalars['String']>;
  location_City?: Maybe<Scalars['ID']>;
  location_City_State?: Maybe<Scalars['ID']>;
  location_City_State_Code?: Maybe<Scalars['String']>;
  location_City_State_Code_Iexact?: Maybe<Scalars['String']>;
  location_City_State_Slug?: Maybe<Scalars['String']>;
  venture?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  location_DistrictSlug?: Maybe<Scalars['String']>;
  location_DistrictSlug_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  location_City_Slug?: Maybe<Scalars['String']>;
};

export type QueryRealtiesArgs = {
  geosort?: Maybe<Scalars['GeoPoint']>;
  forPartnership?: Maybe<Scalars['Boolean']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  status_Istartswith?: Maybe<Scalars['String']>;
  status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  code?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  salePrice?: Maybe<Scalars['Float']>;
  salePrice_Lte?: Maybe<Scalars['Float']>;
  salePrice_Gte?: Maybe<Scalars['Float']>;
  rentPrice?: Maybe<Scalars['Float']>;
  rentPrice_Lte?: Maybe<Scalars['Float']>;
  rentPrice_Gte?: Maybe<Scalars['Float']>;
  condominiumPrice?: Maybe<Scalars['Float']>;
  condominiumPrice_Lte?: Maybe<Scalars['Float']>;
  condominiumPrice_Gte?: Maybe<Scalars['Float']>;
  iptuPrice?: Maybe<Scalars['Float']>;
  iptuPrice_Lte?: Maybe<Scalars['Float']>;
  iptuPrice_Gte?: Maybe<Scalars['Float']>;
  area?: Maybe<Scalars['Float']>;
  area_Lte?: Maybe<Scalars['Float']>;
  area_Gte?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Scalars['Int']>;
  bedrooms_Lte?: Maybe<Scalars['Int']>;
  bedrooms_Gte?: Maybe<Scalars['Int']>;
  suites?: Maybe<Scalars['Int']>;
  suites_Lte?: Maybe<Scalars['Int']>;
  suites_Gte?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
  bathrooms_Lte?: Maybe<Scalars['Int']>;
  bathrooms_Gte?: Maybe<Scalars['Int']>;
  garages?: Maybe<Scalars['Int']>;
  garages_Lte?: Maybe<Scalars['Int']>;
  garages_Gte?: Maybe<Scalars['Int']>;
  energyEfficiency?: Maybe<Scalars['String']>;
  isForSale?: Maybe<Scalars['Boolean']>;
  isForRent?: Maybe<Scalars['Boolean']>;
  isForExchange?: Maybe<Scalars['Boolean']>;
  isForVacation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<Scalars['ID']>;
  user_Kind?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  category_Istartswith?: Maybe<Scalars['String']>;
  category_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<Scalars['ID']>;
  location_Street?: Maybe<Scalars['String']>;
  location_City?: Maybe<Scalars['ID']>;
  location_City_State?: Maybe<Scalars['ID']>;
  location_City_State_Code?: Maybe<Scalars['String']>;
  location_City_State_Code_Iexact?: Maybe<Scalars['String']>;
  location_City_State_Slug?: Maybe<Scalars['String']>;
  venture?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  location_DistrictSlug?: Maybe<Scalars['String']>;
  location_DistrictSlug_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  location_City_Slug?: Maybe<Scalars['String']>;
};

export type QueryRealtiesSitemapArgs = {
  forPartnership?: Maybe<Scalars['Boolean']>;
};

export type QueryRealtyArgs = {
  id: Scalars['ID'];
};

export type QueryRealtySupportArgs = {
  doc: Scalars['String'];
  code: Scalars['String'];
};

export type QueryRealtyMediasArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  realty?: Maybe<Scalars['ID']>;
};

export type QueryRealtyLeadsArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  kind?: Maybe<Scalars['String']>;
  realty?: Maybe<Scalars['ID']>;
};

export type QueryRealtyThreadsArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  realty?: Maybe<Scalars['ID']>;
  sender?: Maybe<Scalars['ID']>;
  recipient?: Maybe<Scalars['ID']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryRealtyThreadMessagesArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  thread?: Maybe<Scalars['ID']>;
};

export type QueryRealtySummaryArgs = {
  start: Scalars['DateTime'];
  end?: Maybe<Scalars['DateTime']>;
  realty?: Maybe<Scalars['ID']>;
};

export type QueryRealtiesByProximityArgs = {
  forPartnership?: Maybe<Scalars['Boolean']>;
  stateSlug?: Maybe<Scalars['String']>;
  citySlug?: Maybe<Scalars['String']>;
  districtSlug?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  isForSale?: Maybe<Scalars['Boolean']>;
  isForRent?: Maybe<Scalars['Boolean']>;
  isForExchange?: Maybe<Scalars['Boolean']>;
  isForVacation?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
};

export type QueryPlansArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  public?: Maybe<Scalars['Boolean']>;
};

export type QueryPlansForCityArgs = {
  city: Scalars['ID'];
  kind: Scalars['String'];
};

export type QueryPlanBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryIntegrationsArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
};

export type QueryCitiesArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  state_Country_Name?: Maybe<Scalars['String']>;
  state_Country_Name_Icontains?: Maybe<Scalars['String']>;
  state_Country_Name_Istartswith?: Maybe<Scalars['String']>;
  state_Country_Code?: Maybe<Scalars['String']>;
  state_Name?: Maybe<Scalars['String']>;
  state_Name_Icontains?: Maybe<Scalars['String']>;
  state_Name_Istartswith?: Maybe<Scalars['String']>;
  state_Code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryLocationByPostalCodeArgs = {
  postalCode: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};

export type QueryDistrictsArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  city_Slug?: Maybe<Scalars['String']>;
  city_State_Slug?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  isNormalized?: Maybe<Scalars['Boolean']>;
  city_Name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryDistrictNormsArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  isNormalized?: Maybe<Scalars['Boolean']>;
  city_Name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RealtyActivateMutationInput = {
  /** ID of the "RealtyType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty activate mutation. */
export type RealtyActivateMutationPayload = {
  __typename?: 'RealtyActivateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realty?: Maybe<RealtyType>;
};

/** Realty by proximity kind. */
export enum RealtyByProximityKind {
  Proximity = 'PROXIMITY',
  Top = 'TOP',
}

/** Realties by proximity. */
export type RealtyByProximityType = {
  __typename?: 'RealtyByProximityType';
  /** The kind of the result. */
  kind?: Maybe<RealtyByProximityKind>;
  /** The realties found. */
  realties?: Maybe<Array<Maybe<RealtyType>>>;
};

export type RealtyCreateMutationInput = {
  /** Country if location is not given by Zip Code */
  country?: Maybe<Scalars['String']>;
  /** Manual zip code if location is not given by Zip Code */
  postalCode?: Maybe<Scalars['String']>;
  /** State if location is not given by Zip Code */
  state?: Maybe<Scalars['String']>;
  /** City if location is not given by Zip Code */
  city?: Maybe<Scalars['String']>;
  /** District if location is not given by Zip Code */
  district?: Maybe<Scalars['String']>;
  /** Street if location is not given by Zip Code */
  street?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  ventureName?: Maybe<Scalars['String']>;
  salePrice?: Maybe<Scalars['Decimal']>;
  rentPrice?: Maybe<Scalars['Decimal']>;
  vacationPrice?: Maybe<Scalars['Decimal']>;
  condominiumPrice?: Maybe<Scalars['Decimal']>;
  iptuPrice?: Maybe<Scalars['Decimal']>;
  category?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['Decimal']>;
  bedrooms?: Maybe<Scalars['Int']>;
  suites?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
  garages?: Maybe<Scalars['Int']>;
  energyEfficiency?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  tourUrl?: Maybe<Scalars['String']>;
  commercialDistrict?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  geopoint?: Maybe<Scalars['GeoPoint']>;
  ok?: Maybe<Scalars['Boolean']>;
  user: Scalars['ID'];
  venture?: Maybe<Scalars['ID']>;
  isForSale?: Maybe<Scalars['Boolean']>;
  isForRent?: Maybe<Scalars['Boolean']>;
  isForVacation?: Maybe<Scalars['Boolean']>;
  isForExchange?: Maybe<Scalars['Boolean']>;
  allowNegotiation?: Maybe<Scalars['Boolean']>;
  allowFinancing?: Maybe<Scalars['Boolean']>;
  fulltimeSecurity?: Maybe<Scalars['Boolean']>;
  swimmingPool?: Maybe<Scalars['Boolean']>;
  playground?: Maybe<Scalars['Boolean']>;
  greenArea?: Maybe<Scalars['Boolean']>;
  location: Scalars['ID'];
  highlighted?: Maybe<Scalars['Boolean']>;
  superHighlighted?: Maybe<Scalars['Boolean']>;
  avoidPublish?: Maybe<Scalars['Boolean']>;
  /** Set list of medias */
  medias?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty create mutation. */
export type RealtyCreateMutationPayload = {
  __typename?: 'RealtyCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realty?: Maybe<RealtyType>;
};

export type RealtyCurationSkipMutationInput = {
  /** ID of the "RealtyType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty activate mutation. */
export type RealtyCurationSkipMutationPayload = {
  __typename?: 'RealtyCurationSkipMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realty?: Maybe<RealtyType>;
};

/** Realty curation task. */
export type RealtyCurationTaskType = {
  __typename?: 'RealtyCurationTaskType';
  /** The current realty for curation. */
  realty?: Maybe<RealtyType>;
  /** The latest approved realty data. */
  previous?: Maybe<RealtyType>;
  /** Possible duplicates for this realty. */
  duplicates?: Maybe<RealtyTypeConnection>;
  /** Threads related to problems with this realty. */
  threads?: Maybe<RealtyThreadTypeConnection>;
  /** Max active realties for the user's plan */
  planMax?: Maybe<Scalars['Int']>;
  /** Current active realties in the user's plan */
  planCurrent?: Maybe<Scalars['Int']>;
  /** Available slots for active realties in the user's plan */
  planAvailable?: Maybe<Scalars['Int']>;
};

/** Realty curation task. */
export type RealtyCurationTaskTypeDuplicatesArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  status_Istartswith?: Maybe<Scalars['String']>;
  status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  code?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  salePrice?: Maybe<Scalars['Float']>;
  salePrice_Lte?: Maybe<Scalars['Float']>;
  salePrice_Gte?: Maybe<Scalars['Float']>;
  rentPrice?: Maybe<Scalars['Float']>;
  rentPrice_Lte?: Maybe<Scalars['Float']>;
  rentPrice_Gte?: Maybe<Scalars['Float']>;
  condominiumPrice?: Maybe<Scalars['Float']>;
  condominiumPrice_Lte?: Maybe<Scalars['Float']>;
  condominiumPrice_Gte?: Maybe<Scalars['Float']>;
  iptuPrice?: Maybe<Scalars['Float']>;
  iptuPrice_Lte?: Maybe<Scalars['Float']>;
  iptuPrice_Gte?: Maybe<Scalars['Float']>;
  area?: Maybe<Scalars['Float']>;
  area_Lte?: Maybe<Scalars['Float']>;
  area_Gte?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Scalars['Int']>;
  bedrooms_Lte?: Maybe<Scalars['Int']>;
  bedrooms_Gte?: Maybe<Scalars['Int']>;
  suites?: Maybe<Scalars['Int']>;
  suites_Lte?: Maybe<Scalars['Int']>;
  suites_Gte?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
  bathrooms_Lte?: Maybe<Scalars['Int']>;
  bathrooms_Gte?: Maybe<Scalars['Int']>;
  garages?: Maybe<Scalars['Int']>;
  garages_Lte?: Maybe<Scalars['Int']>;
  garages_Gte?: Maybe<Scalars['Int']>;
  energyEfficiency?: Maybe<Scalars['String']>;
  isForSale?: Maybe<Scalars['Boolean']>;
  isForRent?: Maybe<Scalars['Boolean']>;
  isForExchange?: Maybe<Scalars['Boolean']>;
  isForVacation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<Scalars['ID']>;
  user_Kind?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  category_Istartswith?: Maybe<Scalars['String']>;
  category_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<Scalars['ID']>;
  location_Street?: Maybe<Scalars['String']>;
  location_City?: Maybe<Scalars['ID']>;
  location_City_State?: Maybe<Scalars['ID']>;
  location_City_State_Code?: Maybe<Scalars['String']>;
  location_City_State_Code_Iexact?: Maybe<Scalars['String']>;
  location_City_State_Slug?: Maybe<Scalars['String']>;
  venture?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  location_DistrictSlug?: Maybe<Scalars['String']>;
  location_DistrictSlug_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  location_City_Slug?: Maybe<Scalars['String']>;
};

/** Realty curation task. */
export type RealtyCurationTaskTypeThreadsArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  realty?: Maybe<Scalars['ID']>;
  sender?: Maybe<Scalars['ID']>;
  recipient?: Maybe<Scalars['ID']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
};

/** Realty filters helper. */
export type RealtyFiltersType = {
  __typename?: 'RealtyFiltersType';
  /** Any of the realties have fulltime security. */
  fulltimeSecurity?: Maybe<Scalars['Boolean']>;
  /** Any of the realties have playground. */
  playground?: Maybe<Scalars['Boolean']>;
  /** Any of the realties have green area. */
  greenArea?: Maybe<Scalars['Boolean']>;
  /** Max sale price. */
  maxSalePrice?: Maybe<Scalars['Decimal']>;
  /** Min sale price. */
  minSalePrice?: Maybe<Scalars['Decimal']>;
  /** Max rent price. */
  maxRentPrice?: Maybe<Scalars['Decimal']>;
  /** Min rent price. */
  minRentPrice?: Maybe<Scalars['Decimal']>;
  /** Max vacation price. */
  maxVacationPrice?: Maybe<Scalars['Decimal']>;
  /** Min vacation price. */
  minVacationPrice?: Maybe<Scalars['Decimal']>;
  /** Max condominium price. */
  maxCondominiumPrice?: Maybe<Scalars['Decimal']>;
  /** Min condominium price. */
  minCondomoniumPrice?: Maybe<Scalars['Decimal']>;
  /** Max area. */
  maxArea?: Maybe<Scalars['Decimal']>;
  /** Min area. */
  minArea?: Maybe<Scalars['Decimal']>;
  /** Max bedrooms. */
  maxBedrooms?: Maybe<Scalars['Int']>;
  /** Min bedrooms. */
  minBedrooms?: Maybe<Scalars['Int']>;
  /** Max bathrooms. */
  maxBathrooms?: Maybe<Scalars['Int']>;
  /** Min bathrooms. */
  minBathrooms?: Maybe<Scalars['Int']>;
  /** Max suites. */
  maxSuites?: Maybe<Scalars['Int']>;
  /** Min suites. */
  minSuites?: Maybe<Scalars['Int']>;
  /** Max garages. */
  maxGarages?: Maybe<Scalars['Int']>;
  /** Min garages. */
  minGarages?: Maybe<Scalars['Int']>;
};

export type RealtyHitCreateMutationInput = {
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  realty: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty hit create mutation. */
export type RealtyHitCreateMutationPayload = {
  __typename?: 'RealtyHitCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realtyHit?: Maybe<RealtyHitType>;
};

/** Realty hits by day. */
export type RealtyHitsByDayType = {
  __typename?: 'RealtyHitsByDayType';
  /** Total hits in the day. */
  hits?: Maybe<Scalars['Int']>;
  /** The day that the hits were made */
  day?: Maybe<Scalars['Date']>;
};

/** A realty page view. */
export type RealtyHitType = Node & {
  __typename?: 'RealtyHitType';
  /** The ID of the object. */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  ipAddress?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['UUID']>;
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  realty: RealtyType;
};

export type RealtyInactivateMutationInput = {
  /** ID of the "RealtyType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty inactivate mutation. */
export type RealtyInactivateMutationPayload = {
  __typename?: 'RealtyInactivateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realty?: Maybe<RealtyType>;
};

/** Realty media optimized urls. */
export type RealtyInactiveReasonType = {
  __typename?: 'RealtyInactiveReasonType';
  /** If this is an automatic curation. */
  automatic?: Maybe<Scalars['Boolean']>;
  /** The latest message from the curator. */
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The enumerable reason to why this realty was rejected. */
  problem?: Maybe<RealtyThreadProblem>;
  /** The latest message from the curator. */
  msg?: Maybe<Scalars['String']>;
};

export type RealtyLeadCreateMutationInput = {
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  kind: RealtyLeadKind;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  msg?: Maybe<Scalars['String']>;
  finality: RealtyLeadFinality;
  realty: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty lead create mutation. */
export type RealtyLeadCreateMutationPayload = {
  __typename?: 'RealtyLeadCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realtyLead?: Maybe<RealtyLeadType>;
};

/** An enumeration. */
export enum RealtyLeadFinality {
  /** Unknown */
  Unknown = 'UNKNOWN',
  /** Sale */
  Sale = 'SALE',
  /** Rent */
  Rent = 'RENT',
}

/** An enumeration. */
export enum RealtyLeadKind {
  /** Phone */
  Phone = 'PHONE',
  /** Whatsapp */
  Whatsapp = 'WHATSAPP',
  /** Map */
  Map = 'MAP',
  /** Share */
  Share = 'SHARE',
  /** Contact */
  Contact = 'CONTACT',
  /** Schedule */
  Schedule = 'SCHEDULE',
}

/** Realty leads by day. */
export type RealtyLeadsByDayType = {
  __typename?: 'RealtyLeadsByDayType';
  /** Total leads in the day. */
  leads?: Maybe<Scalars['Int']>;
  /** The day that the leads were made */
  day?: Maybe<Scalars['Date']>;
};

/** A realty lead (phone or whatsapp contact). */
export type RealtyLeadType = Node & {
  __typename?: 'RealtyLeadType';
  createdAt: Scalars['DateTime'];
  kind: RealtyLeadKind;
  realty: RealtyType;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  msg?: Maybe<Scalars['String']>;
  finality: RealtyLeadFinality;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type RealtyLeadTypeConnection = {
  __typename?: 'RealtyLeadTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RealtyLeadTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `RealtyLeadType` and its cursor. */
export type RealtyLeadTypeEdge = {
  __typename?: 'RealtyLeadTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<RealtyLeadType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type RealtyMediaCreateMutationInput = {
  aspectRatio?: Maybe<Scalars['Float']>;
  caption?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isMain?: Maybe<Scalars['Boolean']>;
  file?: Maybe<Scalars['UploadType']>;
  externalUrl?: Maybe<Scalars['String']>;
  realty?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty media create mutation. */
export type RealtyMediaCreateMutationPayload = {
  __typename?: 'RealtyMediaCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realtyMedia?: Maybe<RealtyMediaType>;
};

export type RealtyMediaDeleteMutationInput = {
  /** ID of the "RealtyMediaType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty media delete mutation. */
export type RealtyMediaDeleteMutationPayload = {
  __typename?: 'RealtyMediaDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realtyMedia?: Maybe<RealtyMediaType>;
};

/** Realty media optimized urls. */
export type RealtyMediaOptimizedUrlType = {
  __typename?: 'RealtyMediaOptimizedUrlType';
  /** Image url as xl */
  xl?: Maybe<Scalars['String']>;
  /** Image url as lg */
  lg?: Maybe<Scalars['String']>;
  /** Image url as md */
  md?: Maybe<Scalars['String']>;
  /** Image url as sm */
  sm?: Maybe<Scalars['String']>;
  /** Image url as xs */
  xs?: Maybe<Scalars['String']>;
};

/** A realty media (e.g. image, video, link, etc). */
export type RealtyMediaType = Node & {
  __typename?: 'RealtyMediaType';
  /** The ID of the object. */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isMain: Scalars['Boolean'];
  optimized: Scalars['Boolean'];
  aspectRatio?: Maybe<Scalars['Float']>;
  realty?: Maybe<RealtyType>;
  caption?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  /** The realty's main image. */
  url: Scalars['String'];
  /** Optimized urls for different screen sizes. */
  optimizedUrls?: Maybe<RealtyMediaOptimizedUrlType>;
};

export type RealtyMediaTypeConnection = {
  __typename?: 'RealtyMediaTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RealtyMediaTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `RealtyMediaType` and its cursor. */
export type RealtyMediaTypeEdge = {
  __typename?: 'RealtyMediaTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<RealtyMediaType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type RealtyMediaUpdateMutationInput = {
  aspectRatio?: Maybe<Scalars['Float']>;
  caption?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  externalUrl?: Maybe<Scalars['String']>;
  /** ID of the "RealtyMediaType" to mutate */
  id: Scalars['ID'];
  isMain?: Maybe<Scalars['Boolean']>;
  file?: Maybe<Scalars['UploadType']>;
  realty?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty media update mutation. */
export type RealtyMediaUpdateMutationPayload = {
  __typename?: 'RealtyMediaUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realtyMedia?: Maybe<RealtyMediaType>;
};

export type RealtyPublishMutationInput = {
  /** ID of the "RealtyType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty publish mutation. */
export type RealtyPublishMutationPayload = {
  __typename?: 'RealtyPublishMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realty?: Maybe<RealtyType>;
};

/** An enumeration. */
export enum RealtyStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Duplicated */
  Duplicated = 'DUPLICATED',
  /** Inactive */
  Inactive = 'INACTIVE',
  /** Pending */
  Pending = 'PENDING',
  /** Rejected */
  Rejected = 'REJECTED',
}

/** Realty summary by period. */
export type RealtySummaryType = {
  __typename?: 'RealtySummaryType';
  /** Hits by day in the period. */
  hits?: Maybe<Array<Maybe<RealtyHitsByDayType>>>;
  /** Leads by day in the period. */
  leads?: Maybe<Array<Maybe<RealtyLeadsByDayType>>>;
  /** Total active realties. */
  activeRealties?: Maybe<Scalars['Int']>;
  /** Total pending realties. */
  pendingRealties?: Maybe<Scalars['Int']>;
  /** Total contacts in the period. */
  contacts?: Maybe<Scalars['Int']>;
  /** Total schedules in the period. */
  schedules?: Maybe<Scalars['Int']>;
  /** Total whatsapp leads in the period. */
  whatsappLeads?: Maybe<Scalars['Int']>;
  /** Total phone leads in the period. */
  phoneLeads?: Maybe<Scalars['Int']>;
  /** Total map leads in the period. */
  mapLeads?: Maybe<Scalars['Int']>;
  /** Total leads with information. */
  total?: Maybe<Scalars['Int']>;
};

export type RealtyThreadArchiveMutationInput = {
  /** ID of the "RealtyThreadType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty thread archive mutation. */
export type RealtyThreadArchiveMutationPayload = {
  __typename?: 'RealtyThreadArchiveMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realtyThread?: Maybe<RealtyThreadType>;
};

export type RealtyThreadCreateMutationInput = {
  kind: RealtyThreadKind;
  msg: Scalars['String'];
  realty?: Maybe<Scalars['ID']>;
  sender: Scalars['ID'];
  recipient: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty thread create mutation. */
export type RealtyThreadCreateMutationPayload = {
  __typename?: 'RealtyThreadCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realtyThread?: Maybe<RealtyThreadType>;
};

/** An enumeration. */
export enum RealtyThreadKind {
  /** Partnership */
  Partnership = 'PARTNERSHIP',
  /** Realty Problem */
  RealtyProblem = 'REALTY_PROBLEM',
}

export type RealtyThreadMarkReadMutationInput = {
  /** ID of the "RealtyThreadType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty thread mark as read mutation. */
export type RealtyThreadMarkReadMutationPayload = {
  __typename?: 'RealtyThreadMarkReadMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realtyThread?: Maybe<RealtyThreadType>;
};

export type RealtyThreadMessageCreateMutationInput = {
  msg: Scalars['String'];
  thread: Scalars['ID'];
  user: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty thread message create mutation. */
export type RealtyThreadMessageCreateMutationPayload = {
  __typename?: 'RealtyThreadMessageCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realtyThreadMessage?: Maybe<RealtyThreadMessageType>;
};

export type RealtyThreadMessageMarkReadMutationInput = {
  /** ID of the "RealtyThreadMessageType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty thread message mark as read mutation. */
export type RealtyThreadMessageMarkReadMutationPayload = {
  __typename?: 'RealtyThreadMessageMarkReadMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realtyThreadMessage?: Maybe<RealtyThreadMessageType>;
};

/** A message inside a realty thread. */
export type RealtyThreadMessageType = Node & {
  __typename?: 'RealtyThreadMessageType';
  /** The ID of the object. */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isEmailed: Scalars['Boolean'];
  isNotified: Scalars['Boolean'];
  thread: RealtyThreadType;
  user: UserType;
  isRead: Scalars['Boolean'];
  readAt?: Maybe<Scalars['DateTime']>;
  msg: Scalars['String'];
};

export type RealtyThreadMessageTypeConnection = {
  __typename?: 'RealtyThreadMessageTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RealtyThreadMessageTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `RealtyThreadMessageType` and its cursor. */
export type RealtyThreadMessageTypeEdge = {
  __typename?: 'RealtyThreadMessageTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<RealtyThreadMessageType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum RealtyThreadProblem {
  /** Missing Photos */
  MissingPhotos = 'MISSING_PHOTOS',
  /** Unauthentic Photos */
  UnauthenticPhotos = 'UNAUTHENTIC_PHOTOS',
  /** Low-Quality Photos */
  LowqualityPhotos = 'LOWQUALITY_PHOTOS',
  /** Stock Realty */
  StockRealty = 'STOCK_REALTY',
  /** Copyright Infringement */
  CopyrightInfringement = 'COPYRIGHT_INFRINGEMENT',
  /** Others */
  Others = 'OTHERS',
}

/** A realty thread (message between 2 user regarding a realty). */
export type RealtyThreadType = Node & {
  __typename?: 'RealtyThreadType';
  /** The ID of the object. */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isArchived: Scalars['Boolean'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  isEmailed: Scalars['Boolean'];
  isNotified: Scalars['Boolean'];
  kind: RealtyThreadKind;
  realty?: Maybe<RealtyType>;
  sender: UserType;
  recipient: UserType;
  problem?: Maybe<RealtyThreadProblem>;
  isRead: Scalars['Boolean'];
  readAt?: Maybe<Scalars['DateTime']>;
  msg: Scalars['String'];
  messages: RealtyThreadMessageTypeConnection;
  /** Last message in the thread */
  lastMsg?: Maybe<Scalars['String']>;
  /** Last date in the thread */
  lastDate?: Maybe<Scalars['DateTime']>;
  /** How many unread messages there's in the thread */
  unreadCount?: Maybe<Scalars['Int']>;
};

/** A realty thread (message between 2 user regarding a realty). */
export type RealtyThreadTypeMessagesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  thread?: Maybe<Scalars['ID']>;
};

export type RealtyThreadTypeConnection = {
  __typename?: 'RealtyThreadTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RealtyThreadTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `RealtyThreadType` and its cursor. */
export type RealtyThreadTypeEdge = {
  __typename?: 'RealtyThreadTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<RealtyThreadType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type RealtyThreadUnarchiveMutationInput = {
  /** ID of the "RealtyThreadType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty thread archive mutation. */
export type RealtyThreadUnarchiveMutationPayload = {
  __typename?: 'RealtyThreadUnarchiveMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realtyThread?: Maybe<RealtyThreadType>;
};

/** A realty in the database. */
export type RealtyType = Node & {
  __typename?: 'RealtyType';
  /** The ID of the object. */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: RealtyStatus;
  published: Scalars['Boolean'];
  ok: Scalars['Boolean'];
  /** The user that registered the realty. */
  user?: Maybe<PublicUserType>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  codeInternal?: Maybe<Scalars['Int']>;
  ventureName?: Maybe<Scalars['String']>;
  venture?: Maybe<VentureType>;
  salePrice?: Maybe<Scalars['Decimal']>;
  rentPrice?: Maybe<Scalars['Decimal']>;
  vacationPrice?: Maybe<Scalars['Decimal']>;
  condominiumPrice?: Maybe<Scalars['Decimal']>;
  iptuPrice?: Maybe<Scalars['Decimal']>;
  isForSale: Scalars['Boolean'];
  isForRent: Scalars['Boolean'];
  isForVacation: Scalars['Boolean'];
  isForExchange: Scalars['Boolean'];
  allowNegotiation: Scalars['Boolean'];
  allowFinancing: Scalars['Boolean'];
  category: Scalars['String'];
  area?: Maybe<Scalars['Decimal']>;
  bedrooms?: Maybe<Scalars['Int']>;
  suites?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
  garages?: Maybe<Scalars['Int']>;
  fulltimeSecurity: Scalars['Boolean'];
  swimmingPool: Scalars['Boolean'];
  playground: Scalars['Boolean'];
  greenArea: Scalars['Boolean'];
  energyEfficiency?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  tourUrl?: Maybe<Scalars['String']>;
  location: LocationType;
  commercialDistrict?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  geopoint?: Maybe<Scalars['GeoPoint']>;
  /** If the realty is highlighted on Zap */
  highlighted?: Maybe<Scalars['Boolean']>;
  /** If the realty is super highlighted on Zap */
  superHighlighted?: Maybe<Scalars['Boolean']>;
  avoidPublish: Scalars['Boolean'];
  /** All medias for this realty. */
  medias: Array<Maybe<RealtyMediaType>>;
  /** The rank of this result. */
  rank?: Maybe<Scalars['Float']>;
  /** The distance of this result from the user (in m). */
  distance?: Maybe<Scalars['Distance']>;
  /** If this realty was curated. */
  isCurated?: Maybe<Scalars['Boolean']>;
  /** Number of hits in this realty. */
  hitsCount?: Maybe<Scalars['Int']>;
  /** Number of contacts in this realty. */
  contactsCount?: Maybe<Scalars['Int']>;
  /** Number of schedules in this realty. */
  schedulesCount?: Maybe<Scalars['Int']>;
  /** Number of leads in this realty. */
  leadsCount?: Maybe<Scalars['Int']>;
  /** Number of leads (phone) in this realty. */
  phonesCount?: Maybe<Scalars['Int']>;
  /** Number of leads (whatsapp) in this realty. */
  whatsappsCount?: Maybe<Scalars['Int']>;
  /** Number of leads (map) in this realty. */
  mapsCount?: Maybe<Scalars['Int']>;
  /** Number of leads (share) in this realty. */
  sharesCount?: Maybe<Scalars['Int']>;
  /** Number of total leads in this realty. */
  totalsCount?: Maybe<Scalars['Int']>;
  /** The reason this realty is pending. */
  inactiveReason?: Maybe<RealtyInactiveReasonType>;
  /** Similar realties to this one. */
  similars: Array<RealtyType>;
  portalId?: Maybe<Scalars['String']>;
  integration?: Maybe<RealtyIntegration>;
  originDescription?: Maybe<Scalars['String']>;
  rentPeriod?: Maybe<Scalars['String']>;
};

/** A realty in the database. */
export type RealtyTypeMediasArgs = {
  first?: Maybe<Scalars['Int']>;
};

export type RealtyIntegration = {
  id?: Maybe<Scalars['Int']>;
};

/** A realty in the database. */
export type RealtyTypeSimilarsArgs = {
  first?: Maybe<Scalars['Int']>;
  isForSale?: Maybe<Scalars['Boolean']>;
  isForRent?: Maybe<Scalars['Boolean']>;
};

export type RealtyTypeConnection = {
  __typename?: 'RealtyTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RealtyTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
  /** Realty filters helper. */
  filters: RealtyFiltersType;
};

/** A Relay edge containing a `RealtyType` and its cursor. */
export type RealtyTypeEdge = {
  __typename?: 'RealtyTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<RealtyType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type RealtyUnpublishMutationInput = {
  /** ID of the "RealtyType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty publish mutation. */
export type RealtyUnpublishMutationPayload = {
  __typename?: 'RealtyUnpublishMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realty?: Maybe<RealtyType>;
};

export type RealtyUpdateManagerMutationInput = {
  /** A reason to why this realty was rejected. */
  msg?: Maybe<Scalars['String']>;
  /** The enumerable reason to why this realty was rejected. */
  problem?: Maybe<RealtyThreadProblem>;
  /** A media to set as the main one. */
  mainMedia?: Maybe<Scalars['ID']>;
  /** List of medias to delete. */
  excludedMedias?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The realty that this one is duplicate of. */
  duplicatedOf?: Maybe<Scalars['ID']>;
  status: RealtyStatus;
  category?: Maybe<Scalars['String']>;
  /** ID of the "RealtyType" to mutate */
  id: Scalars['ID'];
  published?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty update mutation. */
export type RealtyUpdateManagerMutationPayload = {
  __typename?: 'RealtyUpdateManagerMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realty?: Maybe<RealtyType>;
};

export type RealtyUpdateMutationInput = {
  /** Country if location is not given by Zip Code */
  country?: Maybe<Scalars['String']>;
  /** Manual zip code if location is not given by Zip Code */
  postalCode?: Maybe<Scalars['String']>;
  /** State if location is not given by Zip Code */
  state?: Maybe<Scalars['String']>;
  /** City if location is not given by Zip Code */
  city?: Maybe<Scalars['String']>;
  /** District if location is not given by Zip Code */
  district?: Maybe<Scalars['String']>;
  /** Street if location is not given by Zip Code */
  street?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  ventureName?: Maybe<Scalars['String']>;
  salePrice?: Maybe<Scalars['Decimal']>;
  rentPrice?: Maybe<Scalars['Decimal']>;
  vacationPrice?: Maybe<Scalars['Decimal']>;
  condominiumPrice?: Maybe<Scalars['Decimal']>;
  iptuPrice?: Maybe<Scalars['Decimal']>;
  category?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['Decimal']>;
  bedrooms?: Maybe<Scalars['Int']>;
  suites?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
  garages?: Maybe<Scalars['Int']>;
  energyEfficiency?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  tourUrl?: Maybe<Scalars['String']>;
  commercialDistrict?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  geopoint?: Maybe<Scalars['GeoPoint']>;
  /** ID of the "RealtyType" to mutate */
  id: Scalars['ID'];
  ok?: Maybe<Scalars['Boolean']>;
  user?: Maybe<Scalars['ID']>;
  venture?: Maybe<Scalars['ID']>;
  isForSale?: Maybe<Scalars['Boolean']>;
  isForRent?: Maybe<Scalars['Boolean']>;
  isForVacation?: Maybe<Scalars['Boolean']>;
  isForExchange?: Maybe<Scalars['Boolean']>;
  allowNegotiation?: Maybe<Scalars['Boolean']>;
  allowFinancing?: Maybe<Scalars['Boolean']>;
  fulltimeSecurity?: Maybe<Scalars['Boolean']>;
  swimmingPool?: Maybe<Scalars['Boolean']>;
  playground?: Maybe<Scalars['Boolean']>;
  greenArea?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['ID']>;
  highlighted?: Maybe<Scalars['Boolean']>;
  superHighlighted?: Maybe<Scalars['Boolean']>;
  avoidPublish?: Maybe<Scalars['Boolean']>;
  /** Set list of medias */
  medias?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Realty update mutation. */
export type RealtyUpdateMutationPayload = {
  __typename?: 'RealtyUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  realty?: Maybe<RealtyType>;
};

/** An enumeration. */
export enum SearchHitCategory {
  /** Commercial: Building */
  CBuilding = 'C_BUILDING',
  /** Commercial: Clinic */
  CClinic = 'C_CLINIC',
  /** Commercial: Farm / Grange */
  CFarmGrange = 'C_FARM_GRANGE',
  /** Commercial: Hangar / Deposit / Warehouse */
  CHangarDepositWarehouse = 'C_HANGAR_DEPOSIT_WAREHOUSE',
  /** Commercial: Lot / Land */
  CLotLand = 'C_LOT_LAND',
  /** Commercial: Realty */
  CRealty = 'C_REALTY',
  /** Commercial: Room */
  CRoom = 'C_ROOM',
  /** Commercial: Store */
  CStore = 'C_STORE',
  /** Residential: Apartment */
  RApartment = 'R_APARTMENT',
  /** Residential: Condominium House */
  RCondominiumHouse = 'R_CONDOMINIUM_HOUSE',
  /** Residential: Condominium Land */
  RCondominiumLand = 'R_CONDOMINIUM_LAND',
  /** Residential: Country House / Villa */
  RCountryHouseVilla = 'R_COUNTRY_HOUSE_VILLA',
  /** Residential: Flat */
  RFlat = 'R_FLAT',
  /** Residential: House */
  RHouse = 'R_HOUSE',
  /** Residential: Kitnet */
  RKitnet = 'R_KITNET',
  /** Residential: Land */
  RLand = 'R_LAND',
  /** Residential: Loft */
  RLoft = 'R_LOFT',
  /** Vacation: Apartment */
  VApartment = 'V_APARTMENT',
  /** Vacation: House */
  VHouse = 'V_HOUSE',
}

export type SearchHitCreateMutationInput = {
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  kind?: Maybe<SearchHitKind>;
  stateSlug?: Maybe<Scalars['String']>;
  citySlug?: Maybe<Scalars['String']>;
  districtSlug?: Maybe<Scalars['String']>;
  category?: Maybe<SearchHitCategory>;
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Search hit create mutation. */
export type SearchHitCreateMutationPayload = {
  __typename?: 'SearchHitCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  searchHit?: Maybe<SearchHitType>;
};

/** An enumeration. */
export enum SearchHitKind {
  /** Sale */
  Sale = 'SALE',
  /** Rent */
  Rent = 'RENT',
  /** Vacation */
  Vacation = 'VACATION',
  /** Exchange */
  Exchange = 'EXCHANGE',
}

/** Search hit type. */
export type SearchHitType = Node & {
  __typename?: 'SearchHitType';
  /** The ID of the object. */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  ipAddress?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['UUID']>;
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  kind: SearchHitKind;
  stateSlug?: Maybe<Scalars['String']>;
  citySlug?: Maybe<Scalars['String']>;
  districtSlug?: Maybe<Scalars['String']>;
  category?: Maybe<SearchHitCategory>;
  page: Scalars['Int'];
  ordering?: Maybe<Scalars['String']>;
  filters: Scalars['JSONString'];
};

/** An enumeration. */
export enum SearchKind {
  /** City */
  City = 'CITY',
  /** District */
  District = 'DISTRICT',
  /** Street */
  Street = 'STREET',
  /** Venture */
  Venture = 'VENTURE',
}

/** Search kind. */
export enum SearchTopResultsKind {
  Rent = 'RENT',
  Sale = 'SALE',
  Region = 'REGION',
  Vacation = 'VACATION',
}

/** Search type. */
export type SearchTopResultsType = {
  __typename?: 'SearchTopResultsType';
  /** The kind of the result. */
  kind: SearchTopResultsKind;
  /** The category of the result. */
  category?: Maybe<Scalars['String']>;
  /** The state of the result. */
  state: Scalars['String'];
  /** The state code of the result. */
  stateCode: Scalars['String'];
  /** The state slug of the result. */
  stateSlug: Scalars['String'];
  /** The city of the result. */
  city?: Maybe<Scalars['String']>;
  /** The city of the result. */
  citySlug?: Maybe<Scalars['String']>;
};

/** Search type. */
export type SearchType = Node & {
  __typename?: 'SearchType';
  /** The ID of the object. */
  id: Scalars['ID'];
  kind: SearchKind;
  results: Scalars['Int'];
  state: StateType;
  city: CityType;
  venture?: Maybe<VentureType>;
  district?: Maybe<Scalars['String']>;
  districtSlug?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  geopoint?: Maybe<Scalars['GeoPoint']>;
  trgmname?: Maybe<Scalars['String']>;
  /** The rank of this result. */
  rank?: Maybe<Scalars['Float']>;
  /** The distance of this result from the user (in m). */
  distance?: Maybe<Scalars['Distance']>;
};

export type SearchTypeConnection = {
  __typename?: 'SearchTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SearchTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SearchType` and its cursor. */
export type SearchTypeEdge = {
  __typename?: 'SearchTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<SearchType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A state in a country. */
export type StateType = Node & {
  __typename?: 'StateType';
  /** The ID of the object. */
  id: Scalars['ID'];
  country: CountryType;
  name?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  slug: Scalars['String'];
};

export type UserActivateMutationInput = {
  token: Scalars['String'];
  uid: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Activate a user's registration. */
export type UserActivateMutationPayload = {
  __typename?: 'UserActivateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserChangePasswordMutationInput = {
  oldPassword?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  rePassword: Scalars['String'];
  /** ID of the "UserType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Change the user's password. */
export type UserChangePasswordMutationPayload = {
  __typename?: 'UserChangePasswordMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserType>;
};

export type UserChangePaymentMethodMutationInput = {
  paymentKind: Scalars['String'];
  paymentToken?: Maybe<Scalars['String']>;
  /** ID of the "UserType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Change the payment method of a user plan. */
export type UserChangePaymentMethodMutationPayload = {
  __typename?: 'UserChangePaymentMethodMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The modified user plan. */
  userPlan?: Maybe<UserPlanType>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserType>;
};

/** An enumeration. */
export enum UserCms {
  /** c2s */
  C2S = 'C2S',
  /** exactsales-v1 */
  ExactsalesV1 = 'EXACTSALES_V1',
  /** exactsales-v2 */
  ExactsalesV2 = 'EXACTSALES_V2',
  /** jetimob */
  Jetimob = 'JETIMOB',
  /** msysimob */
  Msysimob = 'MSYSIMOB',
  /** kurole */
  Kurole = 'KUROLE',
  /** sohtec */
  Sohtec = 'SOHTEC',
  /** webmobby */
  Webmobby = 'WEBMOBBY',
  /** imobzi */
  Imobzi = 'IMOBZI',
  /** universal */
  Universal = 'UNIVERSAL',
}

export type UserCreateMutationInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  rePassword: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Create a new user. */
export type UserCreateMutationPayload = {
  __typename?: 'UserCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum UserKind {
  /** Individual */
  Individual = 'INDIVIDUAL',
  /** Broker */
  Broker = 'BROKER',
  /** Realestate */
  Realestate = 'REALESTATE',
  /** Incorporator */
  Incorporator = 'INCORPORATOR',
}

export type UserLoginMutationInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Generates a token to login the user. */
export type UserLoginMutationPayload = {
  __typename?: 'UserLoginMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  /** Token for login. */
  token?: Maybe<Scalars['UUID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserNewPlanMutationInput = {
  plan: Scalars['ID'];
  paymentKind?: Maybe<Scalars['String']>;
  paymentToken?: Maybe<Scalars['String']>;
  /** ID of the "UserType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Change or create a new plan for the user. */
export type UserNewPlanMutationPayload = {
  __typename?: 'UserNewPlanMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The modified user plan. */
  userPlan?: Maybe<UserPlanType>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserType>;
};

export type UserNotificationsMarkReadMutationInput = {
  /** kind of notifications to clear (null means all). */
  kind?: Maybe<NotificationKind>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Mark user's notification as read. */
export type UserNotificationsMarkReadMutationPayload = {
  __typename?: 'UserNotificationsMarkReadMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** User notification. */
export type UserNotificationType = {
  __typename?: 'UserNotificationType';
  /** The kind of the notification. */
  kind?: Maybe<NotificationKind>;
  /** The number of unread notifications of this kind. */
  count?: Maybe<Scalars['Int']>;
};

/** User optimized avatar urls. */
export type UserOptimizedAvatarUrlType = {
  __typename?: 'UserOptimizedAvatarUrlType';
  /** Image url as xl */
  xl?: Maybe<Scalars['String']>;
  /** Image url as lg */
  lg?: Maybe<Scalars['String']>;
  /** Image url as md */
  md?: Maybe<Scalars['String']>;
  /** Image url as sm */
  sm?: Maybe<Scalars['String']>;
  /** Image url as xs */
  xs?: Maybe<Scalars['String']>;
};

export type UserPaymentMethodCreateMutationInput = {
  description: Scalars['String'];
  token: Scalars['String'];
  default?: Maybe<Scalars['Boolean']>;
  /** ID of the "UserType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** User plan set password mutation. */
export type UserPaymentMethodCreateMutationPayload = {
  __typename?: 'UserPaymentMethodCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The newly created payment method. */
  paymentMethod?: Maybe<UserPaymentMethodType>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserType>;
};

export type UserPaymentMethodDeleteMutationInput = {
  paymentMethodId: Scalars['String'];
  /** ID of the "UserType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** User plan set password mutation. */
export type UserPaymentMethodDeleteMutationPayload = {
  __typename?: 'UserPaymentMethodDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The newly created payment method. */
  paymentMethod?: Maybe<UserPaymentMethodType>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserType>;
};

/** User payment method. */
export type UserPaymentMethodType = {
  __typename?: 'UserPaymentMethodType';
  /** The payment method's id. */
  id?: Maybe<Scalars['String']>;
  /** The payment method's kind. */
  kind?: Maybe<Scalars['String']>;
  /** The payment method's description. */
  description?: Maybe<Scalars['String']>;
  /** The payment method's holder name. */
  holderName?: Maybe<Scalars['String']>;
  /** The payment method's display number. */
  displayNumber?: Maybe<Scalars['String']>;
  /** The payment method's brand. */
  brand?: Maybe<Scalars['String']>;
  /** The payment method's year. */
  year?: Maybe<Scalars['Int']>;
  /** The payment method's month. */
  month?: Maybe<Scalars['Int']>;
};

/** User payment method. */
export type UserPlanChangeSimulationType = {
  __typename?: 'UserPlanChangeSimulationType';
  /** The cost of changing the plan. */
  cost?: Maybe<Scalars['Decimal']>;
  /** The discount of changing the plan. */
  discount?: Maybe<Scalars['Decimal']>;
  /** How many cycles the cost/discount will be accounted for. */
  cycles?: Maybe<Scalars['Int']>;
  /** Expiration date. */
  expiresAt?: Maybe<Scalars['Date']>;
};

export type UserPlanCreateMutationInput = {
  planPrice?: Maybe<Scalars['Decimal']>;
  planPromotionPrice?: Maybe<Scalars['Decimal']>;
  planPromotionMonths?: Maybe<Scalars['Int']>;
  planSlots?: Maybe<Scalars['Int']>;
  kind?: Maybe<UserPlanKind>;
  userKind?: Maybe<UserPlanUserKind>;
  name?: Maybe<Scalars['String']>;
  doc?: Maybe<Scalars['String']>;
  advertisingName?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  cellphone?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['ID']>;
  user?: Maybe<Scalars['ID']>;
  salesperson?: Maybe<Scalars['ID']>;
  city?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** User plan create mutation. */
export type UserPlanCreateMutationPayload = {
  __typename?: 'UserPlanCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  userPlan?: Maybe<UserPlanType>;
};

export type UserPlanCreateUserMutationInput = {
  token: Scalars['String'];
  paymentToken?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** User plan create mutation. */
export type UserPlanCreateUserMutationPayload = {
  __typename?: 'UserPlanCreateUserMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The user for the token. */
  user?: Maybe<UserType>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  userPlan?: Maybe<UserPlanType>;
};

/** An enumeration. */
export enum UserPlanKind {
  /** Card */
  Card = 'CARD',
  /** Bill */
  Bill = 'BILL',
}

export type UserPlanSetPasswordMutationInput = {
  token: Scalars['String'];
  password: Scalars['String'];
  rePassword: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** User plan set password mutation. */
export type UserPlanSetPasswordMutationPayload = {
  __typename?: 'UserPlanSetPasswordMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The user for the token. */
  user?: Maybe<UserType>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  userPlan?: Maybe<UserPlanType>;
};

/** An enumeration. */
export enum UserPlanStatus {
  /** Pending */
  Pending = 'PENDING',
  /** Active */
  Active = 'ACTIVE',
  /** Canceled */
  Canceled = 'CANCELED',
  /** Error */
  Error = 'ERROR',
  /** Lost */
  Lost = 'LOST',
}

/** User plan subscription status. */
export enum UserPlanSubscriptionStatus {
  Active = 'ACTIVE',
  WaitingPayment = 'WAITING_PAYMENT',
  Late = 'LATE',
  Canceled = 'CANCELED',
}

/** A user in the database. */
export type UserPlanType = Node & {
  __typename?: 'UserPlanType';
  /** The ID of the object. */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: UserPlanStatus;
  suspended: Scalars['Boolean'];
  expiresAt?: Maybe<Scalars['Date']>;
  startedAt?: Maybe<Scalars['Date']>;
  kind?: Maybe<UserPlanKind>;
  userKind?: Maybe<UserPlanUserKind>;
  promotionPrice?: Maybe<Scalars['Decimal']>;
  promotionMonths?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Decimal']>;
  token: Scalars['UUID'];
  slots?: Maybe<Scalars['Int']>;
  plan?: Maybe<PlanType>;
  user?: Maybe<UserType>;
  salesperson?: Maybe<UserType>;
  name: Scalars['String'];
  doc?: Maybe<Scalars['String']>;
  advertisingName?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmed: Scalars['Boolean'];
  cellphone?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<CityType>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  /** The current status for the subscription */
  subscriptionStatus?: Maybe<UserPlanSubscriptionStatus>;
  /** The promotion kind for this plan */
  promotionKind?: Maybe<Scalars['String']>;
  /** If the plan is allowed to publish realties. */
  canPublish?: Maybe<Scalars['Boolean']>;
  /** If change of plan is allowed. */
  canChangePlan?: Maybe<Scalars['Boolean']>;
  /** The total slots for this plan. */
  slotsTotal?: Maybe<Scalars['Int']>;
};

export type UserPlanTypeConnection = {
  __typename?: 'UserPlanTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserPlanTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UserPlanType` and its cursor. */
export type UserPlanTypeEdge = {
  __typename?: 'UserPlanTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserPlanType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type UserPlanUpdateMutationInput = {
  token: Scalars['String'];
  kind?: Maybe<UserPlanKind>;
  userKind?: Maybe<UserPlanUserKind>;
  name?: Maybe<Scalars['String']>;
  doc?: Maybe<Scalars['String']>;
  advertisingName?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  cellphone?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['ID']>;
  user?: Maybe<Scalars['ID']>;
  salesperson?: Maybe<Scalars['ID']>;
  emailConfirmed?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** User plan create mutation. */
export type UserPlanUpdateMutationPayload = {
  __typename?: 'UserPlanUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  userPlan?: Maybe<UserPlanType>;
};

/** An enumeration. */
export enum UserPlanUserKind {
  /** Individual */
  Individual = 'INDIVIDUAL',
  /** Broker */
  Broker = 'BROKER',
  /** Realestate */
  Realestate = 'REALESTATE',
  /** Incorporator */
  Incorporator = 'INCORPORATOR',
}

/** User realties counters. */
export type UserRealtiesCountType = {
  __typename?: 'UserRealtiesCountType';
  /** The total number of realties for the user. */
  count: Scalars['Int'];
  /** The total number of published realties, which fill slots. */
  countPublished: Scalars['Int'];
  /** The total number of active realties in the website. */
  countActive: Scalars['Int'];
  /** The total number of realties waiting curation. */
  countWaitingCuration: Scalars['Int'];
  /** The total number of realties considered available. */
  countAvailable: Scalars['Int'];
  /** The total number of realties duplicated. */
  countDuplicated: Scalars['Int'];
  /** The total number of realties rejected. */
  countRejected: Scalars['Int'];
  /** The total number of realties in automatic curation. */
  countAutomaticCuration: Scalars['Int'];
};

/** User realties counters. */
export type UserRealtiesLeadsType = {
  __typename?: 'UserRealtiesLeadsType';
  /** Total hits for the user's realties. */
  hits: Scalars['Int'];
  /** Total leads for the user's realties. */
  leads: Scalars['Int'];
  /** Total whatsapp leads for the user's realties. */
  whatsapps: Scalars['Int'];
  /** Total phone leads for the user's realties. */
  phones: Scalars['Int'];
  /** Total contacts for the user's realties. */
  contacts: Scalars['Int'];
  /** Total schedules for the user's realties. */
  schedules: Scalars['Int'];
  /** Total leads + schedules + contacts for the user's realties. */
  totals: Scalars['Int'];
};

export type UserResetPasswordConfirmMutationInput = {
  uid: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
  rePassword: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Confirm the request to reset a user's password. */
export type UserResetPasswordConfirmMutationPayload = {
  __typename?: 'UserResetPasswordConfirmMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserResetPasswordMutationInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Request to reset a user's password. */
export type UserResetPasswordMutationPayload = {
  __typename?: 'UserResetPasswordMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserTokenCreateInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  password: Scalars['String'];
};

/**
 * Authenticates a user and returns token and user data.
 *
 * It overrides the default graphql_jwt.GObtainJSONWebToken to wrap potential
 * authentication errors in our Error type, which is consistent to how rest of
 * the mutation works.
 */
export type UserTokenCreatePayload = {
  __typename?: 'UserTokenCreatePayload';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  errors: Array<Maybe<MutationErrorType>>;
  /** The user for the token. */
  user?: Maybe<UserType>;
  clientMutationId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type UserTokenVerifyInput = {
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Confirm if token is valid and also return user data. */
export type UserTokenVerifyPayload = {
  __typename?: 'UserTokenVerifyPayload';
  payload: Scalars['GenericScalar'];
  /** The user for the token. */
  user?: Maybe<UserType>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** A user in the database. */
export type UserType = Node & {
  __typename?: 'UserType';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  kind: UserKind;
  advertisingName?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  cellphone?: Maybe<Scalars['String']>;
  hasWhatsapp: Scalars['Boolean'];
  optimized: Scalars['Boolean'];
  city?: Maybe<CityType>;
  creci?: Maybe<Scalars['String']>;
  realtiesCached: Scalars['Int'];
  allowScheduling: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  email: Scalars['String'];
  cms?: Maybe<UserCms>;
  cmsToken?: Maybe<Scalars['String']>;
  cmsDomain?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  doc?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  hasActivePlan: Scalars['Boolean'];
  contactCcEmails: Array<Scalars['String']>;
  contactSaleCcEmails: Array<Scalars['String']>;
  contactRentCcEmails: Array<Scalars['String']>;
  sendContactEmails: Scalars['Boolean'];
  sendMessageEmails: Scalars['Boolean'];
  sendMonthlySummaryEmails: Scalars['Boolean'];
  senderThreads: RealtyThreadTypeConnection;
  recipientThreads: RealtyThreadTypeConnection;
  senderMessages: RealtyThreadMessageTypeConnection;
  /** The user's full name. */
  fullName?: Maybe<Scalars['String']>;
  /** The user's main image. */
  avatarUrl?: Maybe<Scalars['String']>;
  /** Optimized urls for different screen sizes. */
  optimizedAvatarUrls?: Maybe<UserOptimizedAvatarUrlType>;
  /** List of permissions for this user. */
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The user's current integration. */
  currentIntegration?: Maybe<IntegrationType>;
  /** The user's current plan. */
  currentPlan?: Maybe<UserPlanType>;
  /** This user's encrypted hash. */
  hash?: Maybe<Scalars['String']>;
  /** Realties counters. */
  realtiesCounts: UserRealtiesCountType;
  /** Realties leads. */
  realtiesLeads: UserRealtiesLeadsType;
};

/** A user in the database. */
export type UserTypeSenderThreadsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  realty?: Maybe<Scalars['ID']>;
  sender?: Maybe<Scalars['ID']>;
  recipient?: Maybe<Scalars['ID']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
};

/** A user in the database. */
export type UserTypeRecipientThreadsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  realty?: Maybe<Scalars['ID']>;
  sender?: Maybe<Scalars['ID']>;
  recipient?: Maybe<Scalars['ID']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
};

/** A user in the database. */
export type UserTypeSenderMessagesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  thread?: Maybe<Scalars['ID']>;
};

export type UserUpdateMutationInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  advertisingName?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  cellphone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  doc?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  contactSaleCcEmails?: Maybe<Array<Scalars['String']>>;
  contactRentCcEmails?: Maybe<Array<Scalars['String']>>;
  /** ID of the "UserType" to mutate */
  id: Scalars['ID'];
  avatar?: Maybe<Scalars['UploadType']>;
  city?: Maybe<Scalars['ID']>;
  sendContactEmails?: Maybe<Scalars['Boolean']>;
  sendMessageEmails?: Maybe<Scalars['Boolean']>;
  sendMonthlySummaryEmails?: Maybe<Scalars['Boolean']>;
  allowScheduling?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** User update mutation. */
export type UserUpdateMutationPayload = {
  __typename?: 'UserUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserType>;
};

export type UserValidateSlugMutationInput = {
  /** The slug to be validated. */
  slug: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** User validate slug mutation. */
export type UserValidateSlugMutationPayload = {
  __typename?: 'UserValidateSlugMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the slug is valid or not. */
  isValid?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type VentureBatchDeleteInput = {
  /** List of object ids. */
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Delete ventures in batch. */
export type VentureBatchDeletePayload = {
  __typename?: 'VentureBatchDeletePayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  venture?: Maybe<Array<Maybe<VentureType>>>;
};

export type VentureCreateInput = {
  name: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Create a venture. */
export type VentureCreatePayload = {
  __typename?: 'VentureCreatePayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  venture?: Maybe<VentureType>;
};

export type VentureDeleteInput = {
  /** ID of the "VentureType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Delete a venture. */
export type VentureDeletePayload = {
  __typename?: 'VentureDeletePayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  venture?: Maybe<VentureType>;
};

export type VentureNameBatchUpdateInput = {
  /** List of values to normalize to. */
  updates: Array<Maybe<BatchUpdateInput>>;
  /** List of object ids. */
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Update products. */
export type VentureNameBatchUpdatePayload = {
  __typename?: 'VentureNameBatchUpdatePayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  ventureName?: Maybe<Array<Maybe<VentureNameType>>>;
};

/** Venture name type. */
export type VentureNameType = Node & {
  __typename?: 'VentureNameType';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  norm?: Maybe<Scalars['String']>;
  city: CityType;
  venture?: Maybe<VentureType>;
  /** If this venture name is normalized. */
  isNormalized?: Maybe<Scalars['Boolean']>;
};

export type VentureNameTypeConnection = {
  __typename?: 'VentureNameTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VentureNameTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VentureNameType` and its cursor. */
export type VentureNameTypeEdge = {
  __typename?: 'VentureNameTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<VentureNameType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Venture type. */
export type VentureType = Node & {
  __typename?: 'VentureType';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  location?: Maybe<LocationType>;
  /** Realties in this venture. */
  realties?: Maybe<RealtyTypeConnection>;
  /** Latest realty modified date (only for sitemaps). */
  lastRealtyDate?: Maybe<Scalars['DateTime']>;
  /** The number of related objects. */
  relatedCount?: Maybe<Scalars['Int']>;
};

/** Venture type. */
export type VentureTypeRealtiesArgs = {
  geosort?: Maybe<Scalars['GeoPoint']>;
  forPartnership?: Maybe<Scalars['Boolean']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  status_Istartswith?: Maybe<Scalars['String']>;
  status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  code?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  salePrice?: Maybe<Scalars['Float']>;
  salePrice_Lte?: Maybe<Scalars['Float']>;
  salePrice_Gte?: Maybe<Scalars['Float']>;
  rentPrice?: Maybe<Scalars['Float']>;
  rentPrice_Lte?: Maybe<Scalars['Float']>;
  rentPrice_Gte?: Maybe<Scalars['Float']>;
  condominiumPrice?: Maybe<Scalars['Float']>;
  condominiumPrice_Lte?: Maybe<Scalars['Float']>;
  condominiumPrice_Gte?: Maybe<Scalars['Float']>;
  iptuPrice?: Maybe<Scalars['Float']>;
  iptuPrice_Lte?: Maybe<Scalars['Float']>;
  iptuPrice_Gte?: Maybe<Scalars['Float']>;
  area?: Maybe<Scalars['Float']>;
  area_Lte?: Maybe<Scalars['Float']>;
  area_Gte?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Scalars['Int']>;
  bedrooms_Lte?: Maybe<Scalars['Int']>;
  bedrooms_Gte?: Maybe<Scalars['Int']>;
  suites?: Maybe<Scalars['Int']>;
  suites_Lte?: Maybe<Scalars['Int']>;
  suites_Gte?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
  bathrooms_Lte?: Maybe<Scalars['Int']>;
  bathrooms_Gte?: Maybe<Scalars['Int']>;
  garages?: Maybe<Scalars['Int']>;
  garages_Lte?: Maybe<Scalars['Int']>;
  garages_Gte?: Maybe<Scalars['Int']>;
  energyEfficiency?: Maybe<Scalars['String']>;
  isForSale?: Maybe<Scalars['Boolean']>;
  isForRent?: Maybe<Scalars['Boolean']>;
  isForExchange?: Maybe<Scalars['Boolean']>;
  isForVacation?: Maybe<Scalars['Boolean']>;
  user?: Maybe<Scalars['ID']>;
  user_Kind?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  category_Istartswith?: Maybe<Scalars['String']>;
  category_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<Scalars['ID']>;
  location_Street?: Maybe<Scalars['String']>;
  location_City?: Maybe<Scalars['ID']>;
  location_City_State?: Maybe<Scalars['ID']>;
  location_City_State_Code?: Maybe<Scalars['String']>;
  location_City_State_Code_Iexact?: Maybe<Scalars['String']>;
  location_City_State_Slug?: Maybe<Scalars['String']>;
  venture?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  location_DistrictSlug?: Maybe<Scalars['String']>;
  location_DistrictSlug_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  location_City_Slug?: Maybe<Scalars['String']>;
};

export type VentureTypeConnection = {
  __typename?: 'VentureTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VentureTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VentureType` and its cursor. */
export type VentureTypeEdge = {
  __typename?: 'VentureTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<VentureType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type VentureUpdateInput = {
  name?: Maybe<Scalars['String']>;
  /** ID of the "VentureType" to mutate */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Update a venture. */
export type VentureUpdatePayload = {
  __typename?: 'VentureUpdatePayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  venture?: Maybe<VentureType>;
};

export type CitiesQueryVariables = Exact<{
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}>;

export type CitiesQuery = { __typename?: 'Query' } & {
  cities?: Maybe<
    { __typename?: 'CityTypeConnection' } & Pick<CityTypeConnection, 'totalCount'> & {
        edges: Array<
          Maybe<
            { __typename?: 'CityTypeEdge' } & {
              node?: Maybe<
                { __typename?: 'CityType' } & Pick<CityType, 'id' | 'name'> & {
                    state: { __typename?: 'StateType' } & Pick<StateType, 'id' | 'code'>;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type CitiesForSalesQueryVariables = Exact<{ [key: string]: never }>;

export type CitiesForSalesQuery = { __typename?: 'Query' } & {
  citiesForSales?: Maybe<
    Array<
      Maybe<
        { __typename?: 'CityType' } & Pick<CityType, 'name' | 'id' | 'slug'> & {
            state: { __typename?: 'StateType' } & Pick<StateType, 'name'>;
          }
      >
    >
  >;
};

export type MutErrorFieldsFragment = { __typename?: 'MutationErrorType' } & Pick<
  MutationErrorType,
  'field' | 'message'
>;

export type PagInfoFieldsFragment = { __typename?: 'PageInfo' } & Pick<
  PageInfo,
  'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
>;

export type CreateRealtyMutationVariables = Exact<{
  input: RealtyCreateMutationInput;
}>;

export type CreateRealtyMutation = { __typename?: 'Mutation' } & {
  realtyCreate?: Maybe<
    { __typename?: 'RealtyCreateMutationPayload' } & {
      realty?: Maybe<
        { __typename?: 'RealtyType' } & Pick<
          RealtyType,
          | 'id'
          | 'code'
          | 'category'
          | 'description'
          | 'isForSale'
          | 'isForRent'
          | 'isForExchange'
          | 'area'
          | 'ventureName'
          | 'bedrooms'
          | 'suites'
          | 'bathrooms'
          | 'garages'
        >
      >;
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & MutErrorFieldsFragment>>;
    }
  >;
};

export type CrmInterestAnnounceCreateMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  doc?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  kind?: Maybe<CrmInterestAnnounceKind>;
}>;

export type CrmInterestAnnounceCreateMutation = { __typename?: 'Mutation' } & {
  crmInterestAnnounceCreate?: Maybe<
    { __typename?: 'CrmInterestAnnounceCreateMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'message' | 'field'>>
      >;
      crmInterestAnnounce?: Maybe<
        { __typename?: 'CrmInterestAnnounceType' } & Pick<CrmInterestAnnounceType, 'id'>
      >;
    }
  >;
};

export type DistrictsQueryVariables = Exact<{
  citySlug: Scalars['String'];
  cityStateSlug: Scalars['String'];
}>;

export type DistrictsQuery = { __typename?: 'Query' } & {
  searchDistricts?: Maybe<
    Array<
      Maybe<{ __typename?: 'SearchType' } & Pick<SearchType, 'id' | 'district' | 'districtSlug'>>
    >
  >;
};

export type HomePartnershipsQueryVariables = Exact<{
  geo?: Maybe<Scalars['GeoPoint']>;
}>;

export type HomePartnershipsQuery = { __typename?: 'Query' } & {
  realties?: Maybe<
    { __typename?: 'RealtyTypeConnection' } & Pick<RealtyTypeConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
        edges: Array<
          Maybe<
            { __typename?: 'RealtyTypeEdge' } & {
              node?: Maybe<
                { __typename?: 'RealtyType' } & Pick<
                  RealtyType,
                  | 'id'
                  | 'ventureName'
                  | 'salePrice'
                  | 'rentPrice'
                  | 'vacationPrice'
                  | 'condominiumPrice'
                  | 'iptuPrice'
                  | 'isForSale'
                  | 'isForRent'
                  | 'isForVacation'
                  | 'isForExchange'
                  | 'allowNegotiation'
                  | 'allowFinancing'
                  | 'category'
                  | 'area'
                  | 'bedrooms'
                  | 'suites'
                  | 'bathrooms'
                  | 'garages'
                  | 'energyEfficiency'
                  | 'distance'
                  | 'commercialDistrict'
                  | 'streetNumber'
                  | 'isCurated'
                > & {
                    user?: Maybe<
                      { __typename?: 'PublicUserType' } & Pick<PublicUserType, 'id' | 'kind'>
                    >;
                    location: { __typename?: 'LocationType' } & Pick<
                      LocationType,
                      'district' | 'districtSlug' | 'street' | 'id'
                    > & {
                        city: { __typename?: 'CityType' } & Pick<CityType, 'name' | 'slug'> & {
                            state: { __typename?: 'StateType' } & Pick<
                              StateType,
                              'name' | 'code' | 'slug'
                            >;
                          };
                      };
                    medias: Array<
                      Maybe<
                        { __typename?: 'RealtyMediaType' } & Pick<
                          RealtyMediaType,
                          'url' | 'caption' | 'description' | 'isMain' | 'optimized'
                        > & {
                            optimizedUrls?: Maybe<
                              { __typename?: 'RealtyMediaOptimizedUrlType' } & Pick<
                                RealtyMediaOptimizedUrlType,
                                'md' | 'sm' | 'xs'
                              >
                            >;
                          }
                      >
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type IntegrationArchiveMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IntegrationArchiveMutation = { __typename?: 'Mutation' } & {
  integrationArchive?: Maybe<
    { __typename?: 'IntegrationArchiveMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      integration?: Maybe<
        { __typename?: 'IntegrationType' } & Pick<
          IntegrationType,
          'id' | 'isArchived' | 'archivedAt'
        >
      >;
    }
  >;
};

export type IntegrationCreateMutationVariables = Exact<{
  input: IntegrationCreateMutationInput;
}>;

export type IntegrationCreateMutation = { __typename?: 'Mutation' } & {
  integrationCreate?: Maybe<
    { __typename?: 'IntegrationCreateMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      integration?: Maybe<
        { __typename?: 'IntegrationType' } & Pick<
          IntegrationType,
          | 'id'
          | 'url'
          | 'file'
          | 'key'
          | 'origin'
          | 'preferredDistricts'
          | 'forSale'
          | 'forRent'
          | 'priority'
          | 'priceMinSale'
          | 'priceMaxSale'
          | 'priceMinRent'
          | 'priceMaxRent'
          | 'salePercentage'
          | 'rentPercentage'
        >
      >;
    }
  >;
};

export type IntegrationListQueryVariables = Exact<{ [key: string]: never }>;

export type IntegrationListQuery = { __typename?: 'Query' } & {
  integrations?: Maybe<
    { __typename?: 'IntegrationTypeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'IntegrationTypeEdge' } & {
            node?: Maybe<
              { __typename?: 'IntegrationType' } & Pick<
                IntegrationType,
                | 'id'
                | 'url'
                | 'name'
                | 'key'
                | 'preferredDistricts'
                | 'data'
                | 'processedAt'
                | 'createdAt'
                | 'isArchived'
                | 'origin'
                | 'forSale'
                | 'forRent'
                | 'priceMinSale'
                | 'priceMaxSale'
                | 'priceMinRent'
                | 'priceMaxRent'
                | 'rentPercentage'
                | 'salePercentage'
              > & {
                  user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                      realtiesCounts: { __typename?: 'UserRealtiesCountType' } & Pick<
                        UserRealtiesCountType,
                        | 'count'
                        | 'countPublished'
                        | 'countActive'
                        | 'countWaitingCuration'
                        | 'countAvailable'
                        | 'countRejected'
                        | 'countAutomaticCuration'
                      >;
                    };
                }
            >;
          }
        >
      >;
    }
  >;
};

export type IntegrationOriginsQueryVariables = Exact<{ [key: string]: never }>;

export type IntegrationOriginsQuery = { __typename?: 'Query' } & {
  integrationOrigins?: Maybe<
    Array<
      Maybe<
        { __typename?: 'IntegrationOriginType' } & Pick<
          IntegrationOriginType,
          'name' | 'origin' | 'kind' | 'key' | 'url'
        >
      >
    >
  >;
};

export type IntegrationUpdateMutationVariables = Exact<{
  input: IntegrationUpdateMutationInput;
}>;

export type IntegrationUpdateMutation = { __typename?: 'Mutation' } & {
  integrationUpdate?: Maybe<
    { __typename?: 'IntegrationUpdateMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      integration?: Maybe<
        { __typename?: 'IntegrationType' } & Pick<
          IntegrationType,
          | 'id'
          | 'url'
          | 'file'
          | 'key'
          | 'origin'
          | 'preferredDistricts'
          | 'forSale'
          | 'forRent'
          | 'salePercentage'
          | 'rentPercentage'
        >
      >;
    }
  >;
};

export type IntegrationUpdateDescriptionMutation = { __typename?: 'Mutation' } & {
  integrationUpdate?: Maybe<
    { __typename?: 'IntegrationUpdateDescriptionMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      integration?: Maybe<
        { __typename?: 'IntegrationType' } & Pick<
          IntegrationType,
          | 'id'
          | 'url'
          | 'file'
          | 'key'
          | 'origin'
          | 'preferredDistricts'
          | 'forSale'
          | 'forRent'
          | 'salePercentage'
          | 'rentPercentage'
        >
      >;
    }
  >;
};

export type LocationByPostalCodeQueryVariables = Exact<{
  postalCode: Scalars['String'];
  token?: Maybe<Scalars['String']>;
}>;

export type LocationByPostalCodeQuery = { __typename?: 'Query' } & {
  locationByPostalCode?: Maybe<
    { __typename?: 'LocationType' } & Pick<LocationType, 'street' | 'district' | 'id'> & {
        city: { __typename?: 'CityType' } & Pick<CityType, 'id' | 'name'> & {
            state: { __typename?: 'StateType' } & Pick<StateType, 'code' | 'name' | 'id'>;
          };
      }
  >;
};

export type LocationsKeywordsQueryVariables = Exact<{ [key: string]: never }>;

export type LocationsKeywordsQuery = { __typename?: 'Query' } & {
  locationsKeywords?: Maybe<
    Array<
      Maybe<
        { __typename?: 'LocationKeywordsType' } & Pick<
          LocationKeywordsType,
          | 'category'
          | 'city'
          | 'district'
          | 'quantity'
          | 'citySlug'
          | 'districtSlug'
          | 'stateSlug'
          | 'finality'
        >
      >
    >
  >;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = { __typename?: 'Mutation' } & {
  userLogin?: Maybe<
    { __typename?: 'UserLoginMutationPayload' } & Pick<
      UserLoginMutationPayload,
      'success' | 'token'
    > & { errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & MutErrorFieldsFragment>> }
  >;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'UserType' } & Pick<
      UserType,
      | 'id'
      | 'username'
      | 'firstName'
      | 'lastName'
      | 'fullName'
      | 'advertisingName'
      | 'email'
      | 'phone'
      | 'cellphone'
      | 'street'
      | 'streetNumber'
      | 'district'
      | 'postalCode'
      | 'permissions'
      | 'sendContactEmails'
      | 'sendMessageEmails'
      | 'sendMonthlySummaryEmails'
      | 'contactSaleCcEmails'
      | 'contactRentCcEmails'
      | 'allowScheduling'
      | 'complement'
      | 'kind'
      | 'hash'
      | 'avatarUrl'
    > & {
        realtiesCounts: { __typename?: 'UserRealtiesCountType' } & Pick<
          UserRealtiesCountType,
          | 'count'
          | 'countPublished'
          | 'countActive'
          | 'countWaitingCuration'
          | 'countAvailable'
          | 'countRejected'
          | 'countAutomaticCuration'
        >;
        currentPlan?: Maybe<
          { __typename?: 'UserPlanType' } & Pick<
            UserPlanType,
            | 'id'
            | 'status'
            | 'name'
            | 'email'
            | 'expiresAt'
            | 'kind'
            | 'suspended'
            | 'canPublish'
            | 'token'
            | 'slotsTotal'
          > & {
              plan?: Maybe<
                { __typename?: 'PlanType' } & Pick<PlanType, 'id' | 'kind' | 'price' | 'quantity'>
              >;
            }
        >;
        city?: Maybe<
          { __typename?: 'CityType' } & Pick<CityType, 'name'> & {
              state: { __typename?: 'StateType' } & Pick<StateType, 'name'>;
            }
        >;
        optimizedAvatarUrls?: Maybe<
          { __typename?: 'UserOptimizedAvatarUrlType' } & Pick<UserOptimizedAvatarUrlType, 'sm'>
        >;
      }
  >;
};

export type MyPaymentMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type MyPaymentMethodsQuery = { __typename?: 'Query' } & {
  myPaymentMethods?: Maybe<
    Array<
      Maybe<
        { __typename?: 'UserPaymentMethodType' } & Pick<
          UserPaymentMethodType,
          | 'id'
          | 'kind'
          | 'description'
          | 'holderName'
          | 'displayNumber'
          | 'brand'
          | 'year'
          | 'month'
        >
      >
    >
  >;
};

export type MyRealtiesQueryVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  statusIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  published?: Maybe<Scalars['Boolean']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;

export type MyRealtiesQuery = { __typename?: 'Query' } & {
  myRealties?: Maybe<
    { __typename?: 'RealtyTypeConnection' } & Pick<RealtyTypeConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'startCursor' | 'endCursor'>;
        edges: Array<
          Maybe<
            { __typename?: 'RealtyTypeEdge' } & {
              node?: Maybe<
                { __typename?: 'RealtyType' } & Pick<
                  RealtyType,
                  | 'id'
                  | 'status'
                  | 'code'
                  | 'codeInternal'
                  | 'category'
                  | 'isForSale'
                  | 'salePrice'
                  | 'iptuPrice'
                  | 'isForRent'
                  | 'isForVacation'
                  | 'rentPrice'
                  | 'isForExchange'
                  | 'vacationPrice'
                  | 'condominiumPrice'
                  | 'videoUrl'
                  | 'createdAt'
                  | 'updatedAt'
                  | 'hitsCount'
                  | 'contactsCount'
                  | 'streetNumber'
                  | 'complement'
                  | 'area'
                  | 'ventureName'
                  | 'bedrooms'
                  | 'suites'
                  | 'bathrooms'
                  | 'garages'
                  | 'phonesCount'
                  | 'whatsappsCount'
                  | 'mapsCount'
                  | 'sharesCount'
                  | 'schedulesCount'
                  | 'totalsCount'
                  | 'portalId'
                  | 'rentPeriod'
                > & {
                    inactiveReason?: Maybe<
                      { __typename?: 'RealtyInactiveReasonType' } & Pick<
                        RealtyInactiveReasonType,
                        'msg' | 'problem' | 'automatic'
                      >
                    >;
                    integration?: Maybe<
                      { __typename?: 'RealtyIntegration' } & Pick<RealtyIntegration, 'id'>
                    >;
                    location: { __typename?: 'LocationType' } & Pick<
                      LocationType,
                      'id' | 'postalCode' | 'street' | 'district'
                    > & {
                        city: { __typename?: 'CityType' } & Pick<CityType, 'id' | 'name'> & {
                            state: { __typename?: 'StateType' } & Pick<
                              StateType,
                              'id' | 'slug' | 'name'
                            >;
                          };
                      };
                    medias: Array<
                      Maybe<
                        { __typename?: 'RealtyMediaType' } & Pick<
                          RealtyMediaType,
                          'id' | 'order' | 'url' | 'isMain'
                        > & {
                            optimizedUrls?: Maybe<
                              { __typename?: 'RealtyMediaOptimizedUrlType' } & Pick<
                                RealtyMediaOptimizedUrlType,
                                'xs'
                              >
                            >;
                          }
                      >
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type CityNormalizationFieldsFragment = { __typename?: 'CityType' } & Pick<
  CityType,
  'id' | 'name'
> & { state: { __typename?: 'StateType' } & Pick<StateType, 'id' | 'code'> };

export type DistrictNormFieldsFragment = { __typename?: 'DistrictNormType' } & Pick<
  DistrictNormType,
  'id' | 'norm' | 'normSlug' | 'relatedCount'
> & { city: { __typename?: 'CityType' } & CityNormalizationFieldsFragment };

export type DistrictFieldFragment = { __typename?: 'DistrictType' } & Pick<
  DistrictType,
  'id' | 'district' | 'norm' | 'isNormalized' | 'origin'
> & { city: { __typename?: 'CityType' } & CityNormalizationFieldsFragment };

export type VentureFieldsFragment = { __typename?: 'VentureType' } & Pick<
  VentureType,
  'id' | 'relatedCount' | 'name'
> & {
    location?: Maybe<
      { __typename?: 'LocationType' } & Pick<LocationType, 'id'> & {
          city: { __typename?: 'CityType' } & CityNormalizationFieldsFragment;
        }
    >;
  };

export type VentureNameFieldsFragment = { __typename?: 'VentureNameType' } & Pick<
  VentureNameType,
  'id' | 'name' | 'norm' | 'isNormalized'
> & {
    city: { __typename?: 'CityType' } & CityNormalizationFieldsFragment;
    venture?: Maybe<{ __typename?: 'VentureType' } & Pick<VentureType, 'id' | 'name'>>;
  };

export type DistrictNormalizationListQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  isNormalized?: Maybe<Scalars['Boolean']>;
  cities?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;

export type DistrictNormalizationListQuery = { __typename?: 'Query' } & {
  districts?: Maybe<
    { __typename?: 'DistrictTypeConnection' } & Pick<DistrictTypeConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & PagInfoFieldsFragment;
        edges: Array<
          Maybe<
            { __typename?: 'DistrictTypeEdge' } & {
              node?: Maybe<{ __typename?: 'DistrictType' } & DistrictFieldFragment>;
            }
          >
        >;
      }
  >;
};

export type VentureNormalizationListQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  isNormalized?: Maybe<Scalars['Boolean']>;
  cities?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;

export type VentureNormalizationListQuery = { __typename?: 'Query' } & {
  ventureNames?: Maybe<
    { __typename?: 'VentureNameTypeConnection' } & Pick<VentureNameTypeConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & PagInfoFieldsFragment;
        edges: Array<
          Maybe<
            { __typename?: 'VentureNameTypeEdge' } & {
              node?: Maybe<{ __typename?: 'VentureNameType' } & VentureNameFieldsFragment>;
            }
          >
        >;
      }
  >;
};

export type DistrictTermsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  isNormalized?: Maybe<Scalars['Boolean']>;
  cities?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;

export type DistrictTermsQuery = { __typename?: 'Query' } & {
  districtNorms?: Maybe<
    { __typename?: 'DistrictNormTypeConnection' } & Pick<
      DistrictNormTypeConnection,
      'totalCount'
    > & {
        pageInfo: { __typename?: 'PageInfo' } & PagInfoFieldsFragment;
        edges: Array<
          Maybe<
            { __typename?: 'DistrictNormTypeEdge' } & {
              node?: Maybe<{ __typename?: 'DistrictNormType' } & DistrictNormFieldsFragment>;
            }
          >
        >;
      }
  >;
};

export type VentureTermsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  isNormalized?: Maybe<Scalars['Boolean']>;
  cities?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;

export type VentureTermsQuery = { __typename?: 'Query' } & {
  ventures?: Maybe<
    { __typename?: 'VentureTypeConnection' } & Pick<VentureTypeConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & PagInfoFieldsFragment;
        edges: Array<
          Maybe<
            { __typename?: 'VentureTypeEdge' } & {
              node?: Maybe<{ __typename?: 'VentureType' } & VentureFieldsFragment>;
            }
          >
        >;
      }
  >;
};

export type DistrictBatchUpdateMutationVariables = Exact<{
  ids: Array<Maybe<Scalars['ID']>>;
  updates: Array<Maybe<BatchUpdateInput>>;
}>;

export type DistrictBatchUpdateMutation = { __typename?: 'Mutation' } & {
  districtBatchUpdate?: Maybe<
    { __typename?: 'DistrictBatchUpdatePayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & MutErrorFieldsFragment>>;
      district?: Maybe<Array<Maybe<{ __typename?: 'DistrictType' } & DistrictFieldFragment>>>;
    }
  >;
};

export type VentureNameBatchUpdateMutationVariables = Exact<{
  ids: Array<Maybe<Scalars['ID']>>;
  updates: Array<Maybe<BatchUpdateInput>>;
}>;

export type VentureNameBatchUpdateMutation = { __typename?: 'Mutation' } & {
  ventureNameBatchUpdate?: Maybe<
    { __typename?: 'VentureNameBatchUpdatePayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & MutErrorFieldsFragment>>;
      ventureName?: Maybe<
        Array<Maybe<{ __typename?: 'VentureNameType' } & VentureNameFieldsFragment>>
      >;
    }
  >;
};

export type DistrictNormBatchDeleteMutationVariables = Exact<{
  ids: Array<Maybe<Scalars['ID']>>;
}>;

export type DistrictNormBatchDeleteMutation = { __typename?: 'Mutation' } & {
  districtNormBatchDelete?: Maybe<
    { __typename?: 'DistrictNormBatchDeletePayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & MutErrorFieldsFragment>>;
      districtNorm?: Maybe<
        Array<Maybe<{ __typename?: 'DistrictNormType' } & DistrictNormFieldsFragment>>
      >;
    }
  >;
};

export type VentureBatchDeleteMutationVariables = Exact<{
  ids: Array<Maybe<Scalars['ID']>>;
}>;

export type VentureBatchDeleteMutation = { __typename?: 'Mutation' } & {
  ventureBatchDelete?: Maybe<
    { __typename?: 'VentureBatchDeletePayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & MutErrorFieldsFragment>>;
      venture?: Maybe<Array<Maybe<{ __typename?: 'VentureType' } & VentureFieldsFragment>>>;
    }
  >;
};

export type DistrictNormCreateMutationVariables = Exact<{
  norm: Scalars['String'];
}>;

export type DistrictNormCreateMutation = { __typename?: 'Mutation' } & {
  districtNormCreate?: Maybe<
    { __typename?: 'DistrictNormCreatePayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & MutErrorFieldsFragment>>;
      districtNorm?: Maybe<{ __typename?: 'DistrictNormType' } & DistrictNormFieldsFragment>;
    }
  >;
};

export type DistrictNormDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DistrictNormDeleteMutation = { __typename?: 'Mutation' } & {
  districtNormDelete?: Maybe<
    { __typename?: 'DistrictNormDeletePayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & MutErrorFieldsFragment>>;
      districtNorm?: Maybe<{ __typename?: 'DistrictNormType' } & DistrictNormFieldsFragment>;
    }
  >;
};

export type VentureDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type VentureDeleteMutation = { __typename?: 'Mutation' } & {
  ventureDelete?: Maybe<
    { __typename?: 'VentureDeletePayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & MutErrorFieldsFragment>>;
      venture?: Maybe<{ __typename?: 'VentureType' } & VentureFieldsFragment>;
    }
  >;
};

export type DistrictNormUpdateMutationVariables = Exact<{
  norm?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}>;

export type DistrictNormUpdateMutation = { __typename?: 'Mutation' } & {
  districtNormUpdate?: Maybe<
    { __typename?: 'DistrictNormUpdatePayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & MutErrorFieldsFragment>>;
      districtNorm?: Maybe<{ __typename?: 'DistrictNormType' } & DistrictNormFieldsFragment>;
    }
  >;
};

export type VentureUpdateMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}>;

export type VentureUpdateMutation = { __typename?: 'Mutation' } & {
  ventureUpdate?: Maybe<
    { __typename?: 'VentureUpdatePayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & MutErrorFieldsFragment>>;
      venture?: Maybe<{ __typename?: 'VentureType' } & VentureFieldsFragment>;
    }
  >;
};

export type PlanBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type PlanBySlugQuery = { __typename?: 'Query' } & {
  planBySlug?: Maybe<
    { __typename?: 'PlanType' } & Pick<
      PlanType,
      'id' | 'kind' | 'code' | 'name' | 'price' | 'description' | 'quantity'
    > & { country?: Maybe<{ __typename?: 'CountryType' } & Pick<CountryType, 'id' | 'name'>> }
  >;
};

export type PlansQueryVariables = Exact<{
  public?: Maybe<Scalars['Boolean']>;
}>;

export type PlansQuery = { __typename?: 'Query' } & {
  plans?: Maybe<
    { __typename?: 'PlanTypeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'PlanTypeEdge' } & {
            node?: Maybe<
              { __typename?: 'PlanType' } & Pick<
                PlanType,
                'id' | 'kind' | 'code' | 'name' | 'price' | 'description' | 'quantity'
              > & {
                  country?: Maybe<
                    { __typename?: 'CountryType' } & Pick<CountryType, 'id' | 'name'>
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
};

export type PlansForCityQueryVariables = Exact<{
  city: Scalars['ID'];
  kind: Scalars['String'];
}>;

export type PlansForCityQuery = { __typename?: 'Query' } & {
  plansForCity?: Maybe<
    Array<
      Maybe<
        { __typename?: 'PlanType' } & Pick<
          PlanType,
          'id' | 'kind' | 'code' | 'name' | 'price' | 'description' | 'quantity' | 'promotionPrice'
        > & { country?: Maybe<{ __typename?: 'CountryType' } & Pick<CountryType, 'id' | 'name'>> }
      >
    >
  >;
};

export type RealestateDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RealestateDetailQuery = { __typename?: 'Query' } & {
  realty?: Maybe<
    { __typename?: 'RealtyType' } & Pick<
      RealtyType,
      | 'id'
      | 'code'
      | 'description'
      | 'status'
      | 'salePrice'
      | 'rentPrice'
      | 'vacationPrice'
      | 'condominiumPrice'
      | 'iptuPrice'
      | 'isForSale'
      | 'isForRent'
      | 'isForVacation'
      | 'isForExchange'
      | 'isCurated'
      | 'allowNegotiation'
      | 'allowFinancing'
      | 'category'
      | 'area'
      | 'bedrooms'
      | 'suites'
      | 'bathrooms'
      | 'garages'
      | 'energyEfficiency'
      | 'videoUrl'
      | 'tourUrl'
      | 'commercialDistrict'
      | 'streetNumber'
      | 'complement'
      | 'reference'
      | 'geopoint'
      | 'fulltimeSecurity'
      | 'swimmingPool'
      | 'playground'
      | 'greenArea'
    > & {
        venture?: Maybe<{ __typename?: 'VentureType' } & Pick<VentureType, 'id' | 'slug' | 'name'>>;
        location: { __typename?: 'LocationType' } & Pick<
          LocationType,
          'street' | 'district' | 'postalCode' | 'geopoint'
        > & {
            city: { __typename?: 'CityType' } & Pick<CityType, 'name' | 'geopoint'> & {
                state: { __typename?: 'StateType' } & Pick<StateType, 'name' | 'code'>;
              };
          };
        medias: Array<
          Maybe<
            { __typename?: 'RealtyMediaType' } & Pick<
              RealtyMediaType,
              'url' | 'caption' | 'description' | 'isMain' | 'optimized'
            > & {
                optimizedUrls?: Maybe<
                  { __typename?: 'RealtyMediaOptimizedUrlType' } & Pick<
                    RealtyMediaOptimizedUrlType,
                    'xl' | 'lg' | 'md' | 'xs' | 'sm'
                  >
                >;
              }
          >
        >;
        user?: Maybe<
          { __typename?: 'PublicUserType' } & Pick<
            PublicUserType,
            | 'firstName'
            | 'lastName'
            | 'fullName'
            | 'advertisingName'
            | 'slug'
            | 'avatarUrl'
            | 'id'
            | 'realtiesCached'
            | 'cellphone'
            | 'hasWhatsapp'
            | 'kind'
            | 'phone'
            | 'allowScheduling'
            | 'optimized'
          > & {
              optimizedAvatarUrls?: Maybe<
                { __typename?: 'UserOptimizedAvatarUrlType' } & Pick<
                  UserOptimizedAvatarUrlType,
                  'xs' | 'sm' | 'md'
                >
              >;
            }
        >;
        similars: Array<
          { __typename?: 'RealtyType' } & Pick<
            RealtyType,
            | 'id'
            | 'status'
            | 'salePrice'
            | 'rentPrice'
            | 'vacationPrice'
            | 'condominiumPrice'
            | 'iptuPrice'
            | 'isForSale'
            | 'isForRent'
            | 'isForVacation'
            | 'isForExchange'
            | 'category'
            | 'area'
            | 'bedrooms'
            | 'suites'
            | 'bathrooms'
            | 'garages'
            | 'isCurated'
            | 'commercialDistrict'
          > & {
              location: { __typename?: 'LocationType' } & Pick<
                LocationType,
                'district' | 'districtSlug' | 'street'
              > & {
                  city: { __typename?: 'CityType' } & Pick<CityType, 'name' | 'slug'> & {
                      state: { __typename?: 'StateType' } & Pick<StateType, 'name' | 'slug'>;
                    };
                };
              medias: Array<
                Maybe<
                  { __typename?: 'RealtyMediaType' } & Pick<
                    RealtyMediaType,
                    'optimized' | 'caption' | 'description' | 'url'
                  > & {
                      optimizedUrls?: Maybe<
                        { __typename?: 'RealtyMediaOptimizedUrlType' } & Pick<
                          RealtyMediaOptimizedUrlType,
                          'md' | 'sm' | 'xs'
                        >
                      >;
                    }
                >
              >;
            }
        >;
      }
  >;
};

export type RealestateHitCreateMutationVariables = Exact<{
  id: Scalars['ID'];
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
}>;

export type RealestateHitCreateMutation = { __typename?: 'Mutation' } & {
  realtyHitCreate?: Maybe<
    { __typename?: 'RealtyHitCreateMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
    }
  >;
};

export type RealestateLeadCreateMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  msg?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  finality: RealtyLeadFinality;
  realty: Scalars['ID'];
  kind: RealtyLeadKind;
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
}>;

export type RealestateLeadCreateMutation = { __typename?: 'Mutation' } & {
  realtyLeadCreate?: Maybe<
    { __typename?: 'RealtyLeadCreateMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      realtyLead?: Maybe<{ __typename?: 'RealtyLeadType' } & Pick<RealtyLeadType, 'id'>>;
    }
  >;
};

export type RealestateListingFiltersQueryVariables = Exact<{
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  isForSale?: Maybe<Scalars['Boolean']>;
  isForRent?: Maybe<Scalars['Boolean']>;
  isForExchange?: Maybe<Scalars['Boolean']>;
  isForVacation?: Maybe<Scalars['Boolean']>;
  street?: Maybe<Scalars['String']>;
  bedrooms?: Maybe<Scalars['Int']>;
  garages?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
  suites?: Maybe<Scalars['Int']>;
  salePriceMin?: Maybe<Scalars['Float']>;
  salePriceMax?: Maybe<Scalars['Float']>;
  rentPriceMin?: Maybe<Scalars['Float']>;
  rentPriceMax?: Maybe<Scalars['Float']>;
  condominiumPriceMin?: Maybe<Scalars['Float']>;
  condominiumPriceMax?: Maybe<Scalars['Float']>;
  iptuPriceMin?: Maybe<Scalars['Float']>;
  iptuPriceMax?: Maybe<Scalars['Float']>;
  areaMin?: Maybe<Scalars['Float']>;
  areaMax?: Maybe<Scalars['Float']>;
  first?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  geosort?: Maybe<Scalars['GeoPoint']>;
  user?: Maybe<Scalars['ID']>;
}>;

export type RealestateListingFiltersQuery = { __typename?: 'Query' } & {
  realties?: Maybe<
    { __typename?: 'RealtyTypeConnection' } & {
      filters: { __typename?: 'RealtyFiltersType' } & Pick<
        RealtyFiltersType,
        | 'minSalePrice'
        | 'maxSalePrice'
        | 'minRentPrice'
        | 'maxRentPrice'
        | 'minVacationPrice'
        | 'maxVacationPrice'
        | 'minCondomoniumPrice'
        | 'maxCondominiumPrice'
        | 'minArea'
        | 'maxArea'
      >;
    }
  >;
};

export type RealestateListingQueryVariables = Exact<{
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  districtList?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<Scalars['String']>;
  isForSale?: Maybe<Scalars['Boolean']>;
  isForRent?: Maybe<Scalars['Boolean']>;
  isForExchange?: Maybe<Scalars['Boolean']>;
  isForVacation?: Maybe<Scalars['Boolean']>;
  street?: Maybe<Scalars['String']>;
  bedrooms?: Maybe<Scalars['Int']>;
  garages?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
  suites?: Maybe<Scalars['Int']>;
  salePriceMin?: Maybe<Scalars['Float']>;
  salePriceMax?: Maybe<Scalars['Float']>;
  rentPriceMin?: Maybe<Scalars['Float']>;
  rentPriceMax?: Maybe<Scalars['Float']>;
  condominiumPriceMin?: Maybe<Scalars['Float']>;
  condominiumPriceMax?: Maybe<Scalars['Float']>;
  iptuPriceMin?: Maybe<Scalars['Float']>;
  iptuPriceMax?: Maybe<Scalars['Float']>;
  areaMin?: Maybe<Scalars['Float']>;
  areaMax?: Maybe<Scalars['Float']>;
  first?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  geosort?: Maybe<Scalars['GeoPoint']>;
  user?: Maybe<Scalars['ID']>;
  forPartnership?: Maybe<Scalars['Boolean']>;
}>;

export type RealestateListingQuery = { __typename?: 'Query' } & {
  realties?: Maybe<
    { __typename?: 'RealtyTypeConnection' } & Pick<RealtyTypeConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
        edges: Array<
          Maybe<
            { __typename?: 'RealtyTypeEdge' } & {
              node?: Maybe<
                { __typename?: 'RealtyType' } & Pick<
                  RealtyType,
                  | 'id'
                  | 'ventureName'
                  | 'salePrice'
                  | 'rentPrice'
                  | 'vacationPrice'
                  | 'condominiumPrice'
                  | 'iptuPrice'
                  | 'isForSale'
                  | 'isForRent'
                  | 'isForVacation'
                  | 'isForExchange'
                  | 'allowNegotiation'
                  | 'allowFinancing'
                  | 'category'
                  | 'area'
                  | 'bedrooms'
                  | 'suites'
                  | 'bathrooms'
                  | 'garages'
                  | 'energyEfficiency'
                  | 'distance'
                  | 'commercialDistrict'
                  | 'streetNumber'
                  | 'isCurated'
                > & {
                    user?: Maybe<
                      { __typename?: 'PublicUserType' } & Pick<PublicUserType, 'id' | 'kind'>
                    >;
                    location: { __typename?: 'LocationType' } & Pick<
                      LocationType,
                      'district' | 'districtSlug' | 'street' | 'id'
                    > & {
                        city: { __typename?: 'CityType' } & Pick<CityType, 'name' | 'slug'> & {
                            state: { __typename?: 'StateType' } & Pick<
                              StateType,
                              'name' | 'code' | 'slug'
                            >;
                          };
                      };
                    venture?: Maybe<
                      { __typename?: 'VentureType' } & Pick<VentureType, 'id' | 'name'>
                    >;
                    medias: Array<
                      Maybe<
                        { __typename?: 'RealtyMediaType' } & Pick<
                          RealtyMediaType,
                          'url' | 'caption' | 'description' | 'isMain' | 'optimized'
                        > & {
                            optimizedUrls?: Maybe<
                              { __typename?: 'RealtyMediaOptimizedUrlType' } & Pick<
                                RealtyMediaOptimizedUrlType,
                                'md' | 'sm' | 'xs'
                              >
                            >;
                          }
                      >
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type RealestateRedirectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RealestateRedirectQuery = { __typename?: 'Query' } & {
  realty?: Maybe<
    { __typename?: 'RealtyType' } & Pick<
      RealtyType,
      'id' | 'isForSale' | 'isForRent' | 'isForVacation' | 'isForExchange' | 'category'
    > & {
        location: { __typename?: 'LocationType' } & Pick<LocationType, 'id' | 'districtSlug'> & {
            city: { __typename?: 'CityType' } & Pick<CityType, 'id' | 'slug'> & {
                state: { __typename?: 'StateType' } & Pick<StateType, 'id' | 'code'>;
              };
          };
      }
  >;
};

export type RealtiesPartnershipListQueryVariables = Exact<{
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  isForSale?: Maybe<Scalars['Boolean']>;
  isForRent?: Maybe<Scalars['Boolean']>;
  isForExchange?: Maybe<Scalars['Boolean']>;
  isForVacation?: Maybe<Scalars['Boolean']>;
  street?: Maybe<Scalars['String']>;
  bedrooms?: Maybe<Scalars['Int']>;
  garages?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
  suites?: Maybe<Scalars['Int']>;
  salePriceMin?: Maybe<Scalars['Float']>;
  salePriceMax?: Maybe<Scalars['Float']>;
  rentPriceMin?: Maybe<Scalars['Float']>;
  rentPriceMax?: Maybe<Scalars['Float']>;
  condominiumPriceMin?: Maybe<Scalars['Float']>;
  condominiumPriceMax?: Maybe<Scalars['Float']>;
  iptuPriceMin?: Maybe<Scalars['Float']>;
  iptuPriceMax?: Maybe<Scalars['Float']>;
  areaMin?: Maybe<Scalars['Float']>;
  areaMax?: Maybe<Scalars['Float']>;
  first?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  geosort?: Maybe<Scalars['GeoPoint']>;
  user?: Maybe<Scalars['ID']>;
}>;

export type RealtiesPartnershipListQuery = { __typename?: 'Query' } & {
  realties?: Maybe<
    { __typename?: 'RealtyTypeConnection' } & Pick<RealtyTypeConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'startCursor' | 'endCursor'>;
        edges: Array<
          Maybe<
            { __typename?: 'RealtyTypeEdge' } & {
              node?: Maybe<
                { __typename?: 'RealtyType' } & Pick<
                  RealtyType,
                  | 'id'
                  | 'status'
                  | 'code'
                  | 'category'
                  | 'isForSale'
                  | 'salePrice'
                  | 'iptuPrice'
                  | 'isForRent'
                  | 'isForVacation'
                  | 'rentPrice'
                  | 'isForExchange'
                  | 'vacationPrice'
                  | 'condominiumPrice'
                  | 'videoUrl'
                  | 'streetNumber'
                  | 'complement'
                  | 'area'
                  | 'ventureName'
                  | 'bedrooms'
                  | 'suites'
                  | 'bathrooms'
                  | 'garages'
                > & {
                    user?: Maybe<
                      { __typename?: 'PublicUserType' } & Pick<
                        PublicUserType,
                        | 'firstName'
                        | 'lastName'
                        | 'fullName'
                        | 'advertisingName'
                        | 'avatarUrl'
                        | 'id'
                        | 'cellphone'
                        | 'hasWhatsapp'
                        | 'kind'
                        | 'phone'
                        | 'optimized'
                      > & {
                          optimizedAvatarUrls?: Maybe<
                            { __typename?: 'UserOptimizedAvatarUrlType' } & Pick<
                              UserOptimizedAvatarUrlType,
                              'xs' | 'sm' | 'md'
                            >
                          >;
                        }
                    >;
                    location: { __typename?: 'LocationType' } & Pick<
                      LocationType,
                      'id' | 'postalCode' | 'street' | 'district'
                    > & {
                        city: { __typename?: 'CityType' } & Pick<CityType, 'name'> & {
                            state: { __typename?: 'StateType' } & Pick<StateType, 'slug' | 'name'>;
                          };
                      };
                    medias: Array<
                      Maybe<
                        { __typename?: 'RealtyMediaType' } & Pick<
                          RealtyMediaType,
                          'id' | 'order' | 'url' | 'isMain'
                        > & {
                            optimizedUrls?: Maybe<
                              { __typename?: 'RealtyMediaOptimizedUrlType' } & Pick<
                                RealtyMediaOptimizedUrlType,
                                'xs'
                              >
                            >;
                          }
                      >
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type RealtySummaryQueryVariables = Exact<{
  start: Scalars['DateTime'];
}>;

export type RealtySummaryQuery = { __typename?: 'Query' } & {
  realtySummary?: Maybe<
    { __typename?: 'RealtySummaryType' } & Pick<
      RealtySummaryType,
      'activeRealties' | 'total' | 'schedules' | 'whatsappLeads' | 'phoneLeads' | 'contacts'
    > & {
        hits?: Maybe<
          Array<
            Maybe<
              { __typename?: 'RealtyHitsByDayType' } & Pick<RealtyHitsByDayType, 'hits' | 'day'>
            >
          >
        >;
        leads?: Maybe<
          Array<
            Maybe<
              { __typename?: 'RealtyLeadsByDayType' } & Pick<RealtyLeadsByDayType, 'leads' | 'day'>
            >
          >
        >;
      }
  >;
};

export type RealtyVentureFieldsFragment = { __typename?: 'VentureType' } & Pick<
  VentureType,
  'id' | 'slug' | 'name'
>;

export type RealtyLocationFieldsFragment = { __typename?: 'LocationType' } & Pick<
  LocationType,
  'street' | 'district' | 'districtSlug' | 'postalCode' | 'geopoint'
> & {
    city: { __typename?: 'CityType' } & Pick<CityType, 'name' | 'slug' | 'geopoint'> & {
        state: { __typename?: 'StateType' } & Pick<StateType, 'name' | 'code'>;
      };
  };

export type RealtyMediaFieldsFragment = { __typename?: 'RealtyMediaType' } & Pick<
  RealtyMediaType,
  'url' | 'caption' | 'description' | 'isMain' | 'optimized'
> & {
    optimizedUrls?: Maybe<
      { __typename?: 'RealtyMediaOptimizedUrlType' } & Pick<
        RealtyMediaOptimizedUrlType,
        'xl' | 'lg' | 'md' | 'xs' | 'sm'
      >
    >;
  };

export type RealtyUserFieldsFragment = { __typename?: 'PublicUserType' } & Pick<
  PublicUserType,
  | 'firstName'
  | 'lastName'
  | 'fullName'
  | 'advertisingName'
  | 'slug'
  | 'avatarUrl'
  | 'id'
  | 'realtiesCached'
  | 'cellphone'
  | 'hasWhatsapp'
  | 'kind'
  | 'phone'
  | 'allowScheduling'
  | 'optimized'
  | 'creci'
> & {
    optimizedAvatarUrls?: Maybe<
      { __typename?: 'UserOptimizedAvatarUrlType' } & Pick<
        UserOptimizedAvatarUrlType,
        'xs' | 'sm' | 'md'
      >
    >;
  };

export type RealtyDetailSimilarFieldsFragment = { __typename?: 'RealtyType' } & Pick<
  RealtyType,
  | 'id'
  | 'status'
  | 'salePrice'
  | 'rentPrice'
  | 'vacationPrice'
  | 'condominiumPrice'
  | 'iptuPrice'
  | 'isForSale'
  | 'isForRent'
  | 'isForVacation'
  | 'isForExchange'
  | 'category'
  | 'area'
  | 'bedrooms'
  | 'suites'
  | 'bathrooms'
  | 'garages'
  | 'isCurated'
  | 'commercialDistrict'
> & {
    location: { __typename?: 'LocationType' } & RealtyLocationFieldsFragment;
    medias: Array<Maybe<{ __typename?: 'RealtyMediaType' } & RealtyMediaFieldsFragment>>;
  };

export type RealtyDetailFieldsFragment = { __typename?: 'RealtyType' } & Pick<
  RealtyType,
  | 'id'
  | 'code'
  | 'description'
  | 'status'
  | 'salePrice'
  | 'rentPrice'
  | 'vacationPrice'
  | 'condominiumPrice'
  | 'iptuPrice'
  | 'isForSale'
  | 'isForRent'
  | 'isForVacation'
  | 'isForExchange'
  | 'isCurated'
  | 'allowNegotiation'
  | 'allowFinancing'
  | 'category'
  | 'area'
  | 'bedrooms'
  | 'suites'
  | 'bathrooms'
  | 'garages'
  | 'energyEfficiency'
  | 'videoUrl'
  | 'tourUrl'
  | 'commercialDistrict'
  | 'streetNumber'
  | 'complement'
  | 'reference'
  | 'geopoint'
  | 'fulltimeSecurity'
  | 'swimmingPool'
  | 'playground'
  | 'greenArea'
  | 'originDescription'
  | 'rentPeriod'
> & {
    venture?: Maybe<{ __typename?: 'VentureType' } & RealtyVentureFieldsFragment>;
    location: { __typename?: 'LocationType' } & RealtyLocationFieldsFragment;
    medias: Array<Maybe<{ __typename?: 'RealtyMediaType' } & RealtyMediaFieldsFragment>>;
    user?: Maybe<{ __typename?: 'PublicUserType' } & RealtyUserFieldsFragment>;
    similars: Array<{ __typename?: 'RealtyType' } & RealtyDetailSimilarFieldsFragment>;
  };

export type RealtyDetailQueryVariables = Exact<{
  id: Scalars['ID'];
  isForSale?: Maybe<Scalars['Boolean']>;
  isForRent?: Maybe<Scalars['Boolean']>;
}>;

export type RealtyDetailQuery = { __typename?: 'Query' } & {
  realty?: Maybe<{ __typename?: 'RealtyType' } & RealtyDetailFieldsFragment>;
};

export type RealtiesByProximityQueryVariables = Exact<{
  stateSlug?: Maybe<Scalars['String']>;
  citySlug?: Maybe<Scalars['String']>;
  districtSlug?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  isForSale?: Maybe<Scalars['Boolean']>;
  isForRent?: Maybe<Scalars['Boolean']>;
  isForExchange?: Maybe<Scalars['Boolean']>;
  isForVacation?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  forPartnership?: Maybe<Scalars['Boolean']>;
}>;

export type RealtiesByProximityQuery = { __typename?: 'Query' } & {
  realtiesByProximity?: Maybe<
    { __typename?: 'RealtyByProximityType' } & Pick<RealtyByProximityType, 'kind'> & {
        realties?: Maybe<
          Array<
            Maybe<
              { __typename?: 'RealtyType' } & Pick<
                RealtyType,
                | 'id'
                | 'ventureName'
                | 'salePrice'
                | 'rentPrice'
                | 'vacationPrice'
                | 'condominiumPrice'
                | 'iptuPrice'
                | 'isForSale'
                | 'isForRent'
                | 'isForVacation'
                | 'isForExchange'
                | 'allowNegotiation'
                | 'allowFinancing'
                | 'category'
                | 'area'
                | 'bedrooms'
                | 'suites'
                | 'bathrooms'
                | 'garages'
                | 'energyEfficiency'
                | 'distance'
                | 'commercialDistrict'
                | 'streetNumber'
              > & {
                  user?: Maybe<
                    { __typename?: 'PublicUserType' } & Pick<PublicUserType, 'id' | 'kind'>
                  >;
                  location: { __typename?: 'LocationType' } & Pick<
                    LocationType,
                    'district' | 'districtSlug' | 'street' | 'id'
                  > & {
                      city: { __typename?: 'CityType' } & Pick<CityType, 'name' | 'slug'> & {
                          state: { __typename?: 'StateType' } & Pick<
                            StateType,
                            'name' | 'code' | 'slug'
                          >;
                        };
                    };
                  medias: Array<
                    Maybe<
                      { __typename?: 'RealtyMediaType' } & Pick<
                        RealtyMediaType,
                        'url' | 'caption' | 'description' | 'isMain'
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type RealtyCurationSkipMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RealtyCurationSkipMutation = { __typename?: 'Mutation' } & {
  realtyCurationSkip?: Maybe<
    { __typename?: 'RealtyCurationSkipMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      realty?: Maybe<{ __typename?: 'RealtyType' } & Pick<RealtyType, 'id'>>;
    }
  >;
};

export type RealtyCurationTaskQueryVariables = Exact<{ [key: string]: never }>;

export type RealtyCurationTaskQuery = { __typename?: 'Query' } & {
  realtyCurationTask?: Maybe<
    { __typename?: 'RealtyCurationTaskType' } & {
      realty?: Maybe<
        { __typename?: 'RealtyType' } & Pick<
          RealtyType,
          | 'id'
          | 'status'
          | 'code'
          | 'category'
          | 'isForSale'
          | 'salePrice'
          | 'iptuPrice'
          | 'isForRent'
          | 'rentPrice'
          | 'isForExchange'
          | 'vacationPrice'
          | 'condominiumPrice'
          | 'originDescription'
          | 'videoUrl'
          | 'streetNumber'
          | 'complement'
          | 'area'
          | 'ventureName'
          | 'bedrooms'
          | 'suites'
          | 'bathrooms'
          | 'garages'
          | 'rentPeriod'
        > & {
            user?: Maybe<
              { __typename?: 'PublicUserType' } & Pick<PublicUserType, 'advertisingName' | 'kind'>
            >;
            location: { __typename?: 'LocationType' } & Pick<
              LocationType,
              'id' | 'postalCode' | 'street' | 'district'
            > & {
                city: { __typename?: 'CityType' } & Pick<CityType, 'name'> & {
                    state: { __typename?: 'StateType' } & Pick<StateType, 'name'>;
                  };
              };
            medias: Array<
              Maybe<
                { __typename?: 'RealtyMediaType' } & Pick<
                  RealtyMediaType,
                  'id' | 'url' | 'isMain'
                > & {
                    optimizedUrls?: Maybe<
                      { __typename?: 'RealtyMediaOptimizedUrlType' } & Pick<
                        RealtyMediaOptimizedUrlType,
                        'xl' | 'md' | 'sm' | 'xs'
                      >
                    >;
                  }
              >
            >;
          }
      >;
      previous?: Maybe<
        { __typename?: 'RealtyType' } & Pick<
          RealtyType,
          | 'id'
          | 'status'
          | 'code'
          | 'category'
          | 'isForSale'
          | 'salePrice'
          | 'iptuPrice'
          | 'isForRent'
          | 'rentPrice'
          | 'isForExchange'
          | 'vacationPrice'
          | 'condominiumPrice'
          | 'videoUrl'
          | 'streetNumber'
          | 'complement'
          | 'area'
          | 'ventureName'
          | 'bedrooms'
          | 'suites'
          | 'bathrooms'
          | 'garages'
        > & {
            user?: Maybe<
              { __typename?: 'PublicUserType' } & Pick<PublicUserType, 'advertisingName' | 'kind'>
            >;
            location: { __typename?: 'LocationType' } & Pick<
              LocationType,
              'id' | 'postalCode' | 'street' | 'district'
            > & { city: { __typename?: 'CityType' } & Pick<CityType, 'name'> };
          }
      >;
      duplicates?: Maybe<
        { __typename?: 'RealtyTypeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'RealtyTypeEdge' } & {
                node?: Maybe<
                  { __typename?: 'RealtyType' } & Pick<
                    RealtyType,
                    | 'id'
                    | 'rank'
                    | 'status'
                    | 'code'
                    | 'category'
                    | 'isForSale'
                    | 'salePrice'
                    | 'iptuPrice'
                    | 'isForRent'
                    | 'rentPrice'
                    | 'isForExchange'
                    | 'vacationPrice'
                    | 'condominiumPrice'
                    | 'videoUrl'
                    | 'streetNumber'
                    | 'complement'
                    | 'area'
                    | 'ventureName'
                    | 'bedrooms'
                    | 'suites'
                    | 'bathrooms'
                    | 'garages'
                  > & {
                      location: { __typename?: 'LocationType' } & Pick<
                        LocationType,
                        'id' | 'postalCode' | 'street' | 'district'
                      > & { city: { __typename?: 'CityType' } & Pick<CityType, 'name'> };
                      medias: Array<
                        Maybe<
                          { __typename?: 'RealtyMediaType' } & Pick<
                            RealtyMediaType,
                            'id' | 'order' | 'url' | 'isMain'
                          > & {
                              optimizedUrls?: Maybe<
                                { __typename?: 'RealtyMediaOptimizedUrlType' } & Pick<
                                  RealtyMediaOptimizedUrlType,
                                  'xs'
                                >
                              >;
                            }
                        >
                      >;
                    }
                >;
              }
            >
          >;
        }
      >;
    }
  >;
};

export type RealtyInactivateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RealtyInactivateMutation = { __typename?: 'Mutation' } & {
  realtyInactivate?: Maybe<
    { __typename?: 'RealtyInactivateMutationPayload' } & {
      realty?: Maybe<{ __typename?: 'RealtyType' } & Pick<RealtyType, 'id' | 'status'>>;
    }
  >;
};

export type RealtyLeadsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  kind?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;

export type RealtyLeadsQuery = { __typename?: 'Query' } & {
  realtyLeads?: Maybe<
    { __typename?: 'RealtyLeadTypeConnection' } & Pick<RealtyLeadTypeConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'startCursor' | 'endCursor'>;
        edges: Array<
          Maybe<
            { __typename?: 'RealtyLeadTypeEdge' } & Pick<RealtyLeadTypeEdge, 'cursor'> & {
                node?: Maybe<
                  { __typename?: 'RealtyLeadType' } & Pick<
                    RealtyLeadType,
                    'id' | 'name' | 'email' | 'phone' | 'createdAt' | 'date' | 'msg' | 'finality'
                  > & {
                      realty: { __typename?: 'RealtyType' } & Pick<
                        RealtyType,
                        | 'id'
                        | 'code'
                        | 'category'
                        | 'isForSale'
                        | 'salePrice'
                        | 'iptuPrice'
                        | 'isForRent'
                        | 'isForVacation'
                        | 'rentPrice'
                        | 'isForExchange'
                        | 'vacationPrice'
                        | 'condominiumPrice'
                        | 'videoUrl'
                        | 'streetNumber'
                        | 'complement'
                        | 'area'
                        | 'ventureName'
                        | 'bedrooms'
                        | 'suites'
                        | 'bathrooms'
                        | 'garages'
                      > & {
                          location: { __typename?: 'LocationType' } & Pick<
                            LocationType,
                            'id' | 'postalCode' | 'street' | 'district'
                          > & {
                              city: { __typename?: 'CityType' } & Pick<CityType, 'id' | 'name'> & {
                                  state: { __typename?: 'StateType' } & Pick<
                                    StateType,
                                    'id' | 'slug' | 'name'
                                  >;
                                };
                            };
                        };
                    }
                >;
              }
          >
        >;
      }
  >;
};

export type RealtyMediaCreateMutationVariables = Exact<{
  input: RealtyMediaCreateMutationInput;
}>;

export type RealtyMediaCreateMutation = { __typename?: 'Mutation' } & {
  realtyMediaCreate?: Maybe<
    { __typename?: 'RealtyMediaCreateMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      realtyMedia?: Maybe<
        { __typename?: 'RealtyMediaType' } & Pick<
          RealtyMediaType,
          'id' | 'order' | 'url' | 'isMain'
        >
      >;
    }
  >;
};

export type RealtyMediaDeleteMutationVariables = Exact<{
  media: Scalars['ID'];
}>;

export type RealtyMediaDeleteMutation = { __typename?: 'Mutation' } & {
  realtyMediaDelete?: Maybe<
    { __typename?: 'RealtyMediaDeleteMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
    }
  >;
};

export type RealtyMediaListQueryVariables = Exact<{
  realty: Scalars['ID'];
}>;

export type RealtyMediaListQuery = { __typename?: 'Query' } & {
  realtyMedias?: Maybe<
    { __typename?: 'RealtyMediaTypeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'RealtyMediaTypeEdge' } & {
            node?: Maybe<
              { __typename?: 'RealtyMediaType' } & Pick<
                RealtyMediaType,
                'id' | 'order' | 'url' | 'isMain'
              > & {
                  optimizedUrls?: Maybe<
                    { __typename?: 'RealtyMediaOptimizedUrlType' } & Pick<
                      RealtyMediaOptimizedUrlType,
                      'xs' | 'sm' | 'md'
                    >
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
};

export type RealtyMediaUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  isMain: Scalars['Boolean'];
  realty?: Maybe<Scalars['ID']>;
}>;

export type RealtyMediaUpdateMutation = { __typename?: 'Mutation' } & {
  realtyMediaUpdate?: Maybe<
    { __typename?: 'RealtyMediaUpdateMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
    }
  >;
};

export type RealtyNearbyQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  geosort?: Maybe<Scalars['GeoPoint']>;
}>;

export type RealtyNearbyQuery = { __typename?: 'Query' } & {
  realties?: Maybe<
    { __typename?: 'RealtyTypeConnection' } & Pick<RealtyTypeConnection, 'totalCount'> & {
        edges: Array<
          Maybe<
            { __typename?: 'RealtyTypeEdge' } & {
              node?: Maybe<
                { __typename?: 'RealtyType' } & Pick<
                  RealtyType,
                  | 'id'
                  | 'ventureName'
                  | 'salePrice'
                  | 'rentPrice'
                  | 'vacationPrice'
                  | 'condominiumPrice'
                  | 'iptuPrice'
                  | 'isForSale'
                  | 'isForRent'
                  | 'isForVacation'
                  | 'isForExchange'
                  | 'allowNegotiation'
                  | 'allowFinancing'
                  | 'category'
                  | 'area'
                  | 'bedrooms'
                  | 'suites'
                  | 'bathrooms'
                  | 'garages'
                  | 'energyEfficiency'
                  | 'distance'
                  | 'commercialDistrict'
                  | 'streetNumber'
                > & {
                    location: { __typename?: 'LocationType' } & Pick<
                      LocationType,
                      'district' | 'districtSlug' | 'street' | 'id'
                    > & {
                        city: { __typename?: 'CityType' } & Pick<CityType, 'name' | 'slug'> & {
                            state: { __typename?: 'StateType' } & Pick<
                              StateType,
                              'name' | 'code' | 'slug'
                            >;
                          };
                      };
                    medias: Array<
                      Maybe<
                        { __typename?: 'RealtyMediaType' } & Pick<
                          RealtyMediaType,
                          'url' | 'caption' | 'description' | 'isMain' | 'optimized'
                        > & {
                            optimizedUrls?: Maybe<
                              { __typename?: 'RealtyMediaOptimizedUrlType' } & Pick<
                                RealtyMediaOptimizedUrlType,
                                'md' | 'sm' | 'xs'
                              >
                            >;
                          }
                      >
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type RealtyPublishMutationVariables = Exact<{
  realtyId: Scalars['ID'];
}>;

export type RealtyPublishMutation = { __typename?: 'Mutation' } & {
  realtyPublish?: Maybe<
    { __typename?: 'RealtyPublishMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      realty?: Maybe<
        { __typename?: 'RealtyType' } & Pick<RealtyType, 'id' | 'status' | 'published'>
      >;
    }
  >;
};

export type RealtyThreadArchiveMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RealtyThreadArchiveMutation = { __typename?: 'Mutation' } & {
  realtyThreadArchive?: Maybe<
    { __typename?: 'RealtyThreadArchiveMutationPayload' } & {
      realtyThread?: Maybe<
        { __typename?: 'RealtyThreadType' } & Pick<RealtyThreadType, 'id' | 'isArchived'>
      >;
    }
  >;
};

export type RealtyThreadCreateMutationVariables = Exact<{
  kind: RealtyThreadKind;
  msg: Scalars['String'];
  sender: Scalars['ID'];
  recipient: Scalars['ID'];
  realty?: Maybe<Scalars['ID']>;
}>;

export type RealtyThreadCreateMutation = { __typename?: 'Mutation' } & {
  realtyThreadCreate?: Maybe<
    { __typename?: 'RealtyThreadCreateMutationPayload' } & {
      realtyThread?: Maybe<{ __typename?: 'RealtyThreadType' } & Pick<RealtyThreadType, 'id'>>;
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'message' | 'field'>>
      >;
    }
  >;
};

export type RealtyThreadListQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  recipient?: Maybe<Scalars['ID']>;
  sender?: Maybe<Scalars['ID']>;
  searchText?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;

export type RealtyThreadListQuery = { __typename?: 'Query' } & {
  realtyThreads?: Maybe<
    { __typename?: 'RealtyThreadTypeConnection' } & Pick<
      RealtyThreadTypeConnection,
      'totalCount'
    > & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'startCursor' | 'endCursor'>;
        edges: Array<
          Maybe<
            { __typename?: 'RealtyThreadTypeEdge' } & {
              node?: Maybe<
                { __typename?: 'RealtyThreadType' } & Pick<
                  RealtyThreadType,
                  'id' | 'kind' | 'isArchived' | 'isRead' | 'msg' | 'lastMsg'
                > & {
                    realty?: Maybe<{ __typename?: 'RealtyType' } & Pick<RealtyType, 'id' | 'code'>>;
                    sender: { __typename?: 'UserType' } & Pick<
                      UserType,
                      'firstName' | 'lastName' | 'fullName' | 'avatarUrl'
                    > & {
                        optimizedAvatarUrls?: Maybe<
                          { __typename?: 'UserOptimizedAvatarUrlType' } & Pick<
                            UserOptimizedAvatarUrlType,
                            'sm'
                          >
                        >;
                      };
                    recipient: { __typename?: 'UserType' } & Pick<
                      UserType,
                      'id' | 'firstName' | 'lastName' | 'fullName'
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type RealtyThreadMarkReadMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RealtyThreadMarkReadMutation = { __typename?: 'Mutation' } & {
  realtyThreadMarkRead?: Maybe<
    { __typename?: 'RealtyThreadMarkReadMutationPayload' } & {
      realtyThread?: Maybe<
        { __typename?: 'RealtyThreadType' } & Pick<RealtyThreadType, 'id' | 'isRead'>
      >;
    }
  >;
};

export type RealtyThreadMessageCreateMutationVariables = Exact<{
  msg: Scalars['String'];
  user: Scalars['ID'];
  thread: Scalars['ID'];
}>;

export type RealtyThreadMessageCreateMutation = { __typename?: 'Mutation' } & {
  realtyThreadMessageCreate?: Maybe<
    { __typename?: 'RealtyThreadMessageCreateMutationPayload' } & {
      realtyThreadMessage?: Maybe<
        { __typename?: 'RealtyThreadMessageType' } & Pick<
          RealtyThreadMessageType,
          'id' | 'createdAt' | 'msg'
        > & {
            user: { __typename?: 'UserType' } & Pick<
              UserType,
              'firstName' | 'lastName' | 'fullName' | 'avatarUrl'
            > & {
                optimizedAvatarUrls?: Maybe<
                  { __typename?: 'UserOptimizedAvatarUrlType' } & Pick<
                    UserOptimizedAvatarUrlType,
                    'sm'
                  >
                >;
              };
          }
      >;
    }
  >;
};

export type RealtyThreadMessageListQueryVariables = Exact<{
  thread: Scalars['ID'];
}>;

export type RealtyThreadMessageListQuery = { __typename?: 'Query' } & {
  realtyThreadMessages?: Maybe<
    { __typename?: 'RealtyThreadMessageTypeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'RealtyThreadMessageTypeEdge' } & {
            node?: Maybe<
              { __typename?: 'RealtyThreadMessageType' } & Pick<
                RealtyThreadMessageType,
                'id' | 'createdAt' | 'msg'
              > & {
                  user: { __typename?: 'UserType' } & Pick<
                    UserType,
                    'firstName' | 'lastName' | 'fullName' | 'avatarUrl'
                  > & {
                      optimizedAvatarUrls?: Maybe<
                        { __typename?: 'UserOptimizedAvatarUrlType' } & Pick<
                          UserOptimizedAvatarUrlType,
                          'sm'
                        >
                      >;
                    };
                }
            >;
          }
        >
      >;
    }
  >;
};

export type RealtyUnpublishMutationVariables = Exact<{
  realtyId: Scalars['ID'];
}>;

export type RealtyUnpublishMutation = { __typename?: 'Mutation' } & {
  realtyUnpublish?: Maybe<
    { __typename?: 'RealtyUnpublishMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      realty?: Maybe<
        { __typename?: 'RealtyType' } & Pick<RealtyType, 'id' | 'status' | 'published'>
      >;
    }
  >;
};

export type RealtyUpdateMutationVariables = Exact<{
  input: RealtyUpdateMutationInput;
}>;

export type RealtyUpdateMutation = { __typename?: 'Mutation' } & {
  realtyUpdate?: Maybe<
    { __typename?: 'RealtyUpdateMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      realty?: Maybe<
        { __typename?: 'RealtyType' } & Pick<
          RealtyType,
          | 'id'
          | 'code'
          | 'category'
          | 'description'
          | 'isForSale'
          | 'isForRent'
          | 'isForExchange'
          | 'area'
          | 'ventureName'
          | 'bedrooms'
          | 'suites'
          | 'bathrooms'
          | 'garages'
        >
      >;
    }
  >;
};

export type RealtyUpdateManagerMutationVariables = Exact<{
  realty: Scalars['ID'];
  status: RealtyStatus;
  observation?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  mainMedia?: Maybe<Scalars['ID']>;
  duplicatedOf?: Maybe<Scalars['ID']>;
  removedMedias?: Maybe<Array<Maybe<Scalars['ID']>>>;
  published?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<RealtyThreadProblem>;
}>;

export type RealtyUpdateManagerMutation = { __typename?: 'Mutation' } & {
  realtyUpdateManager?: Maybe<
    { __typename?: 'RealtyUpdateManagerMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      realty?: Maybe<
        { __typename?: 'RealtyType' } & Pick<RealtyType, 'id' | 'status' | 'published'>
      >;
    }
  >;
};

export type SalespersonClientsListQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
}>;

export type SalespersonClientsListQuery = { __typename?: 'Query' } & {
  userPlansSalesperson?: Maybe<
    { __typename?: 'UserPlanTypeConnection' } & Pick<UserPlanTypeConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & PagInfoFieldsFragment;
        edges: Array<
          Maybe<
            { __typename?: 'UserPlanTypeEdge' } & {
              node?: Maybe<
                { __typename?: 'UserPlanType' } & Pick<
                  UserPlanType,
                  'id' | 'subscriptionStatus' | 'advertisingName' | 'slotsTotal'
                > & {
                    user?: Maybe<
                      { __typename?: 'UserType' } & Pick<UserType, 'id' | 'email'> & {
                          realtiesCounts: { __typename?: 'UserRealtiesCountType' } & Pick<
                            UserRealtiesCountType,
                            'countPublished'
                          >;
                          currentIntegration?: Maybe<
                            { __typename?: 'IntegrationType' } & Pick<
                              IntegrationType,
                              | 'id'
                              | 'origin'
                              | 'processedAt'
                              | 'foundCount'
                              | 'foundNoPostalCodeCount'
                              | 'status'
                              | 'error'
                            >
                          >;
                        }
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type SalespersonClientDetailsQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;

export type SalespersonClientDetailsQuery = { __typename?: 'Query' } & {
  userPlan?: Maybe<
    { __typename?: 'UserPlanType' } & Pick<
      UserPlanType,
      'id' | 'subscriptionStatus' | 'slotsTotal' | 'kind' | 'expiresAt'
    > & {
        salesperson?: Maybe<{ __typename?: 'UserType' } & Pick<UserType, 'firstName'>>;
        user?: Maybe<
          { __typename?: 'UserType' } & Pick<
            UserType,
            | 'id'
            | 'username'
            | 'firstName'
            | 'lastName'
            | 'fullName'
            | 'advertisingName'
            | 'kind'
            | 'email'
            | 'avatarUrl'
            | 'hash'
            | 'phone'
            | 'cellphone'
            | 'sendContactEmails'
            | 'sendMessageEmails'
            | 'sendMonthlySummaryEmails'
            | 'allowScheduling'
            | 'postalCode'
            | 'complement'
            | 'street'
            | 'slug'
            | 'streetNumber'
            | 'district'
          > & {
              city?: Maybe<
                { __typename?: 'CityType' } & Pick<CityType, 'name'> & {
                    state: { __typename?: 'StateType' } & Pick<StateType, 'name' | 'code'>;
                  }
              >;
              optimizedAvatarUrls?: Maybe<
                { __typename?: 'UserOptimizedAvatarUrlType' } & Pick<
                  UserOptimizedAvatarUrlType,
                  'sm'
                >
              >;
              realtiesCounts: { __typename?: 'UserRealtiesCountType' } & Pick<
                UserRealtiesCountType,
                | 'countActive'
                | 'countPublished'
                | 'countWaitingCuration'
                | 'countAvailable'
                | 'countDuplicated'
                | 'countRejected'
                | 'countAutomaticCuration'
              >;
              realtiesLeads: { __typename?: 'UserRealtiesLeadsType' } & Pick<
                UserRealtiesLeadsType,
                'hits' | 'leads' | 'whatsapps' | 'phones' | 'contacts' | 'schedules' | 'totals'
              >;
              currentIntegration?: Maybe<
                { __typename?: 'IntegrationType' } & Pick<
                  IntegrationType,
                  | 'id'
                  | 'status'
                  | 'updatedAt'
                  | 'kind'
                  | 'foundCount'
                  | 'foundNoPostalCodeCount'
                  | 'url'
                  | 'name'
                  | 'key'
                  | 'preferredDistricts'
                  | 'data'
                  | 'processedAt'
                  | 'createdAt'
                  | 'isArchived'
                  | 'origin'
                  | 'priceMinSale'
                  | 'priceMaxSale'
                  | 'priceMinRent'
                  | 'priceMaxRent'
                  | 'priority'
                  | 'forSale'
                  | 'forRent'
                  | 'rentPercentage'
                  | 'salePercentage'
                >
              >;
            }
        >;
      }
  >;
};

export type SearchQueryVariables = Exact<{
  q: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  kind_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Scalars['String']>;
  geosort?: Maybe<Scalars['GeoPoint']>;
  citySlug?: Maybe<Scalars['String']>;
  stateSlug?: Maybe<Scalars['String']>;
}>;

export type SearchQuery = { __typename?: 'Query' } & {
  search?: Maybe<
    { __typename?: 'SearchTypeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'SearchTypeEdge' } & {
            node?: Maybe<
              { __typename?: 'SearchType' } & Pick<
                SearchType,
                'kind' | 'district' | 'districtSlug' | 'street' | 'rank' | 'id' | 'distance'
              > & {
                  city: { __typename?: 'CityType' } & Pick<CityType, 'id' | 'name' | 'slug'> & {
                      state: { __typename?: 'StateType' } & Pick<
                        StateType,
                        'id' | 'name' | 'code' | 'slug'
                      >;
                    };
                  venture?: Maybe<
                    { __typename?: 'VentureType' } & Pick<VentureType, 'id' | 'name' | 'slug'>
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
};

export type SearchHitCreateMutationVariables = Exact<{
  kind: SearchHitKind;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  filters: Scalars['JSONString'];
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
}>;

export type SearchHitCreateMutation = { __typename?: 'Mutation' } & {
  searchHitCreate?: Maybe<
    { __typename?: 'SearchHitCreateMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
    }
  >;
};

export type SearchTopResultsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
}>;

export type SearchTopResultsQuery = { __typename?: 'Query' } & {
  sale?: Maybe<Array<Maybe<{ __typename?: 'SearchTopResultsType' } & TopSearchFragment>>>;
  rent?: Maybe<Array<Maybe<{ __typename?: 'SearchTopResultsType' } & TopSearchFragment>>>;
  region?: Maybe<Array<Maybe<{ __typename?: 'SearchTopResultsType' } & TopSearchFragment>>>;
};

export type TopSearchFragment = { __typename?: 'SearchTopResultsType' } & Pick<
  SearchTopResultsType,
  'kind' | 'stateCode' | 'state' | 'citySlug' | 'city' | 'category'
>;

export type SitemapLocationsQueryVariables = Exact<{ [key: string]: never }>;

export type SitemapLocationsQuery = { __typename?: 'Query' } & {
  locationsSitemap?: Maybe<
    Array<
      Maybe<
        { __typename?: 'LocationSitemapType' } & Pick<
          LocationSitemapType,
          'districtSlug' | 'citySlug' | 'stateSlug' | 'category' | 'finality' | 'updatedAt'
        >
      >
    >
  >;
};

export type SitemapRealtiesQueryVariables = Exact<{
  forPartnership?: Maybe<Scalars['Boolean']>;
  withMedias?: Maybe<Scalars['Boolean']>;
}>;

export type SitemapRealtiesQuery = { __typename?: 'Query' } & {
  realtiesSitemap?: Maybe<
    Array<
      Maybe<
        { __typename?: 'RealtyType' } & Pick<
          RealtyType,
          | 'id'
          | 'updatedAt'
          | 'category'
          | 'isForSale'
          | 'isForRent'
          | 'isForVacation'
          | 'isForExchange'
          | 'salePrice'
          | 'rentPrice'
          | 'condominiumPrice'
          | 'iptuPrice'
          | 'vacationPrice'
          | 'bathrooms'
          | 'suites'
          | 'garages'
          | 'bedrooms'
          | 'commercialDistrict'
          | 'fulltimeSecurity'
          | 'swimmingPool'
          | 'greenArea'
          | 'playground'
          | 'highlighted'
          | 'area'
        > & {
            location: { __typename?: 'LocationType' } & Pick<
              LocationType,
              'id' | 'districtSlug' | 'district' | 'street' | 'postalCode' | 'geopoint'
            > & {
                city: { __typename?: 'CityType' } & Pick<CityType, 'id' | 'slug' | 'name'> & {
                    state: { __typename?: 'StateType' } & Pick<
                      StateType,
                      'id' | 'slug' | 'code' | 'name'
                    > & {
                        country: { __typename?: 'CountryType' } & Pick<
                          CountryType,
                          'code' | 'name'
                        >;
                      };
                  };
              };
            user?: Maybe<
              { __typename?: 'PublicUserType' } & Pick<
                PublicUserType,
                'id' | 'kind' | 'advertisingName' | 'fullName'
              >
            >;
            medias: Array<
              Maybe<{ __typename?: 'RealtyMediaType' } & Pick<RealtyMediaType, 'id' | 'url'>>
            >;
          }
      >
    >
  >;
};

export type SitemapUsersQueryVariables = Exact<{ [key: string]: never }>;

export type SitemapUsersQuery = { __typename?: 'Query' } & {
  usersSitemap?: Maybe<
    Array<
      Maybe<{ __typename?: 'PublicUserType' } & Pick<PublicUserType, 'lastRealtyDate' | 'slug'>>
    >
  >;
};

export type SitemapVenturesQueryVariables = Exact<{ [key: string]: never }>;

export type SitemapVenturesQuery = { __typename?: 'Query' } & {
  venturesSitemap?: Maybe<
    Array<
      Maybe<
        { __typename?: 'VentureType' } & Pick<
          VentureType,
          'id' | 'name' | 'slug' | 'lastRealtyDate'
        >
      >
    >
  >;
};

export type SubscribeNewsletterMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type SubscribeNewsletterMutation = { __typename?: 'Mutation' } & {
  crmNewsletterCreate?: Maybe<
    { __typename?: 'CrmNewsletterCreateMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      crmNewsletter?: Maybe<
        { __typename?: 'CrmNewsletterType' } & Pick<CrmNewsletterType, 'email'>
      >;
    }
  >;
};

export type SupportQueryVariables = Exact<{
  doc: Scalars['String'];
  code: Scalars['String'];
}>;

export type SupportQuery = { __typename?: 'Query' } & {
  userSupport?: Maybe<
    { __typename?: 'UserType' } & Pick<
      UserType,
      | 'id'
      | 'username'
      | 'firstName'
      | 'lastName'
      | 'fullName'
      | 'email'
      | 'phone'
      | 'cellphone'
      | 'street'
      | 'streetNumber'
      | 'district'
      | 'postalCode'
      | 'permissions'
      | 'sendContactEmails'
      | 'sendMessageEmails'
      | 'sendMonthlySummaryEmails'
      | 'kind'
      | 'avatarUrl'
      | 'realtiesCached'
    > & {
        realtiesCounts: { __typename?: 'UserRealtiesCountType' } & Pick<
          UserRealtiesCountType,
          | 'count'
          | 'countPublished'
          | 'countActive'
          | 'countWaitingCuration'
          | 'countAvailable'
          | 'countRejected'
          | 'countAutomaticCuration'
        >;
        city?: Maybe<
          { __typename?: 'CityType' } & Pick<CityType, 'name'> & {
              state: { __typename?: 'StateType' } & Pick<StateType, 'name'>;
            }
        >;
        optimizedAvatarUrls?: Maybe<
          { __typename?: 'UserOptimizedAvatarUrlType' } & Pick<UserOptimizedAvatarUrlType, 'sm'>
        >;
        currentPlan?: Maybe<
          { __typename?: 'UserPlanType' } & Pick<UserPlanType, 'kind' | 'slotsTotal'> & {
              plan?: Maybe<{ __typename?: 'PlanType' } & Pick<PlanType, 'name' | 'quantity'>>;
              salesperson?: Maybe<
                { __typename?: 'UserType' } & Pick<UserType, 'id' | 'fullName' | 'email'>
              >;
            }
        >;
      }
  >;
  realtySupport?: Maybe<
    { __typename?: 'RealtyType' } & Pick<
      RealtyType,
      | 'id'
      | 'status'
      | 'code'
      | 'category'
      | 'isForSale'
      | 'salePrice'
      | 'iptuPrice'
      | 'isForRent'
      | 'isForVacation'
      | 'rentPrice'
      | 'isForExchange'
      | 'vacationPrice'
      | 'condominiumPrice'
      | 'videoUrl'
      | 'streetNumber'
      | 'complement'
      | 'area'
      | 'ventureName'
      | 'bedrooms'
      | 'suites'
      | 'bathrooms'
      | 'garages'
    > & {
        user?: Maybe<{ __typename?: 'PublicUserType' } & Pick<PublicUserType, 'id' | 'fullName'>>;
        location: { __typename?: 'LocationType' } & Pick<
          LocationType,
          'id' | 'postalCode' | 'street' | 'district'
        > & {
            city: { __typename?: 'CityType' } & Pick<CityType, 'id' | 'name'> & {
                state: { __typename?: 'StateType' } & Pick<
                  StateType,
                  'id' | 'code' | 'slug' | 'name'
                >;
              };
          };
        medias: Array<
          Maybe<
            { __typename?: 'RealtyMediaType' } & Pick<
              RealtyMediaType,
              'id' | 'order' | 'url' | 'isMain'
            > & {
                optimizedUrls?: Maybe<
                  { __typename?: 'RealtyMediaOptimizedUrlType' } & Pick<
                    RealtyMediaOptimizedUrlType,
                    'xs'
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type UserActivateMutationVariables = Exact<{
  uid: Scalars['String'];
  token: Scalars['String'];
}>;

export type UserActivateMutation = { __typename?: 'Mutation' } & {
  userActivate?: Maybe<
    { __typename?: 'UserActivateMutationPayload' } & Pick<
      UserActivateMutationPayload,
      'success'
    > & {
        errors?: Maybe<
          Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
        >;
      }
  >;
};

export type UserBySlugQueryVariables = Exact<{
  userSlug: Scalars['String'];
}>;

export type UserBySlugQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'PublicUserType' } & Pick<
      PublicUserType,
      | 'id'
      | 'slug'
      | 'advertisingName'
      | 'phone'
      | 'cellphone'
      | 'hasWhatsapp'
      | 'optimized'
      | 'avatarUrl'
      | 'kind'
      | 'realtiesCached'
      | 'creci'
    > & {
        optimizedAvatarUrls?: Maybe<
          { __typename?: 'UserOptimizedAvatarUrlType' } & Pick<
            UserOptimizedAvatarUrlType,
            'sm' | 'xs'
          >
        >;
      }
  >;
};

export type UserChangePasswordMutationVariables = Exact<{
  oldPassword?: Maybe<Scalars['String']>;
  newPassword: Scalars['String'];
  user: Scalars['ID'];
}>;

export type UserChangePasswordMutation = { __typename?: 'Mutation' } & {
  userChangePassword?: Maybe<
    { __typename?: 'UserChangePasswordMutationPayload' } & Pick<
      UserChangePasswordMutationPayload,
      'success'
    > & {
        errors?: Maybe<
          Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
        >;
      }
  >;
};

export type UserChangePaymentMethodMutationVariables = Exact<{
  input: UserChangePaymentMethodMutationInput;
}>;

export type UserChangePaymentMethodMutation = { __typename?: 'Mutation' } & {
  userChangePaymentMethod?: Maybe<
    { __typename?: 'UserChangePaymentMethodMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
    }
  >;
};

export type UserNewPlanMutationVariables = Exact<{
  input: UserNewPlanMutationInput;
}>;

export type UserNewPlanMutation = { __typename?: 'Mutation' } & {
  userNewPlan?: Maybe<
    { __typename?: 'UserNewPlanMutationPayload' } & Pick<UserNewPlanMutationPayload, 'success'> & {
        errors?: Maybe<
          Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
        >;
      }
  >;
};

export type UserPasswordConfirmMutationVariables = Exact<{
  uid: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
  rePassword: Scalars['String'];
}>;

export type UserPasswordConfirmMutation = { __typename?: 'Mutation' } & {
  userPasswordConfirm?: Maybe<
    { __typename?: 'UserResetPasswordConfirmMutationPayload' } & Pick<
      UserResetPasswordConfirmMutationPayload,
      'success'
    > & {
        errors?: Maybe<
          Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
        >;
      }
  >;
};

export type UserPaymentMethodDeleteMutationVariables = Exact<{
  input: UserPaymentMethodDeleteMutationInput;
}>;

export type UserPaymentMethodDeleteMutation = { __typename?: 'Mutation' } & {
  userPaymentMethodDelete?: Maybe<
    { __typename?: 'UserPaymentMethodDeleteMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
    }
  >;
};

export type UserPlanQueryVariables = Exact<{
  token: Scalars['UUID'];
}>;

export type UserPlanQuery = { __typename?: 'Query' } & {
  userPlan?: Maybe<
    { __typename?: 'UserPlanType' } & Pick<
      UserPlanType,
      | 'id'
      | 'expiresAt'
      | 'kind'
      | 'token'
      | 'emailConfirmed'
      | 'name'
      | 'email'
      | 'userKind'
      | 'promotionKind'
      | 'doc'
      | 'price'
      | 'promotionPrice'
      | 'promotionMonths'
      | 'slotsTotal'
      | 'postalCode'
      | 'district'
      | 'streetNumber'
      | 'complement'
    > & {
        plan?: Maybe<
          { __typename?: 'PlanType' } & Pick<
            PlanType,
            'id' | 'kind' | 'code' | 'name' | 'slug' | 'public' | 'price' | 'quantity'
          > & {
              country?: Maybe<
                { __typename?: 'CountryType' } & Pick<CountryType, 'id' | 'name' | 'code'>
              >;
            }
        >;
        city?: Maybe<
          { __typename?: 'CityType' } & Pick<CityType, 'name'> & {
              state: { __typename?: 'StateType' } & Pick<StateType, 'name' | 'code'>;
            }
        >;
        user?: Maybe<
          { __typename?: 'UserType' } & Pick<
            UserType,
            | 'firstName'
            | 'lastName'
            | 'fullName'
            | 'kind'
            | 'advertisingName'
            | 'phone'
            | 'cellphone'
            | 'id'
            | 'email'
            | 'doc'
          > & {
              city?: Maybe<
                { __typename?: 'CityType' } & Pick<CityType, 'name' | 'slug'> & {
                    state: { __typename?: 'StateType' } & Pick<StateType, 'name' | 'code'>;
                  }
              >;
            }
        >;
      }
  >;
};

export type UserPlanCreateMutationVariables = Exact<{
  input: UserPlanCreateMutationInput;
}>;

export type UserPlanCreateMutation = { __typename?: 'Mutation' } & {
  userPlanCreate?: Maybe<
    { __typename?: 'UserPlanCreateMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      userPlan?: Maybe<
        { __typename?: 'UserPlanType' } & Pick<UserPlanType, 'id' | 'token' | 'createdAt'>
      >;
    }
  >;
};

export type UserPlanCreateUserMutationVariables = Exact<{
  input: UserPlanCreateUserMutationInput;
}>;

export type UserPlanCreateUserMutation = { __typename?: 'Mutation' } & {
  userPlanCreateUser?: Maybe<
    { __typename?: 'UserPlanCreateUserMutationPayload' } & Pick<
      UserPlanCreateUserMutationPayload,
      'success'
    > & {
        errors?: Maybe<
          Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
        >;
        user?: Maybe<{ __typename?: 'UserType' } & Pick<UserType, 'id'>>;
        userPlan?: Maybe<{ __typename?: 'UserPlanType' } & Pick<UserPlanType, 'id' | 'token'>>;
      }
  >;
};

export type UserPlanSetPassswordMutationVariables = Exact<{
  input: UserPlanSetPasswordMutationInput;
}>;

export type UserPlanSetPassswordMutation = { __typename?: 'Mutation' } & {
  userPlanSetPassword?: Maybe<
    { __typename?: 'UserPlanSetPasswordMutationPayload' } & Pick<
      UserPlanSetPasswordMutationPayload,
      'success'
    > & {
        errors?: Maybe<
          Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
        >;
        userPlan?: Maybe<{ __typename?: 'UserPlanType' } & Pick<UserPlanType, 'id' | 'token'>>;
        user?: Maybe<{ __typename?: 'UserType' } & Pick<UserType, 'id'>>;
      }
  >;
};

export type UserPlanUpdateMutationVariables = Exact<{
  input: UserPlanUpdateMutationInput;
}>;

export type UserPlanUpdateMutation = { __typename?: 'Mutation' } & {
  userPlanUpdate?: Maybe<
    { __typename?: 'UserPlanUpdateMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      userPlan?: Maybe<{ __typename?: 'UserPlanType' } & Pick<UserPlanType, 'id' | 'createdAt'>>;
    }
  >;
};

export type UserResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type UserResetPasswordMutation = { __typename?: 'Mutation' } & {
  userResetPassword?: Maybe<
    { __typename?: 'UserResetPasswordMutationPayload' } & Pick<
      UserResetPasswordMutationPayload,
      'success'
    > & {
        errors?: Maybe<
          Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
        >;
      }
  >;
};

export type UserUpdateMutationVariables = Exact<{
  input: UserUpdateMutationInput;
}>;

export type UserUpdateMutation = { __typename?: 'Mutation' } & {
  userUpdate?: Maybe<
    { __typename?: 'UserUpdateMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      user?: Maybe<
        { __typename?: 'UserType' } & Pick<
          UserType,
          | 'id'
          | 'username'
          | 'firstName'
          | 'lastName'
          | 'fullName'
          | 'advertisingName'
          | 'email'
          | 'phone'
          | 'cellphone'
          | 'street'
          | 'streetNumber'
          | 'district'
          | 'postalCode'
          | 'permissions'
          | 'sendContactEmails'
          | 'sendMessageEmails'
          | 'sendMonthlySummaryEmails'
          | 'contactSaleCcEmails'
          | 'contactRentCcEmails'
          | 'allowScheduling'
          | 'complement'
          | 'kind'
        >
      >;
    }
  >;
};

export type UsersQueryVariables = Exact<{ [key: string]: never }>;

export type UsersQuery = { __typename?: 'Query' } & {
  users?: Maybe<
    { __typename?: 'PublicUserTypeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'PublicUserTypeEdge' } & {
            node?: Maybe<
              { __typename?: 'PublicUserType' } & Pick<
                PublicUserType,
                | 'id'
                | 'kind'
                | 'realtiesCached'
                | 'slug'
                | 'advertisingName'
                | 'avatarUrl'
                | 'optimized'
              > & {
                  realtiesCounts: { __typename?: 'UserRealtiesCountType' } & Pick<
                    UserRealtiesCountType,
                    'count'
                  >;
                  optimizedAvatarUrls?: Maybe<
                    { __typename?: 'UserOptimizedAvatarUrlType' } & Pick<
                      UserOptimizedAvatarUrlType,
                      'sm'
                    >
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
};

export type VentureQueryVariables = Exact<{
  id: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  isForSale?: Maybe<Scalars['Boolean']>;
  isForRent?: Maybe<Scalars['Boolean']>;
  isForExchange?: Maybe<Scalars['Boolean']>;
  isForVacation?: Maybe<Scalars['Boolean']>;
  street?: Maybe<Scalars['String']>;
  bedrooms?: Maybe<Scalars['Int']>;
  garages?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
  suites?: Maybe<Scalars['Int']>;
  salePriceMin?: Maybe<Scalars['Float']>;
  salePriceMax?: Maybe<Scalars['Float']>;
  rentPriceMin?: Maybe<Scalars['Float']>;
  rentPriceMax?: Maybe<Scalars['Float']>;
  condominiumPriceMin?: Maybe<Scalars['Float']>;
  condominiumPriceMax?: Maybe<Scalars['Float']>;
  iptuPriceMin?: Maybe<Scalars['Float']>;
  iptuPriceMax?: Maybe<Scalars['Float']>;
  areaMin?: Maybe<Scalars['Float']>;
  areaMax?: Maybe<Scalars['Float']>;
  first?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  geosort?: Maybe<Scalars['GeoPoint']>;
  user?: Maybe<Scalars['ID']>;
  forPartnership?: Maybe<Scalars['Boolean']>;
}>;

export type VentureQuery = { __typename?: 'Query' } & {
  venture?: Maybe<
    { __typename?: 'VentureType' } & Pick<VentureType, 'id' | 'name' | 'slug'> & {
        location?: Maybe<
          { __typename?: 'LocationType' } & Pick<
            LocationType,
            'id' | 'postalCode' | 'street' | 'district'
          > & {
              city: { __typename?: 'CityType' } & Pick<CityType, 'id' | 'name'> & {
                  state: { __typename?: 'StateType' } & Pick<StateType, 'id' | 'code'>;
                };
            }
        >;
        realties?: Maybe<
          { __typename?: 'RealtyTypeConnection' } & Pick<RealtyTypeConnection, 'totalCount'> & {
              pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
              >;
              filters: { __typename?: 'RealtyFiltersType' } & Pick<
                RealtyFiltersType,
                | 'minSalePrice'
                | 'minRentPrice'
                | 'minVacationPrice'
                | 'minCondomoniumPrice'
                | 'maxArea'
                | 'minArea'
                | 'maxBedrooms'
                | 'minBedrooms'
                | 'maxBathrooms'
                | 'minBathrooms'
                | 'maxSuites'
                | 'minSuites'
                | 'maxGarages'
                | 'minGarages'
                | 'fulltimeSecurity'
                | 'playground'
                | 'greenArea'
              >;
              edges: Array<
                Maybe<
                  { __typename?: 'RealtyTypeEdge' } & {
                    node?: Maybe<
                      { __typename?: 'RealtyType' } & Pick<
                        RealtyType,
                        | 'id'
                        | 'ventureName'
                        | 'salePrice'
                        | 'rentPrice'
                        | 'vacationPrice'
                        | 'condominiumPrice'
                        | 'iptuPrice'
                        | 'isForSale'
                        | 'isForRent'
                        | 'isForVacation'
                        | 'isForExchange'
                        | 'allowNegotiation'
                        | 'allowFinancing'
                        | 'category'
                        | 'area'
                        | 'bedrooms'
                        | 'suites'
                        | 'bathrooms'
                        | 'garages'
                        | 'energyEfficiency'
                        | 'distance'
                        | 'commercialDistrict'
                        | 'streetNumber'
                        | 'isCurated'
                      > & {
                          user?: Maybe<
                            { __typename?: 'PublicUserType' } & Pick<PublicUserType, 'id' | 'kind'>
                          >;
                          location: { __typename?: 'LocationType' } & Pick<
                            LocationType,
                            'district' | 'districtSlug' | 'street' | 'id'
                          > & {
                              city: { __typename?: 'CityType' } & Pick<
                                CityType,
                                'id' | 'name' | 'slug'
                              > & {
                                  state: { __typename?: 'StateType' } & Pick<
                                    StateType,
                                    'id' | 'name' | 'code' | 'slug'
                                  >;
                                };
                            };
                          medias: Array<
                            Maybe<
                              { __typename?: 'RealtyMediaType' } & Pick<
                                RealtyMediaType,
                                'url' | 'caption' | 'description' | 'isMain' | 'optimized'
                              > & {
                                  optimizedUrls?: Maybe<
                                    { __typename?: 'RealtyMediaOptimizedUrlType' } & Pick<
                                      RealtyMediaOptimizedUrlType,
                                      'md' | 'sm' | 'xs'
                                    >
                                  >;
                                }
                            >
                          >;
                        }
                    >;
                  }
                >
              >;
            }
        >;
      }
  >;
};

export type ZipcodeSearchQueryVariables = Exact<{
  postalCode: Scalars['String'];
  token?: Maybe<Scalars['String']>;
}>;

export type ZipcodeSearchQuery = { __typename?: 'Query' } & {
  locationByPostalCode?: Maybe<
    { __typename?: 'LocationType' } & Pick<LocationType, 'street' | 'district' | 'id'> & {
        city: { __typename?: 'CityType' } & Pick<CityType, 'id' | 'name' | 'slug'> & {
            state: { __typename?: 'StateType' } & Pick<StateType, 'id' | 'name' | 'code' | 'slug'>;
          };
      }
  >;
};

export const MutErrorFieldsFragmentDoc = gql`
  fragment mutErrorFields on MutationErrorType {
    field
    message
  }
`;
export const PagInfoFieldsFragmentDoc = gql`
  fragment pagInfoFields on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;
export const CityNormalizationFieldsFragmentDoc = gql`
  fragment cityNormalizationFields on CityType {
    id
    name
    state {
      id
      code
    }
  }
`;
export const DistrictNormFieldsFragmentDoc = gql`
  fragment districtNormFields on DistrictNormType {
    id
    norm
    normSlug
    relatedCount
    city {
      ...cityNormalizationFields
    }
  }
  ${CityNormalizationFieldsFragmentDoc}
`;
export const DistrictFieldFragmentDoc = gql`
  fragment districtField on DistrictType {
    id
    city {
      ...cityNormalizationFields
    }
    district
    norm
    isNormalized
    origin
  }
  ${CityNormalizationFieldsFragmentDoc}
`;
export const VentureFieldsFragmentDoc = gql`
  fragment ventureFields on VentureType {
    id
    relatedCount
    name
    location {
      id
      city {
        ...cityNormalizationFields
      }
    }
  }
  ${CityNormalizationFieldsFragmentDoc}
`;
export const VentureNameFieldsFragmentDoc = gql`
  fragment ventureNameFields on VentureNameType {
    id
    name
    norm
    city {
      ...cityNormalizationFields
    }
    venture {
      id
      name
    }
    isNormalized
  }
  ${CityNormalizationFieldsFragmentDoc}
`;
export const RealtyVentureFieldsFragmentDoc = gql`
  fragment realtyVentureFields on VentureType {
    id
    slug
    name
  }
`;
export const RealtyLocationFieldsFragmentDoc = gql`
  fragment realtyLocationFields on LocationType {
    city {
      state {
        name
        code
      }
      name
      slug
      geopoint
    }
    street
    district
    districtSlug
    postalCode
    geopoint
  }
`;
export const RealtyMediaFieldsFragmentDoc = gql`
  fragment realtyMediaFields on RealtyMediaType {
    url
    caption
    description
    isMain
    optimized
    optimizedUrls {
      xl
      lg
      md
      xs
      sm
    }
  }
`;
export const RealtyUserFieldsFragmentDoc = gql`
  fragment realtyUserFields on PublicUserType {
    firstName
    lastName
    fullName
    advertisingName
    slug
    avatarUrl
    id
    realtiesCached
    cellphone
    hasWhatsapp
    kind
    phone
    allowScheduling
    optimized
    creci
    optimizedAvatarUrls {
      xs
      sm
      md
    }
  }
`;
export const RealtyDetailSimilarFieldsFragmentDoc = gql`
  fragment realtyDetailSimilarFields on RealtyType {
    id
    status
    salePrice
    rentPrice
    vacationPrice
    condominiumPrice
    iptuPrice
    isForSale
    isForRent
    isForVacation
    isForExchange
    category
    area
    bedrooms
    suites
    bathrooms
    garages
    isCurated
    location {
      ...realtyLocationFields
    }
    commercialDistrict
    medias {
      ...realtyMediaFields
    }
  }
  ${RealtyLocationFieldsFragmentDoc}
  ${RealtyMediaFieldsFragmentDoc}
`;
export const RealtyDetailFieldsFragmentDoc = gql`
  fragment realtyDetailFields on RealtyType {
    id
    code
    description
    status
    salePrice
    rentPrice
    vacationPrice
    condominiumPrice
    iptuPrice
    isForSale
    isForRent
    isForVacation
    isForExchange
    isCurated
    allowNegotiation
    allowFinancing
    category
    area
    bedrooms
    suites
    bathrooms
    garages
    energyEfficiency
    videoUrl
    tourUrl
    commercialDistrict
    streetNumber
    complement
    reference
    geopoint
    fulltimeSecurity
    swimmingPool
    playground
    greenArea
    originDescription
    rentPeriod
    venture {
      ...realtyVentureFields
    }
    location {
      ...realtyLocationFields
    }
    medias {
      ...realtyMediaFields
    }
    user {
      ...realtyUserFields
    }
    similars(first: 3, isForSale: $isForSale, isForRent: $isForRent) {
      ...realtyDetailSimilarFields
    }
  }
  ${RealtyVentureFieldsFragmentDoc}
  ${RealtyLocationFieldsFragmentDoc}
  ${RealtyMediaFieldsFragmentDoc}
  ${RealtyUserFieldsFragmentDoc}
  ${RealtyDetailSimilarFieldsFragmentDoc}
`;
export const TopSearchFragmentDoc = gql`
  fragment TopSearch on SearchTopResultsType {
    kind
    stateCode
    state
    citySlug
    city
    category
  }
`;
export const CitiesDocument = gql`
  query Cities(
    $orderby: [String] = ["name", "state"]
    $name: String
    $first: Int
    $search: String
  ) {
    cities(orderby: $orderby, name: $name, first: $first, search: $search) {
      totalCount
      edges {
        node {
          id
          name
          state {
            id
            code
          }
        }
      }
    }
  }
`;
export function useCitiesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CitiesQuery, CitiesQueryVariables>
) {
  return ApolloReactHooks.useQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, baseOptions);
}
export function useCitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CitiesQuery, CitiesQueryVariables>(
    CitiesDocument,
    baseOptions
  );
}
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = ApolloReactCommon.QueryResult<CitiesQuery, CitiesQueryVariables>;
export const CitiesForSalesDocument = gql`
  query CitiesForSales {
    citiesForSales {
      state {
        name
      }
      name
      id
      slug
    }
  }
`;
export function useCitiesForSalesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CitiesForSalesQuery, CitiesForSalesQueryVariables>
) {
  return ApolloReactHooks.useQuery<CitiesForSalesQuery, CitiesForSalesQueryVariables>(
    CitiesForSalesDocument,
    baseOptions
  );
}
export function useCitiesForSalesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CitiesForSalesQuery,
    CitiesForSalesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CitiesForSalesQuery, CitiesForSalesQueryVariables>(
    CitiesForSalesDocument,
    baseOptions
  );
}
export type CitiesForSalesQueryHookResult = ReturnType<typeof useCitiesForSalesQuery>;
export type CitiesForSalesLazyQueryHookResult = ReturnType<typeof useCitiesForSalesLazyQuery>;
export type CitiesForSalesQueryResult = ApolloReactCommon.QueryResult<
  CitiesForSalesQuery,
  CitiesForSalesQueryVariables
>;
export const CreateRealtyDocument = gql`
  mutation createRealty($input: RealtyCreateMutationInput!) {
    realtyCreate(input: $input) {
      realty {
        id
        code
        category
        description
        isForSale
        isForRent
        isForExchange
        area
        ventureName
        bedrooms
        suites
        bathrooms
        garages
      }
      errors {
        ...mutErrorFields
      }
    }
  }
  ${MutErrorFieldsFragmentDoc}
`;
export type CreateRealtyMutationFn = ApolloReactCommon.MutationFunction<
  CreateRealtyMutation,
  CreateRealtyMutationVariables
>;
export function useCreateRealtyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateRealtyMutation,
    CreateRealtyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateRealtyMutation, CreateRealtyMutationVariables>(
    CreateRealtyDocument,
    baseOptions
  );
}
export type CreateRealtyMutationHookResult = ReturnType<typeof useCreateRealtyMutation>;
export type CreateRealtyMutationResult = ApolloReactCommon.MutationResult<CreateRealtyMutation>;
export type CreateRealtyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateRealtyMutation,
  CreateRealtyMutationVariables
>;
export const CrmInterestAnnounceCreateDocument = gql`
  mutation CrmInterestAnnounceCreate(
    $name: String!
    $email: String!
    $phone: String
    $doc: String
    $city: String
    $state: String
    $kind: CrmInterestAnnounceKind
  ) {
    crmInterestAnnounceCreate(
      input: {
        name: $name
        email: $email
        phone: $phone
        doc: $doc
        city: $city
        state: $state
        kind: $kind
      }
    ) {
      errors {
        message
        field
      }
      crmInterestAnnounce {
        id
      }
    }
  }
`;
export type CrmInterestAnnounceCreateMutationFn = ApolloReactCommon.MutationFunction<
  CrmInterestAnnounceCreateMutation,
  CrmInterestAnnounceCreateMutationVariables
>;
export function useCrmInterestAnnounceCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CrmInterestAnnounceCreateMutation,
    CrmInterestAnnounceCreateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CrmInterestAnnounceCreateMutation,
    CrmInterestAnnounceCreateMutationVariables
  >(CrmInterestAnnounceCreateDocument, baseOptions);
}
export type CrmInterestAnnounceCreateMutationHookResult = ReturnType<
  typeof useCrmInterestAnnounceCreateMutation
>;
export type CrmInterestAnnounceCreateMutationResult = ApolloReactCommon.MutationResult<CrmInterestAnnounceCreateMutation>;
export type CrmInterestAnnounceCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CrmInterestAnnounceCreateMutation,
  CrmInterestAnnounceCreateMutationVariables
>;
export const DistrictsDocument = gql`
  query Districts($citySlug: String!, $cityStateSlug: String!) {
    searchDistricts(citySlug: $citySlug, stateSlug: $cityStateSlug) {
      id
      district
      districtSlug
    }
  }
`;
export function useDistrictsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DistrictsQuery, DistrictsQueryVariables>
) {
  return ApolloReactHooks.useQuery<DistrictsQuery, DistrictsQueryVariables>(
    DistrictsDocument,
    baseOptions
  );
}
export function useDistrictsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DistrictsQuery, DistrictsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<DistrictsQuery, DistrictsQueryVariables>(
    DistrictsDocument,
    baseOptions
  );
}
export type DistrictsQueryHookResult = ReturnType<typeof useDistrictsQuery>;
export type DistrictsLazyQueryHookResult = ReturnType<typeof useDistrictsLazyQuery>;
export type DistrictsQueryResult = ApolloReactCommon.QueryResult<
  DistrictsQuery,
  DistrictsQueryVariables
>;
export const HomePartnershipsDocument = gql`
  query HomePartnerships($geo: GeoPoint) {
    realties(geosort: $geo, first: 6, forPartnership: true, orderby: "distance") {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          ventureName
          salePrice
          rentPrice
          vacationPrice
          condominiumPrice
          iptuPrice
          isForSale
          isForRent
          isForVacation
          isForExchange
          allowNegotiation
          allowFinancing
          category
          area
          bedrooms
          suites
          bathrooms
          garages
          energyEfficiency
          distance
          user {
            id
            kind
          }
          location {
            city {
              name
              slug
              state {
                name
                code
                slug
              }
            }
            district
            districtSlug
            street
            id
          }
          commercialDistrict
          streetNumber
          medias {
            url
            caption
            description
            isMain
            optimizedUrls {
              md
              sm
              xs
            }
            optimized
          }
          isCurated
        }
      }
    }
  }
`;
export function useHomePartnershipsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    HomePartnershipsQuery,
    HomePartnershipsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<HomePartnershipsQuery, HomePartnershipsQueryVariables>(
    HomePartnershipsDocument,
    baseOptions
  );
}
export function useHomePartnershipsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HomePartnershipsQuery,
    HomePartnershipsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<HomePartnershipsQuery, HomePartnershipsQueryVariables>(
    HomePartnershipsDocument,
    baseOptions
  );
}
export type HomePartnershipsQueryHookResult = ReturnType<typeof useHomePartnershipsQuery>;
export type HomePartnershipsLazyQueryHookResult = ReturnType<typeof useHomePartnershipsLazyQuery>;
export type HomePartnershipsQueryResult = ApolloReactCommon.QueryResult<
  HomePartnershipsQuery,
  HomePartnershipsQueryVariables
>;
export const IntegrationArchiveDocument = gql`
  mutation integrationArchive($id: ID!) {
    integrationArchive(input: { id: $id }) {
      errors {
        field
        message
      }
      integration {
        id
        isArchived
        archivedAt
      }
    }
  }
`;
export type IntegrationArchiveMutationFn = ApolloReactCommon.MutationFunction<
  IntegrationArchiveMutation,
  IntegrationArchiveMutationVariables
>;
export function useIntegrationArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IntegrationArchiveMutation,
    IntegrationArchiveMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IntegrationArchiveMutation,
    IntegrationArchiveMutationVariables
  >(IntegrationArchiveDocument, baseOptions);
}
export type IntegrationArchiveMutationHookResult = ReturnType<typeof useIntegrationArchiveMutation>;
export type IntegrationArchiveMutationResult = ApolloReactCommon.MutationResult<IntegrationArchiveMutation>;
export type IntegrationArchiveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  IntegrationArchiveMutation,
  IntegrationArchiveMutationVariables
>;
export const IntegrationCreateDocument = gql`
  mutation integrationCreate($input: IntegrationCreateMutationInput!) {
    integrationCreate(input: $input) {
      errors {
        field
        message
      }
      integration {
        id
        url
        file
        key
        origin
        preferredDistricts
        forSale
        forRent
        priority
        priceMinSale
        priceMaxSale
        priceMinRent
        priceMaxRent
        salePercentage
        rentPercentage
      }
    }
  }
`;
export type IntegrationCreateMutationFn = ApolloReactCommon.MutationFunction<
  IntegrationCreateMutation,
  IntegrationCreateMutationVariables
>;
export function useIntegrationCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IntegrationCreateMutation,
    IntegrationCreateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IntegrationCreateMutation,
    IntegrationCreateMutationVariables
  >(IntegrationCreateDocument, baseOptions);
}
export type IntegrationCreateMutationHookResult = ReturnType<typeof useIntegrationCreateMutation>;
export type IntegrationCreateMutationResult = ApolloReactCommon.MutationResult<IntegrationCreateMutation>;
export type IntegrationCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  IntegrationCreateMutation,
  IntegrationCreateMutationVariables
>;
export const IntegrationListDocument = gql`
  query integrationList {
    integrations {
      edges {
        node {
          id
          url
          name
          key
          preferredDistricts
          data
          processedAt
          createdAt
          isArchived
          origin
          forSale
          forRent
          priceMinSale
          priceMaxSale
          priceMinRent
          priceMaxRent
          rentPercentage
          salePercentage
          user {
            id
            realtiesCounts {
              count
              countPublished
              countActive
              countWaitingCuration
              countAvailable
              countRejected
              countAutomaticCuration
            }
          }
        }
      }
    }
  }
`;
export function useIntegrationListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IntegrationListQuery,
    IntegrationListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<IntegrationListQuery, IntegrationListQueryVariables>(
    IntegrationListDocument,
    baseOptions
  );
}
export function useIntegrationListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IntegrationListQuery,
    IntegrationListQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<IntegrationListQuery, IntegrationListQueryVariables>(
    IntegrationListDocument,
    baseOptions
  );
}
export type IntegrationListQueryHookResult = ReturnType<typeof useIntegrationListQuery>;
export type IntegrationListLazyQueryHookResult = ReturnType<typeof useIntegrationListLazyQuery>;
export type IntegrationListQueryResult = ApolloReactCommon.QueryResult<
  IntegrationListQuery,
  IntegrationListQueryVariables
>;
export const IntegrationOriginsDocument = gql`
  query integrationOrigins {
    integrationOrigins {
      name
      origin
      kind
      key
      url
    }
  }
`;
export function useIntegrationOriginsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IntegrationOriginsQuery,
    IntegrationOriginsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<IntegrationOriginsQuery, IntegrationOriginsQueryVariables>(
    IntegrationOriginsDocument,
    baseOptions
  );
}
export function useIntegrationOriginsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IntegrationOriginsQuery,
    IntegrationOriginsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<IntegrationOriginsQuery, IntegrationOriginsQueryVariables>(
    IntegrationOriginsDocument,
    baseOptions
  );
}
export type IntegrationOriginsQueryHookResult = ReturnType<typeof useIntegrationOriginsQuery>;
export type IntegrationOriginsLazyQueryHookResult = ReturnType<
  typeof useIntegrationOriginsLazyQuery
>;
export type IntegrationOriginsQueryResult = ApolloReactCommon.QueryResult<
  IntegrationOriginsQuery,
  IntegrationOriginsQueryVariables
>;
export const IntegrationUpdateDocument = gql`
  mutation integrationUpdate($input: IntegrationUpdateMutationInput!) {
    integrationUpdate(input: $input) {
      errors {
        field
        message
      }
      integration {
        id
        url
        file
        key
        origin
        preferredDistricts
        forSale
        forRent
        salePercentage
        rentPercentage
      }
    }
  }
`;
export const IntegrationUpdateDescriptionDocument = gql`
  mutation integrationUpdateDescription($input: IntegrationUpdateDescriptionMutationInput!) {
    integrationUpdateDescription(input: $input) {
      errors {
        field
        message
      }
      integration {
        id
        url
        file
        key
        origin
        preferredDistricts
        forSale
        forRent
        salePercentage
        rentPercentage
      }
    }
  }
`;
export type IntegrationUpdateMutationFn = ApolloReactCommon.MutationFunction<
  IntegrationUpdateMutation,
  IntegrationUpdateMutationVariables
>;
export function useIntegrationUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IntegrationUpdateMutation,
    IntegrationUpdateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IntegrationUpdateMutation,
    IntegrationUpdateMutationVariables
  >(IntegrationUpdateDocument, baseOptions);
}
export type IntegrationUpdateMutationHookResult = ReturnType<typeof useIntegrationUpdateMutation>;
export type IntegrationUpdateMutationResult = ApolloReactCommon.MutationResult<IntegrationUpdateMutation>;
export type IntegrationUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  IntegrationUpdateMutation,
  IntegrationUpdateMutationVariables
>;

export function useIntegrationUpdateDescriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IntegrationUpdateDescriptionMutation,
    IntegrationUpdateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IntegrationUpdateDescriptionMutation,
    IntegrationUpdateMutationVariables
  >(IntegrationUpdateDescriptionDocument, baseOptions);
}
export type IntegrationUpdateDescriptionMutationHookResult = ReturnType<
  typeof useIntegrationUpdateDescriptionMutation
>;
export type IntegrationUpdateDescriptionMutationResult = ApolloReactCommon.MutationResult<IntegrationUpdateDescriptionMutation>;
export type IntegrationUpdateDescriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  IntegrationUpdateDescriptionMutation,
  IntegrationUpdateMutationVariables
>;
export const LocationByPostalCodeDocument = gql`
  query LocationByPostalCode($postalCode: String!, $token: String) {
    locationByPostalCode(postalCode: $postalCode, token: $token) {
      city {
        id
        state {
          code
          name
          id
        }
        name
      }
      street
      district
      id
    }
  }
`;
export function useLocationByPostalCodeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LocationByPostalCodeQuery,
    LocationByPostalCodeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<LocationByPostalCodeQuery, LocationByPostalCodeQueryVariables>(
    LocationByPostalCodeDocument,
    baseOptions
  );
}
export function useLocationByPostalCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationByPostalCodeQuery,
    LocationByPostalCodeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LocationByPostalCodeQuery,
    LocationByPostalCodeQueryVariables
  >(LocationByPostalCodeDocument, baseOptions);
}
export type LocationByPostalCodeQueryHookResult = ReturnType<typeof useLocationByPostalCodeQuery>;
export type LocationByPostalCodeLazyQueryHookResult = ReturnType<
  typeof useLocationByPostalCodeLazyQuery
>;
export type LocationByPostalCodeQueryResult = ApolloReactCommon.QueryResult<
  LocationByPostalCodeQuery,
  LocationByPostalCodeQueryVariables
>;
export const LocationsKeywordsDocument = gql`
  query LocationsKeywords {
    locationsKeywords {
      category
      city
      district
      quantity
      citySlug
      districtSlug
      stateSlug
      finality
    }
  }
`;
export function useLocationsKeywordsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LocationsKeywordsQuery,
    LocationsKeywordsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<LocationsKeywordsQuery, LocationsKeywordsQueryVariables>(
    LocationsKeywordsDocument,
    baseOptions
  );
}
export function useLocationsKeywordsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationsKeywordsQuery,
    LocationsKeywordsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<LocationsKeywordsQuery, LocationsKeywordsQueryVariables>(
    LocationsKeywordsDocument,
    baseOptions
  );
}
export type LocationsKeywordsQueryHookResult = ReturnType<typeof useLocationsKeywordsQuery>;
export type LocationsKeywordsLazyQueryHookResult = ReturnType<typeof useLocationsKeywordsLazyQuery>;
export type LocationsKeywordsQueryResult = ApolloReactCommon.QueryResult<
  LocationsKeywordsQuery,
  LocationsKeywordsQueryVariables
>;
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    userLogin(input: { email: $email, password: $password }) {
      errors {
        ...mutErrorFields
      }
      success
      token
    }
  }
  ${MutErrorFieldsFragmentDoc}
`;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>
) {
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    baseOptions
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      id
      username
      firstName
      lastName
      fullName
      advertisingName
      email
      phone
      cellphone
      street
      streetNumber
      district
      postalCode
      permissions
      sendContactEmails
      sendMessageEmails
      sendMonthlySummaryEmails
      contactSaleCcEmails
      contactRentCcEmails
      allowScheduling
      complement
      kind
      realtiesCounts {
        count
        countPublished
        countActive
        countWaitingCuration
        countAvailable
        countRejected
        countAutomaticCuration
      }
      hash
      currentPlan {
        id
        status
        name
        email
        expiresAt
        kind
        suspended
        canPublish
        token
        slotsTotal
        plan {
          id
          kind
          price
          quantity
        }
      }
      city {
        state {
          name
        }
        name
      }
      avatarUrl
      optimizedAvatarUrls {
        sm
      }
    }
  }
`;
export function useMeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
}
export function useMeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const MyPaymentMethodsDocument = gql`
  query myPaymentMethods {
    myPaymentMethods {
      id
      kind
      description
      holderName
      displayNumber
      brand
      year
      month
    }
  }
`;
export function useMyPaymentMethodsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MyPaymentMethodsQuery,
    MyPaymentMethodsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<MyPaymentMethodsQuery, MyPaymentMethodsQueryVariables>(
    MyPaymentMethodsDocument,
    baseOptions
  );
}
export function useMyPaymentMethodsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MyPaymentMethodsQuery,
    MyPaymentMethodsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<MyPaymentMethodsQuery, MyPaymentMethodsQueryVariables>(
    MyPaymentMethodsDocument,
    baseOptions
  );
}
export type MyPaymentMethodsQueryHookResult = ReturnType<typeof useMyPaymentMethodsQuery>;
export type MyPaymentMethodsLazyQueryHookResult = ReturnType<typeof useMyPaymentMethodsLazyQuery>;
export type MyPaymentMethodsQueryResult = ApolloReactCommon.QueryResult<
  MyPaymentMethodsQuery,
  MyPaymentMethodsQueryVariables
>;
export const MyRealtiesDocument = gql`
  query myRealties(
    $userId: ID
    $status: String
    $statusIn: [String]
    $published: Boolean
    $after: String
    $first: Int
    $searchText: String
    $orderBy: [String]
  ) {
    myRealties(
      user: $userId
      status: $status
      status_In: $statusIn
      published: $published
      first: $first
      after: $after
      search: $searchText
      orderby: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          id
          status
          code
          codeInternal
          category
          isForSale
          salePrice
          iptuPrice
          isForRent
          isForVacation
          rentPrice
          isForExchange
          vacationPrice
          condominiumPrice
          videoUrl
          createdAt
          updatedAt
          hitsCount
          contactsCount
          streetNumber
          complement
          area
          ventureName
          bedrooms
          suites
          bathrooms
          garages
          contactsCount
          phonesCount
          whatsappsCount
          mapsCount
          sharesCount
          schedulesCount
          totalsCount
          hitsCount
          portalId
          rentPeriod
          integration {
            id
          }
          inactiveReason {
            msg
            problem
            automatic
          }
          location {
            id
            postalCode
            street
            district
            city {
              id
              name
              state {
                id
                slug
                name
              }
            }
          }
          medias {
            id
            order
            url
            isMain
            optimizedUrls {
              xs
            }
          }
        }
      }
    }
  }
`;
export function useMyRealtiesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MyRealtiesQuery, MyRealtiesQueryVariables>
) {
  return ApolloReactHooks.useQuery<MyRealtiesQuery, MyRealtiesQueryVariables>(
    MyRealtiesDocument,
    baseOptions
  );
}
export function useMyRealtiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyRealtiesQuery, MyRealtiesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<MyRealtiesQuery, MyRealtiesQueryVariables>(
    MyRealtiesDocument,
    baseOptions
  );
}
export type MyRealtiesQueryHookResult = ReturnType<typeof useMyRealtiesQuery>;
export type MyRealtiesLazyQueryHookResult = ReturnType<typeof useMyRealtiesLazyQuery>;
export type MyRealtiesQueryResult = ApolloReactCommon.QueryResult<
  MyRealtiesQuery,
  MyRealtiesQueryVariables
>;
export const DistrictNormalizationListDocument = gql`
  query DistrictNormalizationList(
    $first: Int
    $after: String
    $orderby: [String]
    $search: String
    $isNormalized: Boolean
    $cities: [String]
  ) {
    districts(
      first: $first
      after: $after
      orderby: $orderby
      search: $search
      isNormalized: $isNormalized
      city_Name_In: $cities
    ) {
      totalCount
      pageInfo {
        ...pagInfoFields
      }
      edges {
        node {
          ...districtField
        }
      }
    }
  }
  ${PagInfoFieldsFragmentDoc}
  ${DistrictFieldFragmentDoc}
`;
export function useDistrictNormalizationListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DistrictNormalizationListQuery,
    DistrictNormalizationListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    DistrictNormalizationListQuery,
    DistrictNormalizationListQueryVariables
  >(DistrictNormalizationListDocument, baseOptions);
}
export function useDistrictNormalizationListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DistrictNormalizationListQuery,
    DistrictNormalizationListQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    DistrictNormalizationListQuery,
    DistrictNormalizationListQueryVariables
  >(DistrictNormalizationListDocument, baseOptions);
}
export type DistrictNormalizationListQueryHookResult = ReturnType<
  typeof useDistrictNormalizationListQuery
>;
export type DistrictNormalizationListLazyQueryHookResult = ReturnType<
  typeof useDistrictNormalizationListLazyQuery
>;
export type DistrictNormalizationListQueryResult = ApolloReactCommon.QueryResult<
  DistrictNormalizationListQuery,
  DistrictNormalizationListQueryVariables
>;
export const VentureNormalizationListDocument = gql`
  query VentureNormalizationList(
    $first: Int
    $after: String
    $orderby: [String]
    $search: String
    $isNormalized: Boolean
    $cities: [String]
  ) {
    ventureNames(
      first: $first
      after: $after
      orderby: $orderby
      search: $search
      isNormalized: $isNormalized
      city_Name_In: $cities
    ) {
      totalCount
      pageInfo {
        ...pagInfoFields
      }
      edges {
        node {
          ...ventureNameFields
        }
      }
    }
  }
  ${PagInfoFieldsFragmentDoc}
  ${VentureNameFieldsFragmentDoc}
`;
export function useVentureNormalizationListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    VentureNormalizationListQuery,
    VentureNormalizationListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    VentureNormalizationListQuery,
    VentureNormalizationListQueryVariables
  >(VentureNormalizationListDocument, baseOptions);
}
export function useVentureNormalizationListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VentureNormalizationListQuery,
    VentureNormalizationListQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    VentureNormalizationListQuery,
    VentureNormalizationListQueryVariables
  >(VentureNormalizationListDocument, baseOptions);
}
export type VentureNormalizationListQueryHookResult = ReturnType<
  typeof useVentureNormalizationListQuery
>;
export type VentureNormalizationListLazyQueryHookResult = ReturnType<
  typeof useVentureNormalizationListLazyQuery
>;
export type VentureNormalizationListQueryResult = ApolloReactCommon.QueryResult<
  VentureNormalizationListQuery,
  VentureNormalizationListQueryVariables
>;
export const DistrictTermsDocument = gql`
  query DistrictTerms(
    $first: Int
    $after: String
    $orderby: [String]
    $search: String
    $isNormalized: Boolean
    $cities: [String]
  ) {
    districtNorms(
      first: $first
      after: $after
      orderby: $orderby
      search: $search
      isNormalized: $isNormalized
      city_Name_In: $cities
    ) {
      totalCount
      pageInfo {
        ...pagInfoFields
      }
      edges {
        node {
          ...districtNormFields
        }
      }
    }
  }
  ${PagInfoFieldsFragmentDoc}
  ${DistrictNormFieldsFragmentDoc}
`;
export function useDistrictTermsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DistrictTermsQuery, DistrictTermsQueryVariables>
) {
  return ApolloReactHooks.useQuery<DistrictTermsQuery, DistrictTermsQueryVariables>(
    DistrictTermsDocument,
    baseOptions
  );
}
export function useDistrictTermsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DistrictTermsQuery,
    DistrictTermsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<DistrictTermsQuery, DistrictTermsQueryVariables>(
    DistrictTermsDocument,
    baseOptions
  );
}
export type DistrictTermsQueryHookResult = ReturnType<typeof useDistrictTermsQuery>;
export type DistrictTermsLazyQueryHookResult = ReturnType<typeof useDistrictTermsLazyQuery>;
export type DistrictTermsQueryResult = ApolloReactCommon.QueryResult<
  DistrictTermsQuery,
  DistrictTermsQueryVariables
>;
export const VentureTermsDocument = gql`
  query VentureTerms(
    $first: Int
    $after: String
    $orderby: [String]
    $search: String
    $isNormalized: Boolean
    $cities: [String]
  ) {
    ventures(
      first: $first
      after: $after
      orderby: $orderby
      search: $search
      isNormalized: $isNormalized
      location_City_Name_In: $cities
    ) {
      totalCount
      pageInfo {
        ...pagInfoFields
      }
      edges {
        node {
          ...ventureFields
        }
      }
    }
  }
  ${PagInfoFieldsFragmentDoc}
  ${VentureFieldsFragmentDoc}
`;
export function useVentureTermsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<VentureTermsQuery, VentureTermsQueryVariables>
) {
  return ApolloReactHooks.useQuery<VentureTermsQuery, VentureTermsQueryVariables>(
    VentureTermsDocument,
    baseOptions
  );
}
export function useVentureTermsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VentureTermsQuery, VentureTermsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<VentureTermsQuery, VentureTermsQueryVariables>(
    VentureTermsDocument,
    baseOptions
  );
}
export type VentureTermsQueryHookResult = ReturnType<typeof useVentureTermsQuery>;
export type VentureTermsLazyQueryHookResult = ReturnType<typeof useVentureTermsLazyQuery>;
export type VentureTermsQueryResult = ApolloReactCommon.QueryResult<
  VentureTermsQuery,
  VentureTermsQueryVariables
>;
export const DistrictBatchUpdateDocument = gql`
  mutation DistrictBatchUpdate($ids: [ID]!, $updates: [BatchUpdateInput]!) {
    districtBatchUpdate(input: { ids: $ids, updates: $updates }) {
      errors {
        ...mutErrorFields
      }
      district {
        ...districtField
      }
    }
  }
  ${MutErrorFieldsFragmentDoc}
  ${DistrictFieldFragmentDoc}
`;
export type DistrictBatchUpdateMutationFn = ApolloReactCommon.MutationFunction<
  DistrictBatchUpdateMutation,
  DistrictBatchUpdateMutationVariables
>;
export function useDistrictBatchUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DistrictBatchUpdateMutation,
    DistrictBatchUpdateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DistrictBatchUpdateMutation,
    DistrictBatchUpdateMutationVariables
  >(DistrictBatchUpdateDocument, baseOptions);
}
export type DistrictBatchUpdateMutationHookResult = ReturnType<
  typeof useDistrictBatchUpdateMutation
>;
export type DistrictBatchUpdateMutationResult = ApolloReactCommon.MutationResult<DistrictBatchUpdateMutation>;
export type DistrictBatchUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DistrictBatchUpdateMutation,
  DistrictBatchUpdateMutationVariables
>;
export const VentureNameBatchUpdateDocument = gql`
  mutation VentureNameBatchUpdate($ids: [ID]!, $updates: [BatchUpdateInput]!) {
    ventureNameBatchUpdate(input: { ids: $ids, updates: $updates }) {
      errors {
        ...mutErrorFields
      }
      ventureName {
        ...ventureNameFields
      }
    }
  }
  ${MutErrorFieldsFragmentDoc}
  ${VentureNameFieldsFragmentDoc}
`;
export type VentureNameBatchUpdateMutationFn = ApolloReactCommon.MutationFunction<
  VentureNameBatchUpdateMutation,
  VentureNameBatchUpdateMutationVariables
>;
export function useVentureNameBatchUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    VentureNameBatchUpdateMutation,
    VentureNameBatchUpdateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    VentureNameBatchUpdateMutation,
    VentureNameBatchUpdateMutationVariables
  >(VentureNameBatchUpdateDocument, baseOptions);
}
export type VentureNameBatchUpdateMutationHookResult = ReturnType<
  typeof useVentureNameBatchUpdateMutation
>;
export type VentureNameBatchUpdateMutationResult = ApolloReactCommon.MutationResult<VentureNameBatchUpdateMutation>;
export type VentureNameBatchUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  VentureNameBatchUpdateMutation,
  VentureNameBatchUpdateMutationVariables
>;
export const DistrictNormBatchDeleteDocument = gql`
  mutation DistrictNormBatchDelete($ids: [ID]!) {
    districtNormBatchDelete(input: { ids: $ids }) {
      errors {
        ...mutErrorFields
      }
      districtNorm {
        ...districtNormFields
      }
    }
  }
  ${MutErrorFieldsFragmentDoc}
  ${DistrictNormFieldsFragmentDoc}
`;
export type DistrictNormBatchDeleteMutationFn = ApolloReactCommon.MutationFunction<
  DistrictNormBatchDeleteMutation,
  DistrictNormBatchDeleteMutationVariables
>;
export function useDistrictNormBatchDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DistrictNormBatchDeleteMutation,
    DistrictNormBatchDeleteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DistrictNormBatchDeleteMutation,
    DistrictNormBatchDeleteMutationVariables
  >(DistrictNormBatchDeleteDocument, baseOptions);
}
export type DistrictNormBatchDeleteMutationHookResult = ReturnType<
  typeof useDistrictNormBatchDeleteMutation
>;
export type DistrictNormBatchDeleteMutationResult = ApolloReactCommon.MutationResult<DistrictNormBatchDeleteMutation>;
export type DistrictNormBatchDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DistrictNormBatchDeleteMutation,
  DistrictNormBatchDeleteMutationVariables
>;
export const VentureBatchDeleteDocument = gql`
  mutation VentureBatchDelete($ids: [ID]!) {
    ventureBatchDelete(input: { ids: $ids }) {
      errors {
        ...mutErrorFields
      }
      venture {
        ...ventureFields
      }
    }
  }
  ${MutErrorFieldsFragmentDoc}
  ${VentureFieldsFragmentDoc}
`;
export type VentureBatchDeleteMutationFn = ApolloReactCommon.MutationFunction<
  VentureBatchDeleteMutation,
  VentureBatchDeleteMutationVariables
>;
export function useVentureBatchDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    VentureBatchDeleteMutation,
    VentureBatchDeleteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    VentureBatchDeleteMutation,
    VentureBatchDeleteMutationVariables
  >(VentureBatchDeleteDocument, baseOptions);
}
export type VentureBatchDeleteMutationHookResult = ReturnType<typeof useVentureBatchDeleteMutation>;
export type VentureBatchDeleteMutationResult = ApolloReactCommon.MutationResult<VentureBatchDeleteMutation>;
export type VentureBatchDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  VentureBatchDeleteMutation,
  VentureBatchDeleteMutationVariables
>;
export const DistrictNormCreateDocument = gql`
  mutation DistrictNormCreate($norm: String!) {
    districtNormCreate(input: { norm: $norm }) {
      errors {
        ...mutErrorFields
      }
      districtNorm {
        ...districtNormFields
      }
    }
  }
  ${MutErrorFieldsFragmentDoc}
  ${DistrictNormFieldsFragmentDoc}
`;
export type DistrictNormCreateMutationFn = ApolloReactCommon.MutationFunction<
  DistrictNormCreateMutation,
  DistrictNormCreateMutationVariables
>;
export function useDistrictNormCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DistrictNormCreateMutation,
    DistrictNormCreateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DistrictNormCreateMutation,
    DistrictNormCreateMutationVariables
  >(DistrictNormCreateDocument, baseOptions);
}
export type DistrictNormCreateMutationHookResult = ReturnType<typeof useDistrictNormCreateMutation>;
export type DistrictNormCreateMutationResult = ApolloReactCommon.MutationResult<DistrictNormCreateMutation>;
export type DistrictNormCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DistrictNormCreateMutation,
  DistrictNormCreateMutationVariables
>;
export const DistrictNormDeleteDocument = gql`
  mutation DistrictNormDelete($id: ID!) {
    districtNormDelete(input: { id: $id }) {
      errors {
        ...mutErrorFields
      }
      districtNorm {
        ...districtNormFields
      }
    }
  }
  ${MutErrorFieldsFragmentDoc}
  ${DistrictNormFieldsFragmentDoc}
`;
export type DistrictNormDeleteMutationFn = ApolloReactCommon.MutationFunction<
  DistrictNormDeleteMutation,
  DistrictNormDeleteMutationVariables
>;
export function useDistrictNormDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DistrictNormDeleteMutation,
    DistrictNormDeleteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DistrictNormDeleteMutation,
    DistrictNormDeleteMutationVariables
  >(DistrictNormDeleteDocument, baseOptions);
}
export type DistrictNormDeleteMutationHookResult = ReturnType<typeof useDistrictNormDeleteMutation>;
export type DistrictNormDeleteMutationResult = ApolloReactCommon.MutationResult<DistrictNormDeleteMutation>;
export type DistrictNormDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DistrictNormDeleteMutation,
  DistrictNormDeleteMutationVariables
>;
export const VentureDeleteDocument = gql`
  mutation VentureDelete($id: ID!) {
    ventureDelete(input: { id: $id }) {
      errors {
        ...mutErrorFields
      }
      venture {
        ...ventureFields
      }
    }
  }
  ${MutErrorFieldsFragmentDoc}
  ${VentureFieldsFragmentDoc}
`;
export type VentureDeleteMutationFn = ApolloReactCommon.MutationFunction<
  VentureDeleteMutation,
  VentureDeleteMutationVariables
>;
export function useVentureDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    VentureDeleteMutation,
    VentureDeleteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<VentureDeleteMutation, VentureDeleteMutationVariables>(
    VentureDeleteDocument,
    baseOptions
  );
}
export type VentureDeleteMutationHookResult = ReturnType<typeof useVentureDeleteMutation>;
export type VentureDeleteMutationResult = ApolloReactCommon.MutationResult<VentureDeleteMutation>;
export type VentureDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  VentureDeleteMutation,
  VentureDeleteMutationVariables
>;
export const DistrictNormUpdateDocument = gql`
  mutation DistrictNormUpdate($norm: String, $id: ID!) {
    districtNormUpdate(input: { norm: $norm, id: $id }) {
      errors {
        ...mutErrorFields
      }
      districtNorm {
        ...districtNormFields
      }
    }
  }
  ${MutErrorFieldsFragmentDoc}
  ${DistrictNormFieldsFragmentDoc}
`;
export type DistrictNormUpdateMutationFn = ApolloReactCommon.MutationFunction<
  DistrictNormUpdateMutation,
  DistrictNormUpdateMutationVariables
>;
export function useDistrictNormUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DistrictNormUpdateMutation,
    DistrictNormUpdateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DistrictNormUpdateMutation,
    DistrictNormUpdateMutationVariables
  >(DistrictNormUpdateDocument, baseOptions);
}
export type DistrictNormUpdateMutationHookResult = ReturnType<typeof useDistrictNormUpdateMutation>;
export type DistrictNormUpdateMutationResult = ApolloReactCommon.MutationResult<DistrictNormUpdateMutation>;
export type DistrictNormUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DistrictNormUpdateMutation,
  DistrictNormUpdateMutationVariables
>;
export const VentureUpdateDocument = gql`
  mutation VentureUpdate($name: String, $id: ID!) {
    ventureUpdate(input: { name: $name, id: $id }) {
      errors {
        ...mutErrorFields
      }
      venture {
        ...ventureFields
      }
    }
  }
  ${MutErrorFieldsFragmentDoc}
  ${VentureFieldsFragmentDoc}
`;
export type VentureUpdateMutationFn = ApolloReactCommon.MutationFunction<
  VentureUpdateMutation,
  VentureUpdateMutationVariables
>;
export function useVentureUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    VentureUpdateMutation,
    VentureUpdateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<VentureUpdateMutation, VentureUpdateMutationVariables>(
    VentureUpdateDocument,
    baseOptions
  );
}
export type VentureUpdateMutationHookResult = ReturnType<typeof useVentureUpdateMutation>;
export type VentureUpdateMutationResult = ApolloReactCommon.MutationResult<VentureUpdateMutation>;
export type VentureUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  VentureUpdateMutation,
  VentureUpdateMutationVariables
>;
export const PlanBySlugDocument = gql`
  query PlanBySlug($slug: String!) {
    planBySlug(slug: $slug) {
      id
      kind
      code
      name
      price
      description
      quantity
      country {
        id
        name
      }
    }
  }
`;
export function usePlanBySlugQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PlanBySlugQuery, PlanBySlugQueryVariables>
) {
  return ApolloReactHooks.useQuery<PlanBySlugQuery, PlanBySlugQueryVariables>(
    PlanBySlugDocument,
    baseOptions
  );
}
export function usePlanBySlugLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlanBySlugQuery, PlanBySlugQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<PlanBySlugQuery, PlanBySlugQueryVariables>(
    PlanBySlugDocument,
    baseOptions
  );
}
export type PlanBySlugQueryHookResult = ReturnType<typeof usePlanBySlugQuery>;
export type PlanBySlugLazyQueryHookResult = ReturnType<typeof usePlanBySlugLazyQuery>;
export type PlanBySlugQueryResult = ApolloReactCommon.QueryResult<
  PlanBySlugQuery,
  PlanBySlugQueryVariables
>;
export const PlansDocument = gql`
  query plans($public: Boolean = true) {
    plans(public: $public, orderby: "price") {
      edges {
        node {
          id
          kind
          code
          name
          price
          description
          quantity
          country {
            id
            name
          }
        }
      }
    }
  }
`;
export function usePlansQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PlansQuery, PlansQueryVariables>
) {
  return ApolloReactHooks.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, baseOptions);
}
export function usePlansLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, baseOptions);
}
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = ApolloReactCommon.QueryResult<PlansQuery, PlansQueryVariables>;
export const PlansForCityDocument = gql`
  query plansForCity($city: ID!, $kind: String!) {
    plansForCity(city: $city, kind: $kind) {
      id
      kind
      code
      name
      price
      description
      quantity
      country {
        id
        name
      }
      promotionPrice
    }
  }
`;
export function usePlansForCityQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PlansForCityQuery, PlansForCityQueryVariables>
) {
  return ApolloReactHooks.useQuery<PlansForCityQuery, PlansForCityQueryVariables>(
    PlansForCityDocument,
    baseOptions
  );
}
export function usePlansForCityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlansForCityQuery, PlansForCityQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<PlansForCityQuery, PlansForCityQueryVariables>(
    PlansForCityDocument,
    baseOptions
  );
}
export type PlansForCityQueryHookResult = ReturnType<typeof usePlansForCityQuery>;
export type PlansForCityLazyQueryHookResult = ReturnType<typeof usePlansForCityLazyQuery>;
export type PlansForCityQueryResult = ApolloReactCommon.QueryResult<
  PlansForCityQuery,
  PlansForCityQueryVariables
>;
export const RealestateDetailDocument = gql`
  query RealestateDetail($id: ID!) {
    realty(id: $id) {
      id
      code
      description
      status
      salePrice
      rentPrice
      vacationPrice
      condominiumPrice
      iptuPrice
      isForSale
      isForRent
      isForVacation
      isForExchange
      isCurated
      allowNegotiation
      allowFinancing
      category
      area
      bedrooms
      suites
      bathrooms
      garages
      energyEfficiency
      videoUrl
      tourUrl
      venture {
        id
        slug
        name
      }
      location {
        city {
          state {
            name
            code
          }
          name
          geopoint
        }
        street
        district
        postalCode
        geopoint
      }
      commercialDistrict
      streetNumber
      complement
      reference
      geopoint
      fulltimeSecurity
      swimmingPool
      playground
      greenArea
      originDescription
      medias {
        url
        caption
        description
        isMain
        optimized
        optimizedUrls {
          xl
          lg
          md
          xs
          sm
        }
      }
      user {
        firstName
        lastName
        fullName
        advertisingName
        slug
        avatarUrl
        id
        realtiesCached
        cellphone
        hasWhatsapp
        kind
        phone
        allowScheduling
        optimized
        optimizedAvatarUrls {
          xs
          sm
          md
        }
      }
      similars(first: 3) {
        id
        status
        salePrice
        rentPrice
        vacationPrice
        condominiumPrice
        iptuPrice
        isForSale
        isForRent
        isForVacation
        isForExchange
        category
        area
        bedrooms
        suites
        bathrooms
        garages
        isCurated
        location {
          city {
            state {
              name
              slug
            }
            name
            slug
          }
          district
          districtSlug
          street
        }
        commercialDistrict
        medias {
          optimized
          caption
          description
          url
          optimizedUrls {
            md
            sm
            xs
          }
        }
      }
    }
  }
`;
export function useRealestateDetailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RealestateDetailQuery,
    RealestateDetailQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<RealestateDetailQuery, RealestateDetailQueryVariables>(
    RealestateDetailDocument,
    baseOptions
  );
}
export function useRealestateDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RealestateDetailQuery,
    RealestateDetailQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<RealestateDetailQuery, RealestateDetailQueryVariables>(
    RealestateDetailDocument,
    baseOptions
  );
}
export type RealestateDetailQueryHookResult = ReturnType<typeof useRealestateDetailQuery>;
export type RealestateDetailLazyQueryHookResult = ReturnType<typeof useRealestateDetailLazyQuery>;
export type RealestateDetailQueryResult = ApolloReactCommon.QueryResult<
  RealestateDetailQuery,
  RealestateDetailQueryVariables
>;
export const RealestateHitCreateDocument = gql`
  mutation RealestateHitCreate(
    $id: ID!
    $utmSource: String
    $utmMedium: String
    $utmCampaign: String
    $utmContent: String
  ) {
    realtyHitCreate(
      input: {
        realty: $id
        utmSource: $utmSource
        utmMedium: $utmMedium
        utmCampaign: $utmCampaign
        utmContent: $utmContent
      }
    ) {
      errors {
        field
        message
      }
    }
  }
`;
export type RealestateHitCreateMutationFn = ApolloReactCommon.MutationFunction<
  RealestateHitCreateMutation,
  RealestateHitCreateMutationVariables
>;
export function useRealestateHitCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealestateHitCreateMutation,
    RealestateHitCreateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RealestateHitCreateMutation,
    RealestateHitCreateMutationVariables
  >(RealestateHitCreateDocument, baseOptions);
}
export type RealestateHitCreateMutationHookResult = ReturnType<
  typeof useRealestateHitCreateMutation
>;
export type RealestateHitCreateMutationResult = ApolloReactCommon.MutationResult<RealestateHitCreateMutation>;
export type RealestateHitCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RealestateHitCreateMutation,
  RealestateHitCreateMutationVariables
>;
export const RealestateLeadCreateDocument = gql`
  mutation RealestateLeadCreate(
    $name: String
    $email: String
    $phone: String
    $msg: String
    $date: DateTime
    $finality: RealtyLeadFinality!
    $realty: ID!
    $kind: RealtyLeadKind!
    $utmSource: String
    $utmMedium: String
    $utmCampaign: String
    $utmContent: String
  ) {
    realtyLeadCreate(
      input: {
        kind: $kind
        realty: $realty
        utmSource: $utmSource
        utmMedium: $utmMedium
        utmCampaign: $utmCampaign
        utmContent: $utmContent
        name: $name
        email: $email
        msg: $msg
        phone: $phone
        date: $date
        finality: $finality
      }
    ) {
      errors {
        field
        message
      }
      realtyLead {
        id
      }
    }
  }
`;
export type RealestateLeadCreateMutationFn = ApolloReactCommon.MutationFunction<
  RealestateLeadCreateMutation,
  RealestateLeadCreateMutationVariables
>;
export function useRealestateLeadCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealestateLeadCreateMutation,
    RealestateLeadCreateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RealestateLeadCreateMutation,
    RealestateLeadCreateMutationVariables
  >(RealestateLeadCreateDocument, baseOptions);
}
export type RealestateLeadCreateMutationHookResult = ReturnType<
  typeof useRealestateLeadCreateMutation
>;
export type RealestateLeadCreateMutationResult = ApolloReactCommon.MutationResult<RealestateLeadCreateMutation>;
export type RealestateLeadCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RealestateLeadCreateMutation,
  RealestateLeadCreateMutationVariables
>;
export const RealestateListingFiltersDocument = gql`
  query RealestateListingFilters(
    $state: String
    $city: String
    $district: String
    $category: String
    $isForSale: Boolean
    $isForRent: Boolean
    $isForExchange: Boolean
    $isForVacation: Boolean
    $street: String
    $bedrooms: Int
    $garages: Int
    $bathrooms: Int
    $suites: Int
    $salePriceMin: Float
    $salePriceMax: Float
    $rentPriceMin: Float
    $rentPriceMax: Float
    $condominiumPriceMin: Float
    $condominiumPriceMax: Float
    $iptuPriceMin: Float
    $iptuPriceMax: Float
    $areaMin: Float
    $areaMax: Float
    $first: Int
    $cursor: String
    $orderby: [String]
    $geosort: GeoPoint
    $user: ID
  ) {
    realties(
      location_City_State_Slug: $state
      location_City_Slug: $city
      location_DistrictSlug: $district
      category: $category
      isForSale: $isForSale
      isForRent: $isForRent
      isForExchange: $isForExchange
      isForVacation: $isForVacation
      bedrooms_Gte: $bedrooms
      garages_Gte: $garages
      bathrooms_Gte: $bathrooms
      suites_Gte: $suites
      salePrice_Gte: $salePriceMin
      salePrice_Lte: $salePriceMax
      rentPrice_Gte: $rentPriceMin
      rentPrice_Lte: $rentPriceMax
      condominiumPrice_Gte: $condominiumPriceMin
      condominiumPrice_Lte: $condominiumPriceMax
      iptuPrice_Gte: $iptuPriceMin
      iptuPrice_Lte: $iptuPriceMax
      area_Gte: $areaMin
      area_Lte: $areaMax
      first: $first
      after: $cursor
      orderby: $orderby
      geosort: $geosort
      user: $user
      location_Street: $street
    ) {
      filters {
        minSalePrice
        maxSalePrice
        minRentPrice
        maxRentPrice
        minVacationPrice
        maxVacationPrice
        minCondomoniumPrice
        maxCondominiumPrice
        minArea
        maxArea
      }
    }
  }
`;
export function useRealestateListingFiltersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RealestateListingFiltersQuery,
    RealestateListingFiltersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    RealestateListingFiltersQuery,
    RealestateListingFiltersQueryVariables
  >(RealestateListingFiltersDocument, baseOptions);
}
export function useRealestateListingFiltersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RealestateListingFiltersQuery,
    RealestateListingFiltersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    RealestateListingFiltersQuery,
    RealestateListingFiltersQueryVariables
  >(RealestateListingFiltersDocument, baseOptions);
}
export type RealestateListingFiltersQueryHookResult = ReturnType<
  typeof useRealestateListingFiltersQuery
>;
export type RealestateListingFiltersLazyQueryHookResult = ReturnType<
  typeof useRealestateListingFiltersLazyQuery
>;
export type RealestateListingFiltersQueryResult = ApolloReactCommon.QueryResult<
  RealestateListingFiltersQuery,
  RealestateListingFiltersQueryVariables
>;
export const RealestateListingDocument = gql`
  query RealestateListing(
    $state: String
    $city: String
    $district: String
    $districtList: [String]
    $category: String
    $isForSale: Boolean
    $isForRent: Boolean
    $isForExchange: Boolean
    $isForVacation: Boolean
    $street: String
    $bedrooms: Int
    $garages: Int
    $bathrooms: Int
    $suites: Int
    $salePriceMin: Float
    $salePriceMax: Float
    $rentPriceMin: Float
    $rentPriceMax: Float
    $condominiumPriceMin: Float
    $condominiumPriceMax: Float
    $iptuPriceMin: Float
    $iptuPriceMax: Float
    $areaMin: Float
    $areaMax: Float
    $first: Int = 18
    $cursor: String
    $orderby: [String]
    $geosort: GeoPoint
    $user: ID
    $forPartnership: Boolean
  ) {
    realties(
      location_City_State_Code: $state
      location_City_Slug: $city
      location_DistrictSlug: $district
      location_DistrictSlug_In: $districtList
      category: $category
      isForSale: $isForSale
      isForRent: $isForRent
      isForExchange: $isForExchange
      isForVacation: $isForVacation
      bedrooms_Gte: $bedrooms
      garages_Gte: $garages
      bathrooms_Gte: $bathrooms
      suites_Gte: $suites
      salePrice_Gte: $salePriceMin
      salePrice_Lte: $salePriceMax
      rentPrice_Gte: $rentPriceMin
      rentPrice_Lte: $rentPriceMax
      condominiumPrice_Gte: $condominiumPriceMin
      condominiumPrice_Lte: $condominiumPriceMax
      iptuPrice_Gte: $iptuPriceMin
      iptuPrice_Lte: $iptuPriceMax
      area_Gte: $areaMin
      area_Lte: $areaMax
      first: $first
      after: $cursor
      orderby: $orderby
      geosort: $geosort
      user: $user
      location_Street: $street
      forPartnership: $forPartnership
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          ventureName
          salePrice
          rentPrice
          vacationPrice
          condominiumPrice
          iptuPrice
          isForSale
          isForRent
          isForVacation
          isForExchange
          allowNegotiation
          allowFinancing
          category
          area
          bedrooms
          suites
          bathrooms
          garages
          energyEfficiency
          distance
          user {
            id
            kind
          }
          location {
            city {
              name
              slug
              state {
                name
                code
                slug
              }
            }
            district
            districtSlug
            street
            id
          }
          venture {
            id
            name
          }
          commercialDistrict
          streetNumber
          medias(first: 12) {
            url
            caption
            description
            isMain
            optimizedUrls {
              md
              sm
              xs
            }
            optimized
          }
          isCurated
        }
      }
    }
  }
`;
export function useRealestateListingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RealestateListingQuery,
    RealestateListingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<RealestateListingQuery, RealestateListingQueryVariables>(
    RealestateListingDocument,
    baseOptions
  );
}
export function useRealestateListingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RealestateListingQuery,
    RealestateListingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<RealestateListingQuery, RealestateListingQueryVariables>(
    RealestateListingDocument,
    baseOptions
  );
}
export type RealestateListingQueryHookResult = ReturnType<typeof useRealestateListingQuery>;
export type RealestateListingLazyQueryHookResult = ReturnType<typeof useRealestateListingLazyQuery>;
export type RealestateListingQueryResult = ApolloReactCommon.QueryResult<
  RealestateListingQuery,
  RealestateListingQueryVariables
>;
export const RealestateRedirectDocument = gql`
  query RealestateRedirect($id: ID!) {
    realty(id: $id) {
      id
      isForSale
      isForRent
      isForVacation
      isForExchange
      location {
        id
        districtSlug
        city {
          id
          slug
          state {
            id
            code
          }
        }
      }
      category
    }
  }
`;
export function useRealestateRedirectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RealestateRedirectQuery,
    RealestateRedirectQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<RealestateRedirectQuery, RealestateRedirectQueryVariables>(
    RealestateRedirectDocument,
    baseOptions
  );
}
export function useRealestateRedirectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RealestateRedirectQuery,
    RealestateRedirectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<RealestateRedirectQuery, RealestateRedirectQueryVariables>(
    RealestateRedirectDocument,
    baseOptions
  );
}
export type RealestateRedirectQueryHookResult = ReturnType<typeof useRealestateRedirectQuery>;
export type RealestateRedirectLazyQueryHookResult = ReturnType<
  typeof useRealestateRedirectLazyQuery
>;
export type RealestateRedirectQueryResult = ApolloReactCommon.QueryResult<
  RealestateRedirectQuery,
  RealestateRedirectQueryVariables
>;
export const RealtiesPartnershipListDocument = gql`
  query realtiesPartnershipList(
    $state: String
    $city: String
    $district: String
    $category: String
    $isForSale: Boolean
    $isForRent: Boolean
    $isForExchange: Boolean
    $isForVacation: Boolean
    $street: String
    $bedrooms: Int
    $garages: Int
    $bathrooms: Int
    $suites: Int
    $salePriceMin: Float
    $salePriceMax: Float
    $rentPriceMin: Float
    $rentPriceMax: Float
    $condominiumPriceMin: Float
    $condominiumPriceMax: Float
    $iptuPriceMin: Float
    $iptuPriceMax: Float
    $areaMin: Float
    $areaMax: Float
    $first: Int = 18
    $cursor: String
    $orderby: [String]
    $geosort: GeoPoint
    $user: ID
  ) {
    realties: realties(
      location_City_State_Slug: $state
      location_City_Slug: $city
      location_DistrictSlug: $district
      category: $category
      isForSale: $isForSale
      isForRent: $isForRent
      isForExchange: $isForExchange
      isForVacation: $isForVacation
      bedrooms_Gte: $bedrooms
      garages_Gte: $garages
      bathrooms_Gte: $bathrooms
      suites_Gte: $suites
      salePrice_Gte: $salePriceMin
      salePrice_Lte: $salePriceMax
      rentPrice_Gte: $rentPriceMin
      rentPrice_Lte: $rentPriceMax
      condominiumPrice_Gte: $condominiumPriceMin
      condominiumPrice_Lte: $condominiumPriceMax
      iptuPrice_Gte: $iptuPriceMin
      iptuPrice_Lte: $iptuPriceMax
      area_Gte: $areaMin
      area_Lte: $areaMax
      first: $first
      after: $cursor
      orderby: $orderby
      geosort: $geosort
      user: $user
      location_Street: $street
      forPartnership: true
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          id
          status
          code
          category
          isForSale
          salePrice
          iptuPrice
          isForRent
          isForVacation
          rentPrice
          isForExchange
          vacationPrice
          condominiumPrice
          videoUrl
          streetNumber
          complement
          area
          ventureName
          bedrooms
          suites
          bathrooms
          garages
          user {
            firstName
            lastName
            fullName
            advertisingName
            avatarUrl
            id
            cellphone
            hasWhatsapp
            kind
            phone
            optimized
            optimizedAvatarUrls {
              xs
              sm
              md
            }
          }
          location {
            id
            postalCode
            street
            district
            city {
              name
              state {
                slug
                name
              }
            }
          }
          medias(first: 1) {
            id
            order
            url
            isMain
            optimizedUrls {
              xs
            }
          }
        }
      }
    }
  }
`;
export function useRealtiesPartnershipListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RealtiesPartnershipListQuery,
    RealtiesPartnershipListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    RealtiesPartnershipListQuery,
    RealtiesPartnershipListQueryVariables
  >(RealtiesPartnershipListDocument, baseOptions);
}
export function useRealtiesPartnershipListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RealtiesPartnershipListQuery,
    RealtiesPartnershipListQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    RealtiesPartnershipListQuery,
    RealtiesPartnershipListQueryVariables
  >(RealtiesPartnershipListDocument, baseOptions);
}
export type RealtiesPartnershipListQueryHookResult = ReturnType<
  typeof useRealtiesPartnershipListQuery
>;
export type RealtiesPartnershipListLazyQueryHookResult = ReturnType<
  typeof useRealtiesPartnershipListLazyQuery
>;
export type RealtiesPartnershipListQueryResult = ApolloReactCommon.QueryResult<
  RealtiesPartnershipListQuery,
  RealtiesPartnershipListQueryVariables
>;
export const RealtySummaryDocument = gql`
  query realtySummary($start: DateTime!) {
    realtySummary(start: $start) {
      hits {
        hits
        day
      }
      leads {
        leads
        day
      }
      activeRealties
      total
      schedules
      whatsappLeads
      phoneLeads
      contacts
    }
  }
`;
export function useRealtySummaryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<RealtySummaryQuery, RealtySummaryQueryVariables>
) {
  return ApolloReactHooks.useQuery<RealtySummaryQuery, RealtySummaryQueryVariables>(
    RealtySummaryDocument,
    baseOptions
  );
}
export function useRealtySummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RealtySummaryQuery,
    RealtySummaryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<RealtySummaryQuery, RealtySummaryQueryVariables>(
    RealtySummaryDocument,
    baseOptions
  );
}
export type RealtySummaryQueryHookResult = ReturnType<typeof useRealtySummaryQuery>;
export type RealtySummaryLazyQueryHookResult = ReturnType<typeof useRealtySummaryLazyQuery>;
export type RealtySummaryQueryResult = ApolloReactCommon.QueryResult<
  RealtySummaryQuery,
  RealtySummaryQueryVariables
>;
export const RealtyDetailDocument = gql`
  query RealtyDetail($id: ID!, $isForSale: Boolean, $isForRent: Boolean) {
    realty(id: $id) {
      ...realtyDetailFields
    }
  }
  ${RealtyDetailFieldsFragmentDoc}
`;
export function useRealtyDetailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<RealtyDetailQuery, RealtyDetailQueryVariables>
) {
  return ApolloReactHooks.useQuery<RealtyDetailQuery, RealtyDetailQueryVariables>(
    RealtyDetailDocument,
    baseOptions
  );
}
export function useRealtyDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RealtyDetailQuery, RealtyDetailQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<RealtyDetailQuery, RealtyDetailQueryVariables>(
    RealtyDetailDocument,
    baseOptions
  );
}
export type RealtyDetailQueryHookResult = ReturnType<typeof useRealtyDetailQuery>;
export type RealtyDetailLazyQueryHookResult = ReturnType<typeof useRealtyDetailLazyQuery>;
export type RealtyDetailQueryResult = ApolloReactCommon.QueryResult<
  RealtyDetailQuery,
  RealtyDetailQueryVariables
>;
export const RealtiesByProximityDocument = gql`
  query RealtiesByProximity(
    $stateSlug: String
    $citySlug: String
    $districtSlug: String
    $category: String
    $isForSale: Boolean
    $isForRent: Boolean
    $isForExchange: Boolean
    $isForVacation: Boolean
    $first: Int = 6
    $forPartnership: Boolean
  ) {
    realtiesByProximity(
      stateSlug: $stateSlug
      citySlug: $citySlug
      districtSlug: $districtSlug
      category: $category
      isForSale: $isForSale
      isForRent: $isForRent
      isForExchange: $isForExchange
      isForVacation: $isForVacation
      first: $first
      forPartnership: $forPartnership
    ) {
      kind
      realties {
        id
        ventureName
        salePrice
        rentPrice
        vacationPrice
        condominiumPrice
        iptuPrice
        isForSale
        isForRent
        isForVacation
        isForExchange
        allowNegotiation
        allowFinancing
        category
        area
        bedrooms
        suites
        bathrooms
        garages
        energyEfficiency
        distance
        user {
          id
          kind
        }
        location {
          city {
            name
            slug
            state {
              name
              code
              slug
            }
          }
          district
          districtSlug
          street
          id
        }
        commercialDistrict
        streetNumber
        medias {
          url
          caption
          description
          isMain
        }
      }
    }
  }
`;
export function useRealtiesByProximityQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RealtiesByProximityQuery,
    RealtiesByProximityQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<RealtiesByProximityQuery, RealtiesByProximityQueryVariables>(
    RealtiesByProximityDocument,
    baseOptions
  );
}
export function useRealtiesByProximityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RealtiesByProximityQuery,
    RealtiesByProximityQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<RealtiesByProximityQuery, RealtiesByProximityQueryVariables>(
    RealtiesByProximityDocument,
    baseOptions
  );
}
export type RealtiesByProximityQueryHookResult = ReturnType<typeof useRealtiesByProximityQuery>;
export type RealtiesByProximityLazyQueryHookResult = ReturnType<
  typeof useRealtiesByProximityLazyQuery
>;
export type RealtiesByProximityQueryResult = ApolloReactCommon.QueryResult<
  RealtiesByProximityQuery,
  RealtiesByProximityQueryVariables
>;
export const RealtyCurationSkipDocument = gql`
  mutation RealtyCurationSkip($id: ID!) {
    realtyCurationSkip(input: { id: $id }) {
      errors {
        field
        message
      }
      realty {
        id
      }
    }
  }
`;
export type RealtyCurationSkipMutationFn = ApolloReactCommon.MutationFunction<
  RealtyCurationSkipMutation,
  RealtyCurationSkipMutationVariables
>;
export function useRealtyCurationSkipMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealtyCurationSkipMutation,
    RealtyCurationSkipMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RealtyCurationSkipMutation,
    RealtyCurationSkipMutationVariables
  >(RealtyCurationSkipDocument, baseOptions);
}
export type RealtyCurationSkipMutationHookResult = ReturnType<typeof useRealtyCurationSkipMutation>;
export type RealtyCurationSkipMutationResult = ApolloReactCommon.MutationResult<RealtyCurationSkipMutation>;
export type RealtyCurationSkipMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RealtyCurationSkipMutation,
  RealtyCurationSkipMutationVariables
>;
export const RealtyCurationTaskDocument = gql`
  query realtyCurationTask {
    realtyCurationTask {
      realty {
        id
        status
        code
        category
        isForSale
        salePrice
        iptuPrice
        isForRent
        rentPrice
        isForExchange
        vacationPrice
        condominiumPrice
        originDescription
        videoUrl
        streetNumber
        complement
        area
        ventureName
        bedrooms
        suites
        bathrooms
        garages
        user {
          advertisingName
          kind
        }
        location {
          id
          postalCode
          street
          district
          city {
            name
            state {
              name
            }
          }
        }
        medias {
          id
          url
          isMain
          optimizedUrls {
            xl
            md
            sm
            xs
          }
        }
      }
      previous {
        id
        status
        code
        category
        isForSale
        salePrice
        iptuPrice
        isForRent
        rentPrice
        isForExchange
        vacationPrice
        condominiumPrice
        videoUrl
        streetNumber
        complement
        area
        ventureName
        bedrooms
        suites
        bathrooms
        garages
        user {
          advertisingName
          kind
        }
        location {
          id
          postalCode
          street
          district
          city {
            name
          }
        }
      }
      duplicates {
        edges {
          node {
            id
            rank
            status
            code
            category
            isForSale
            salePrice
            iptuPrice
            isForRent
            rentPrice
            isForExchange
            vacationPrice
            condominiumPrice
            videoUrl
            streetNumber
            complement
            area
            ventureName
            bedrooms
            suites
            bathrooms
            garages
            location {
              id
              postalCode
              street
              district
              city {
                name
              }
            }
            medias {
              id
              order
              url
              isMain
              optimizedUrls {
                xs
              }
            }
          }
        }
      }
    }
  }
`;
export function useRealtyCurationTaskQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RealtyCurationTaskQuery,
    RealtyCurationTaskQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<RealtyCurationTaskQuery, RealtyCurationTaskQueryVariables>(
    RealtyCurationTaskDocument,
    baseOptions
  );
}
export function useRealtyCurationTaskLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RealtyCurationTaskQuery,
    RealtyCurationTaskQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<RealtyCurationTaskQuery, RealtyCurationTaskQueryVariables>(
    RealtyCurationTaskDocument,
    baseOptions
  );
}
export type RealtyCurationTaskQueryHookResult = ReturnType<typeof useRealtyCurationTaskQuery>;
export type RealtyCurationTaskLazyQueryHookResult = ReturnType<
  typeof useRealtyCurationTaskLazyQuery
>;
export type RealtyCurationTaskQueryResult = ApolloReactCommon.QueryResult<
  RealtyCurationTaskQuery,
  RealtyCurationTaskQueryVariables
>;
export const RealtyInactivateDocument = gql`
  mutation realtyInactivate($id: ID!) {
    realtyInactivate(input: { id: $id }) {
      realty {
        id
        status
      }
    }
  }
`;
export type RealtyInactivateMutationFn = ApolloReactCommon.MutationFunction<
  RealtyInactivateMutation,
  RealtyInactivateMutationVariables
>;
export function useRealtyInactivateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealtyInactivateMutation,
    RealtyInactivateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<RealtyInactivateMutation, RealtyInactivateMutationVariables>(
    RealtyInactivateDocument,
    baseOptions
  );
}
export type RealtyInactivateMutationHookResult = ReturnType<typeof useRealtyInactivateMutation>;
export type RealtyInactivateMutationResult = ApolloReactCommon.MutationResult<RealtyInactivateMutation>;
export type RealtyInactivateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RealtyInactivateMutation,
  RealtyInactivateMutationVariables
>;
export const RealtyLeadsDocument = gql`
  query realtyLeads($after: String, $first: Int, $kind: String, $orderBy: [String]) {
    realtyLeads(kind: $kind, first: $first, after: $after, orderby: $orderBy) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
          email
          phone
          createdAt
          date
          msg
          finality
          realty {
            id
            code
            category
            isForSale
            salePrice
            iptuPrice
            isForRent
            isForVacation
            rentPrice
            isForExchange
            vacationPrice
            condominiumPrice
            videoUrl
            streetNumber
            complement
            area
            ventureName
            bedrooms
            suites
            bathrooms
            garages
            location {
              id
              postalCode
              street
              district
              city {
                id
                name
                state {
                  id
                  slug
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
export function useRealtyLeadsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<RealtyLeadsQuery, RealtyLeadsQueryVariables>
) {
  return ApolloReactHooks.useQuery<RealtyLeadsQuery, RealtyLeadsQueryVariables>(
    RealtyLeadsDocument,
    baseOptions
  );
}
export function useRealtyLeadsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RealtyLeadsQuery, RealtyLeadsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<RealtyLeadsQuery, RealtyLeadsQueryVariables>(
    RealtyLeadsDocument,
    baseOptions
  );
}
export type RealtyLeadsQueryHookResult = ReturnType<typeof useRealtyLeadsQuery>;
export type RealtyLeadsLazyQueryHookResult = ReturnType<typeof useRealtyLeadsLazyQuery>;
export type RealtyLeadsQueryResult = ApolloReactCommon.QueryResult<
  RealtyLeadsQuery,
  RealtyLeadsQueryVariables
>;
export const RealtyMediaCreateDocument = gql`
  mutation realtyMediaCreate($input: RealtyMediaCreateMutationInput!) {
    realtyMediaCreate(input: $input) {
      errors {
        field
        message
      }
      realtyMedia {
        id
        order
        url
        isMain
      }
    }
  }
`;
export type RealtyMediaCreateMutationFn = ApolloReactCommon.MutationFunction<
  RealtyMediaCreateMutation,
  RealtyMediaCreateMutationVariables
>;
export function useRealtyMediaCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealtyMediaCreateMutation,
    RealtyMediaCreateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RealtyMediaCreateMutation,
    RealtyMediaCreateMutationVariables
  >(RealtyMediaCreateDocument, baseOptions);
}
export type RealtyMediaCreateMutationHookResult = ReturnType<typeof useRealtyMediaCreateMutation>;
export type RealtyMediaCreateMutationResult = ApolloReactCommon.MutationResult<RealtyMediaCreateMutation>;
export type RealtyMediaCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RealtyMediaCreateMutation,
  RealtyMediaCreateMutationVariables
>;
export const RealtyMediaDeleteDocument = gql`
  mutation realtyMediaDelete($media: ID!) {
    realtyMediaDelete(input: { id: $media }) {
      errors {
        field
        message
      }
    }
  }
`;
export type RealtyMediaDeleteMutationFn = ApolloReactCommon.MutationFunction<
  RealtyMediaDeleteMutation,
  RealtyMediaDeleteMutationVariables
>;
export function useRealtyMediaDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealtyMediaDeleteMutation,
    RealtyMediaDeleteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RealtyMediaDeleteMutation,
    RealtyMediaDeleteMutationVariables
  >(RealtyMediaDeleteDocument, baseOptions);
}
export type RealtyMediaDeleteMutationHookResult = ReturnType<typeof useRealtyMediaDeleteMutation>;
export type RealtyMediaDeleteMutationResult = ApolloReactCommon.MutationResult<RealtyMediaDeleteMutation>;
export type RealtyMediaDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RealtyMediaDeleteMutation,
  RealtyMediaDeleteMutationVariables
>;
export const RealtyMediaListDocument = gql`
  query realtyMediaList($realty: ID!) {
    realtyMedias(realty: $realty) {
      edges {
        node {
          id
          order
          url
          isMain
          optimizedUrls {
            xs
            sm
            md
          }
        }
      }
    }
  }
`;
export function useRealtyMediaListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RealtyMediaListQuery,
    RealtyMediaListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<RealtyMediaListQuery, RealtyMediaListQueryVariables>(
    RealtyMediaListDocument,
    baseOptions
  );
}
export function useRealtyMediaListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RealtyMediaListQuery,
    RealtyMediaListQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<RealtyMediaListQuery, RealtyMediaListQueryVariables>(
    RealtyMediaListDocument,
    baseOptions
  );
}
export type RealtyMediaListQueryHookResult = ReturnType<typeof useRealtyMediaListQuery>;
export type RealtyMediaListLazyQueryHookResult = ReturnType<typeof useRealtyMediaListLazyQuery>;
export type RealtyMediaListQueryResult = ApolloReactCommon.QueryResult<
  RealtyMediaListQuery,
  RealtyMediaListQueryVariables
>;
export const RealtyMediaUpdateDocument = gql`
  mutation realtyMediaUpdate($id: ID!, $isMain: Boolean!, $realty: ID) {
    realtyMediaUpdate(input: { id: $id, isMain: $isMain, realty: $realty }) {
      errors {
        field
        message
      }
    }
  }
`;
export type RealtyMediaUpdateMutationFn = ApolloReactCommon.MutationFunction<
  RealtyMediaUpdateMutation,
  RealtyMediaUpdateMutationVariables
>;
export function useRealtyMediaUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealtyMediaUpdateMutation,
    RealtyMediaUpdateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RealtyMediaUpdateMutation,
    RealtyMediaUpdateMutationVariables
  >(RealtyMediaUpdateDocument, baseOptions);
}
export type RealtyMediaUpdateMutationHookResult = ReturnType<typeof useRealtyMediaUpdateMutation>;
export type RealtyMediaUpdateMutationResult = ApolloReactCommon.MutationResult<RealtyMediaUpdateMutation>;
export type RealtyMediaUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RealtyMediaUpdateMutation,
  RealtyMediaUpdateMutationVariables
>;
export const RealtyNearbyDocument = gql`
  query RealtyNearby($first: Int = 10, $geosort: GeoPoint) {
    realties(orderby: "distance", first: $first, geosort: $geosort) {
      totalCount
      edges {
        node {
          id
          ventureName
          salePrice
          rentPrice
          vacationPrice
          condominiumPrice
          iptuPrice
          isForSale
          isForRent
          isForVacation
          isForExchange
          allowNegotiation
          allowFinancing
          category
          area
          bedrooms
          suites
          bathrooms
          garages
          energyEfficiency
          distance
          location {
            city {
              name
              slug
              state {
                name
                code
                slug
              }
            }
            district
            districtSlug
            street
            id
          }
          commercialDistrict
          streetNumber
          medias(first: 1) {
            url
            caption
            description
            isMain
            optimizedUrls {
              md
              sm
              xs
            }
            optimized
          }
        }
      }
    }
  }
`;
export function useRealtyNearbyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<RealtyNearbyQuery, RealtyNearbyQueryVariables>
) {
  return ApolloReactHooks.useQuery<RealtyNearbyQuery, RealtyNearbyQueryVariables>(
    RealtyNearbyDocument,
    baseOptions
  );
}
export function useRealtyNearbyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RealtyNearbyQuery, RealtyNearbyQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<RealtyNearbyQuery, RealtyNearbyQueryVariables>(
    RealtyNearbyDocument,
    baseOptions
  );
}
export type RealtyNearbyQueryHookResult = ReturnType<typeof useRealtyNearbyQuery>;
export type RealtyNearbyLazyQueryHookResult = ReturnType<typeof useRealtyNearbyLazyQuery>;
export type RealtyNearbyQueryResult = ApolloReactCommon.QueryResult<
  RealtyNearbyQuery,
  RealtyNearbyQueryVariables
>;
export const RealtyPublishDocument = gql`
  mutation realtyPublish($realtyId: ID!) {
    realtyPublish(input: { id: $realtyId }) {
      errors {
        field
        message
      }
      realty {
        id
        status
        published
      }
    }
  }
`;
export type RealtyPublishMutationFn = ApolloReactCommon.MutationFunction<
  RealtyPublishMutation,
  RealtyPublishMutationVariables
>;
export function useRealtyPublishMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealtyPublishMutation,
    RealtyPublishMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<RealtyPublishMutation, RealtyPublishMutationVariables>(
    RealtyPublishDocument,
    baseOptions
  );
}
export type RealtyPublishMutationHookResult = ReturnType<typeof useRealtyPublishMutation>;
export type RealtyPublishMutationResult = ApolloReactCommon.MutationResult<RealtyPublishMutation>;
export type RealtyPublishMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RealtyPublishMutation,
  RealtyPublishMutationVariables
>;
export const RealtyThreadArchiveDocument = gql`
  mutation realtyThreadArchive($id: ID!) {
    realtyThreadArchive(input: { id: $id }) {
      realtyThread {
        id
        isArchived
      }
    }
  }
`;
export type RealtyThreadArchiveMutationFn = ApolloReactCommon.MutationFunction<
  RealtyThreadArchiveMutation,
  RealtyThreadArchiveMutationVariables
>;
export function useRealtyThreadArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealtyThreadArchiveMutation,
    RealtyThreadArchiveMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RealtyThreadArchiveMutation,
    RealtyThreadArchiveMutationVariables
  >(RealtyThreadArchiveDocument, baseOptions);
}
export type RealtyThreadArchiveMutationHookResult = ReturnType<
  typeof useRealtyThreadArchiveMutation
>;
export type RealtyThreadArchiveMutationResult = ApolloReactCommon.MutationResult<RealtyThreadArchiveMutation>;
export type RealtyThreadArchiveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RealtyThreadArchiveMutation,
  RealtyThreadArchiveMutationVariables
>;
export const RealtyThreadCreateDocument = gql`
  mutation realtyThreadCreate(
    $kind: RealtyThreadKind!
    $msg: String!
    $sender: ID!
    $recipient: ID!
    $realty: ID
  ) {
    realtyThreadCreate(
      input: { kind: $kind, msg: $msg, realty: $realty, sender: $sender, recipient: $recipient }
    ) {
      realtyThread {
        id
      }
      errors {
        message
        field
      }
    }
  }
`;
export type RealtyThreadCreateMutationFn = ApolloReactCommon.MutationFunction<
  RealtyThreadCreateMutation,
  RealtyThreadCreateMutationVariables
>;
export function useRealtyThreadCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealtyThreadCreateMutation,
    RealtyThreadCreateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RealtyThreadCreateMutation,
    RealtyThreadCreateMutationVariables
  >(RealtyThreadCreateDocument, baseOptions);
}
export type RealtyThreadCreateMutationHookResult = ReturnType<typeof useRealtyThreadCreateMutation>;
export type RealtyThreadCreateMutationResult = ApolloReactCommon.MutationResult<RealtyThreadCreateMutation>;
export type RealtyThreadCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RealtyThreadCreateMutation,
  RealtyThreadCreateMutationVariables
>;
export const RealtyThreadListDocument = gql`
  query realtyThreadList(
    $after: String
    $first: Int
    $isArchived: Boolean
    $recipient: ID
    $sender: ID
    $searchText: String
    $orderBy: [String]
  ) {
    realtyThreads(
      first: $first
      after: $after
      isArchived: $isArchived
      recipient: $recipient
      sender: $sender
      search: $searchText
      orderby: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          id
          kind
          isArchived
          isRead
          msg
          lastMsg
          realty {
            id
            code
          }
          sender {
            firstName
            lastName
            fullName
            avatarUrl
            optimizedAvatarUrls {
              sm
            }
          }
          recipient {
            id
            firstName
            lastName
            fullName
          }
          isRead
        }
      }
    }
  }
`;
export function useRealtyThreadListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RealtyThreadListQuery,
    RealtyThreadListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<RealtyThreadListQuery, RealtyThreadListQueryVariables>(
    RealtyThreadListDocument,
    baseOptions
  );
}
export function useRealtyThreadListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RealtyThreadListQuery,
    RealtyThreadListQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<RealtyThreadListQuery, RealtyThreadListQueryVariables>(
    RealtyThreadListDocument,
    baseOptions
  );
}
export type RealtyThreadListQueryHookResult = ReturnType<typeof useRealtyThreadListQuery>;
export type RealtyThreadListLazyQueryHookResult = ReturnType<typeof useRealtyThreadListLazyQuery>;
export type RealtyThreadListQueryResult = ApolloReactCommon.QueryResult<
  RealtyThreadListQuery,
  RealtyThreadListQueryVariables
>;
export const RealtyThreadMarkReadDocument = gql`
  mutation realtyThreadMarkRead($id: ID!) {
    realtyThreadMarkRead(input: { id: $id }) {
      realtyThread {
        id
        isRead
      }
    }
  }
`;
export type RealtyThreadMarkReadMutationFn = ApolloReactCommon.MutationFunction<
  RealtyThreadMarkReadMutation,
  RealtyThreadMarkReadMutationVariables
>;
export function useRealtyThreadMarkReadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealtyThreadMarkReadMutation,
    RealtyThreadMarkReadMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RealtyThreadMarkReadMutation,
    RealtyThreadMarkReadMutationVariables
  >(RealtyThreadMarkReadDocument, baseOptions);
}
export type RealtyThreadMarkReadMutationHookResult = ReturnType<
  typeof useRealtyThreadMarkReadMutation
>;
export type RealtyThreadMarkReadMutationResult = ApolloReactCommon.MutationResult<RealtyThreadMarkReadMutation>;
export type RealtyThreadMarkReadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RealtyThreadMarkReadMutation,
  RealtyThreadMarkReadMutationVariables
>;
export const RealtyThreadMessageCreateDocument = gql`
  mutation realtyThreadMessageCreate($msg: String!, $user: ID!, $thread: ID!) {
    realtyThreadMessageCreate(input: { thread: $thread, user: $user, msg: $msg }) {
      realtyThreadMessage {
        id
        createdAt
        user {
          firstName
          lastName
          fullName
          avatarUrl
          optimizedAvatarUrls {
            sm
          }
        }
        msg
      }
    }
  }
`;
export type RealtyThreadMessageCreateMutationFn = ApolloReactCommon.MutationFunction<
  RealtyThreadMessageCreateMutation,
  RealtyThreadMessageCreateMutationVariables
>;
export function useRealtyThreadMessageCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealtyThreadMessageCreateMutation,
    RealtyThreadMessageCreateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RealtyThreadMessageCreateMutation,
    RealtyThreadMessageCreateMutationVariables
  >(RealtyThreadMessageCreateDocument, baseOptions);
}
export type RealtyThreadMessageCreateMutationHookResult = ReturnType<
  typeof useRealtyThreadMessageCreateMutation
>;
export type RealtyThreadMessageCreateMutationResult = ApolloReactCommon.MutationResult<RealtyThreadMessageCreateMutation>;
export type RealtyThreadMessageCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RealtyThreadMessageCreateMutation,
  RealtyThreadMessageCreateMutationVariables
>;
export const RealtyThreadMessageListDocument = gql`
  query realtyThreadMessageList($thread: ID!) {
    realtyThreadMessages(thread: $thread) {
      edges {
        node {
          id
          createdAt
          user {
            firstName
            lastName
            fullName
            avatarUrl
            optimizedAvatarUrls {
              sm
            }
          }
          msg
        }
      }
    }
  }
`;
export function useRealtyThreadMessageListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RealtyThreadMessageListQuery,
    RealtyThreadMessageListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    RealtyThreadMessageListQuery,
    RealtyThreadMessageListQueryVariables
  >(RealtyThreadMessageListDocument, baseOptions);
}
export function useRealtyThreadMessageListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RealtyThreadMessageListQuery,
    RealtyThreadMessageListQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    RealtyThreadMessageListQuery,
    RealtyThreadMessageListQueryVariables
  >(RealtyThreadMessageListDocument, baseOptions);
}
export type RealtyThreadMessageListQueryHookResult = ReturnType<
  typeof useRealtyThreadMessageListQuery
>;
export type RealtyThreadMessageListLazyQueryHookResult = ReturnType<
  typeof useRealtyThreadMessageListLazyQuery
>;
export type RealtyThreadMessageListQueryResult = ApolloReactCommon.QueryResult<
  RealtyThreadMessageListQuery,
  RealtyThreadMessageListQueryVariables
>;
export const RealtyUnpublishDocument = gql`
  mutation realtyUnpublish($realtyId: ID!) {
    realtyUnpublish(input: { id: $realtyId }) {
      errors {
        field
        message
      }
      realty {
        id
        status
        published
      }
    }
  }
`;
export type RealtyUnpublishMutationFn = ApolloReactCommon.MutationFunction<
  RealtyUnpublishMutation,
  RealtyUnpublishMutationVariables
>;
export function useRealtyUnpublishMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealtyUnpublishMutation,
    RealtyUnpublishMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<RealtyUnpublishMutation, RealtyUnpublishMutationVariables>(
    RealtyUnpublishDocument,
    baseOptions
  );
}
export type RealtyUnpublishMutationHookResult = ReturnType<typeof useRealtyUnpublishMutation>;
export type RealtyUnpublishMutationResult = ApolloReactCommon.MutationResult<RealtyUnpublishMutation>;
export type RealtyUnpublishMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RealtyUnpublishMutation,
  RealtyUnpublishMutationVariables
>;
export const RealtyUpdateDocument = gql`
  mutation realtyUpdate($input: RealtyUpdateMutationInput!) {
    realtyUpdate(input: $input) {
      errors {
        field
        message
      }
      realty {
        id
        code
        category
        description
        isForSale
        isForRent
        isForExchange
        area
        ventureName
        bedrooms
        suites
        bathrooms
        garages
      }
    }
  }
`;
export type RealtyUpdateMutationFn = ApolloReactCommon.MutationFunction<
  RealtyUpdateMutation,
  RealtyUpdateMutationVariables
>;
export function useRealtyUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealtyUpdateMutation,
    RealtyUpdateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<RealtyUpdateMutation, RealtyUpdateMutationVariables>(
    RealtyUpdateDocument,
    baseOptions
  );
}
export type RealtyUpdateMutationHookResult = ReturnType<typeof useRealtyUpdateMutation>;
export type RealtyUpdateMutationResult = ApolloReactCommon.MutationResult<RealtyUpdateMutation>;
export type RealtyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RealtyUpdateMutation,
  RealtyUpdateMutationVariables
>;
export const RealtyUpdateManagerDocument = gql`
  mutation realtyUpdateManager(
    $realty: ID!
    $status: RealtyStatus!
    $observation: String
    $category: String
    $mainMedia: ID
    $duplicatedOf: ID
    $removedMedias: [ID]
    $published: Boolean
    $problem: RealtyThreadProblem
  ) {
    realtyUpdateManager(
      input: {
        id: $realty
        status: $status
        msg: $observation
        category: $category
        duplicatedOf: $duplicatedOf
        mainMedia: $mainMedia
        excludedMedias: $removedMedias
        published: $published
        problem: $problem
      }
    ) {
      errors {
        field
        message
      }
      realty {
        id
        status
        published
      }
    }
  }
`;
export type RealtyUpdateManagerMutationFn = ApolloReactCommon.MutationFunction<
  RealtyUpdateManagerMutation,
  RealtyUpdateManagerMutationVariables
>;
export function useRealtyUpdateManagerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealtyUpdateManagerMutation,
    RealtyUpdateManagerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RealtyUpdateManagerMutation,
    RealtyUpdateManagerMutationVariables
  >(RealtyUpdateManagerDocument, baseOptions);
}
export type RealtyUpdateManagerMutationHookResult = ReturnType<
  typeof useRealtyUpdateManagerMutation
>;
export type RealtyUpdateManagerMutationResult = ApolloReactCommon.MutationResult<RealtyUpdateManagerMutation>;
export type RealtyUpdateManagerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RealtyUpdateManagerMutation,
  RealtyUpdateManagerMutationVariables
>;
export const SalespersonClientsListDocument = gql`
  query salespersonClientsList($first: Int, $after: String, $orderby: [String], $search: String) {
    userPlansSalesperson(first: $first, after: $after, orderby: $orderby, search: $search) {
      totalCount
      pageInfo {
        ...pagInfoFields
      }
      edges {
        node {
          id
          subscriptionStatus
          advertisingName
          slotsTotal
          user {
            id
            email
            realtiesCounts {
              countPublished
            }
            currentIntegration {
              id
              origin
              processedAt
              foundCount
              foundNoPostalCodeCount
              status
              error
            }
          }
        }
      }
    }
  }
  ${PagInfoFieldsFragmentDoc}
`;
export function useSalespersonClientsListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SalespersonClientsListQuery,
    SalespersonClientsListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SalespersonClientsListQuery,
    SalespersonClientsListQueryVariables
  >(SalespersonClientsListDocument, baseOptions);
}
export function useSalespersonClientsListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalespersonClientsListQuery,
    SalespersonClientsListQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SalespersonClientsListQuery,
    SalespersonClientsListQueryVariables
  >(SalespersonClientsListDocument, baseOptions);
}
export type SalespersonClientsListQueryHookResult = ReturnType<
  typeof useSalespersonClientsListQuery
>;
export type SalespersonClientsListLazyQueryHookResult = ReturnType<
  typeof useSalespersonClientsListLazyQuery
>;
export type SalespersonClientsListQueryResult = ApolloReactCommon.QueryResult<
  SalespersonClientsListQuery,
  SalespersonClientsListQueryVariables
>;
export const SalespersonClientDetailsDocument = gql`
  query salespersonClientDetails($id: ID) {
    userPlan(id: $id) {
      id
      subscriptionStatus
      slotsTotal
      kind
      expiresAt
      salesperson {
        firstName
      }
      user {
        id
        username
        firstName
        lastName
        fullName
        advertisingName
        kind
        email
        avatarUrl
        hash
        phone
        cellphone
        sendContactEmails
        sendMessageEmails
        sendMonthlySummaryEmails
        allowScheduling
        postalCode
        complement
        street
        slug
        streetNumber
        district
        city {
          state {
            name
            code
          }
          name
        }
        optimizedAvatarUrls {
          sm
        }
        realtiesCounts {
          countActive
          countPublished
          countWaitingCuration
          countAvailable
          countDuplicated
          countRejected
          countAutomaticCuration
        }
        realtiesLeads {
          hits
          leads
          whatsapps
          phones
          contacts
          schedules
          totals
        }
        currentIntegration {
          id
          status
          updatedAt
          kind
          foundCount
          foundNoPostalCodeCount
          url
          name
          key
          preferredDistricts
          data
          processedAt
          createdAt
          isArchived
          origin
          priceMinSale
          priceMaxSale
          priceMinRent
          priceMaxRent
          priority
          preferredDistricts
          forSale
          forRent
          rentPercentage
          salePercentage
        }
      }
    }
  }
`;
export function useSalespersonClientDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SalespersonClientDetailsQuery,
    SalespersonClientDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SalespersonClientDetailsQuery,
    SalespersonClientDetailsQueryVariables
  >(SalespersonClientDetailsDocument, baseOptions);
}
export function useSalespersonClientDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalespersonClientDetailsQuery,
    SalespersonClientDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SalespersonClientDetailsQuery,
    SalespersonClientDetailsQueryVariables
  >(SalespersonClientDetailsDocument, baseOptions);
}
export type SalespersonClientDetailsQueryHookResult = ReturnType<
  typeof useSalespersonClientDetailsQuery
>;
export type SalespersonClientDetailsLazyQueryHookResult = ReturnType<
  typeof useSalespersonClientDetailsLazyQuery
>;
export type SalespersonClientDetailsQueryResult = ApolloReactCommon.QueryResult<
  SalespersonClientDetailsQuery,
  SalespersonClientDetailsQueryVariables
>;
export const SearchDocument = gql`
  query Search(
    $q: String!
    $first: Int = 100
    $kind_in: [String]
    $kind: String
    $geosort: GeoPoint
    $citySlug: String
    $stateSlug: String
  ) {
    search(
      q: $q
      first: $first
      kind_In: $kind_in
      geosort: $geosort
      kind: $kind
      city_Slug: $citySlug
      state_Slug: $stateSlug
    ) {
      edges {
        node {
          kind
          city {
            id
            name
            slug
            state {
              id
              name
              code
              slug
            }
          }
          district
          districtSlug
          street
          venture {
            id
            name
            slug
          }
          rank
          id
          distance
        }
      }
    }
  }
`;
export function useSearchQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  return ApolloReactHooks.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions);
}
export function useSearchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    baseOptions
  );
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = ApolloReactCommon.QueryResult<SearchQuery, SearchQueryVariables>;
export const SearchHitCreateDocument = gql`
  mutation SearchHitCreate(
    $kind: SearchHitKind!
    $state: String
    $city: String
    $district: String
    $page: Int!
    $filters: JSONString!
    $utmSource: String
    $utmMedium: String
    $utmCampaign: String
    $utmContent: String
  ) {
    searchHitCreate(
      input: {
        kind: $kind
        stateSlug: $state
        citySlug: $city
        districtSlug: $district
        page: $page
        filters: $filters
        utmSource: $utmSource
        utmMedium: $utmMedium
        utmCampaign: $utmCampaign
        utmContent: $utmContent
      }
    ) {
      errors {
        field
        message
      }
    }
  }
`;
export type SearchHitCreateMutationFn = ApolloReactCommon.MutationFunction<
  SearchHitCreateMutation,
  SearchHitCreateMutationVariables
>;
export function useSearchHitCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SearchHitCreateMutation,
    SearchHitCreateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<SearchHitCreateMutation, SearchHitCreateMutationVariables>(
    SearchHitCreateDocument,
    baseOptions
  );
}
export type SearchHitCreateMutationHookResult = ReturnType<typeof useSearchHitCreateMutation>;
export type SearchHitCreateMutationResult = ApolloReactCommon.MutationResult<SearchHitCreateMutation>;
export type SearchHitCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SearchHitCreateMutation,
  SearchHitCreateMutationVariables
>;
export const SearchTopResultsDocument = gql`
  query SearchTopResults($first: Int = 10) {
    sale: searchTopResults(kind: SALE, first: $first) {
      ...TopSearch
    }
    rent: searchTopResults(kind: RENT, first: $first) {
      ...TopSearch
    }
    region: searchTopResults(kind: REGION, first: $first) {
      ...TopSearch
    }
  }
  ${TopSearchFragmentDoc}
`;
export function useSearchTopResultsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SearchTopResultsQuery,
    SearchTopResultsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SearchTopResultsQuery, SearchTopResultsQueryVariables>(
    SearchTopResultsDocument,
    baseOptions
  );
}
export function useSearchTopResultsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SearchTopResultsQuery,
    SearchTopResultsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SearchTopResultsQuery, SearchTopResultsQueryVariables>(
    SearchTopResultsDocument,
    baseOptions
  );
}
export type SearchTopResultsQueryHookResult = ReturnType<typeof useSearchTopResultsQuery>;
export type SearchTopResultsLazyQueryHookResult = ReturnType<typeof useSearchTopResultsLazyQuery>;
export type SearchTopResultsQueryResult = ApolloReactCommon.QueryResult<
  SearchTopResultsQuery,
  SearchTopResultsQueryVariables
>;
export const SitemapLocationsDocument = gql`
  query SitemapLocations {
    locationsSitemap {
      districtSlug
      citySlug
      stateSlug
      category
      finality
      updatedAt
    }
  }
`;
export function useSitemapLocationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SitemapLocationsQuery,
    SitemapLocationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SitemapLocationsQuery, SitemapLocationsQueryVariables>(
    SitemapLocationsDocument,
    baseOptions
  );
}
export function useSitemapLocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SitemapLocationsQuery,
    SitemapLocationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SitemapLocationsQuery, SitemapLocationsQueryVariables>(
    SitemapLocationsDocument,
    baseOptions
  );
}
export type SitemapLocationsQueryHookResult = ReturnType<typeof useSitemapLocationsQuery>;
export type SitemapLocationsLazyQueryHookResult = ReturnType<typeof useSitemapLocationsLazyQuery>;
export type SitemapLocationsQueryResult = ApolloReactCommon.QueryResult<
  SitemapLocationsQuery,
  SitemapLocationsQueryVariables
>;
export const SitemapRealtiesDocument = gql`
  query SitemapRealties($forPartnership: Boolean, $withMedias: Boolean = false) {
    realtiesSitemap(forPartnership: $forPartnership) {
      id
      updatedAt
      category
      location {
        id
        districtSlug
        district
        street
        postalCode
        city {
          id
          slug
          name
          state {
            id
            slug
            code
            name
            country {
              code
              name
            }
          }
        }
        geopoint
      }
      isForSale
      isForRent
      isForVacation
      isForExchange
      salePrice
      rentPrice
      condominiumPrice
      iptuPrice
      vacationPrice
      bathrooms
      suites
      garages
      bedrooms
      commercialDistrict
      fulltimeSecurity
      swimmingPool
      greenArea
      playground
      highlighted
      area
      user {
        id
        kind
        advertisingName
        fullName
      }
      updatedAt
      medias(first: 25) @include(if: $withMedias) {
        id
        url
      }
    }
  }
`;
export function useSitemapRealtiesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SitemapRealtiesQuery,
    SitemapRealtiesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SitemapRealtiesQuery, SitemapRealtiesQueryVariables>(
    SitemapRealtiesDocument,
    baseOptions
  );
}
export function useSitemapRealtiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SitemapRealtiesQuery,
    SitemapRealtiesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SitemapRealtiesQuery, SitemapRealtiesQueryVariables>(
    SitemapRealtiesDocument,
    baseOptions
  );
}
export type SitemapRealtiesQueryHookResult = ReturnType<typeof useSitemapRealtiesQuery>;
export type SitemapRealtiesLazyQueryHookResult = ReturnType<typeof useSitemapRealtiesLazyQuery>;
export type SitemapRealtiesQueryResult = ApolloReactCommon.QueryResult<
  SitemapRealtiesQuery,
  SitemapRealtiesQueryVariables
>;
export const SitemapUsersDocument = gql`
  query SitemapUsers {
    usersSitemap {
      lastRealtyDate
      slug
    }
  }
`;
export function useSitemapUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SitemapUsersQuery, SitemapUsersQueryVariables>
) {
  return ApolloReactHooks.useQuery<SitemapUsersQuery, SitemapUsersQueryVariables>(
    SitemapUsersDocument,
    baseOptions
  );
}
export function useSitemapUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SitemapUsersQuery, SitemapUsersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<SitemapUsersQuery, SitemapUsersQueryVariables>(
    SitemapUsersDocument,
    baseOptions
  );
}
export type SitemapUsersQueryHookResult = ReturnType<typeof useSitemapUsersQuery>;
export type SitemapUsersLazyQueryHookResult = ReturnType<typeof useSitemapUsersLazyQuery>;
export type SitemapUsersQueryResult = ApolloReactCommon.QueryResult<
  SitemapUsersQuery,
  SitemapUsersQueryVariables
>;
export const SitemapVenturesDocument = gql`
  query SitemapVentures {
    venturesSitemap {
      id
      name
      slug
      lastRealtyDate
    }
  }
`;
export function useSitemapVenturesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SitemapVenturesQuery,
    SitemapVenturesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SitemapVenturesQuery, SitemapVenturesQueryVariables>(
    SitemapVenturesDocument,
    baseOptions
  );
}
export function useSitemapVenturesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SitemapVenturesQuery,
    SitemapVenturesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SitemapVenturesQuery, SitemapVenturesQueryVariables>(
    SitemapVenturesDocument,
    baseOptions
  );
}
export type SitemapVenturesQueryHookResult = ReturnType<typeof useSitemapVenturesQuery>;
export type SitemapVenturesLazyQueryHookResult = ReturnType<typeof useSitemapVenturesLazyQuery>;
export type SitemapVenturesQueryResult = ApolloReactCommon.QueryResult<
  SitemapVenturesQuery,
  SitemapVenturesQueryVariables
>;
export const SubscribeNewsletterDocument = gql`
  mutation subscribeNewsletter($email: String!) {
    crmNewsletterCreate(input: { email: $email }) {
      errors {
        field
        message
      }
      crmNewsletter {
        email
      }
    }
  }
`;
export type SubscribeNewsletterMutationFn = ApolloReactCommon.MutationFunction<
  SubscribeNewsletterMutation,
  SubscribeNewsletterMutationVariables
>;
export function useSubscribeNewsletterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubscribeNewsletterMutation,
    SubscribeNewsletterMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SubscribeNewsletterMutation,
    SubscribeNewsletterMutationVariables
  >(SubscribeNewsletterDocument, baseOptions);
}
export type SubscribeNewsletterMutationHookResult = ReturnType<
  typeof useSubscribeNewsletterMutation
>;
export type SubscribeNewsletterMutationResult = ApolloReactCommon.MutationResult<SubscribeNewsletterMutation>;
export type SubscribeNewsletterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubscribeNewsletterMutation,
  SubscribeNewsletterMutationVariables
>;
export const SupportDocument = gql`
  query support($doc: String!, $code: String!) {
    userSupport(doc: $doc) {
      id
      username
      firstName
      lastName
      fullName
      email
      phone
      cellphone
      street
      streetNumber
      district
      postalCode
      permissions
      sendContactEmails
      sendMessageEmails
      sendMonthlySummaryEmails
      realtiesCounts {
        count
        countPublished
        countActive
        countWaitingCuration
        countAvailable
        countRejected
        countAutomaticCuration
      }
      kind
      city {
        state {
          name
        }
        name
      }
      avatarUrl
      optimizedAvatarUrls {
        sm
      }
      realtiesCached
      currentPlan {
        kind
        slotsTotal
        plan {
          name
          quantity
        }
        salesperson {
          id
          fullName
          email
        }
      }
    }
    realtySupport(doc: $doc, code: $code) {
      id
      status
      code
      category
      isForSale
      salePrice
      iptuPrice
      isForRent
      isForVacation
      rentPrice
      isForExchange
      vacationPrice
      condominiumPrice
      videoUrl
      streetNumber
      complement
      area
      ventureName
      bedrooms
      suites
      bathrooms
      garages
      user {
        id
        fullName
      }
      location {
        id
        postalCode
        street
        district
        city {
          id
          name
          state {
            id
            code
            slug
            name
          }
        }
      }
      medias {
        id
        order
        url
        isMain
        optimizedUrls {
          xs
        }
      }
    }
  }
`;
export function useSupportQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SupportQuery, SupportQueryVariables>
) {
  return ApolloReactHooks.useQuery<SupportQuery, SupportQueryVariables>(
    SupportDocument,
    baseOptions
  );
}
export function useSupportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SupportQuery, SupportQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<SupportQuery, SupportQueryVariables>(
    SupportDocument,
    baseOptions
  );
}
export type SupportQueryHookResult = ReturnType<typeof useSupportQuery>;
export type SupportLazyQueryHookResult = ReturnType<typeof useSupportLazyQuery>;
export type SupportQueryResult = ApolloReactCommon.QueryResult<SupportQuery, SupportQueryVariables>;
export const UserActivateDocument = gql`
  mutation UserActivate($uid: String!, $token: String!) {
    userActivate(input: { uid: $uid, token: $token }) {
      errors {
        field
        message
      }
      success
    }
  }
`;
export type UserActivateMutationFn = ApolloReactCommon.MutationFunction<
  UserActivateMutation,
  UserActivateMutationVariables
>;
export function useUserActivateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserActivateMutation,
    UserActivateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UserActivateMutation, UserActivateMutationVariables>(
    UserActivateDocument,
    baseOptions
  );
}
export type UserActivateMutationHookResult = ReturnType<typeof useUserActivateMutation>;
export type UserActivateMutationResult = ApolloReactCommon.MutationResult<UserActivateMutation>;
export type UserActivateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserActivateMutation,
  UserActivateMutationVariables
>;
export const UserBySlugDocument = gql`
  query UserBySlug($userSlug: String!) {
    user(slug: $userSlug) {
      id
      slug
      advertisingName
      phone
      cellphone
      hasWhatsapp
      optimized
      avatarUrl
      kind
      realtiesCached
      creci
      optimizedAvatarUrls {
        sm
        xs
      }
    }
  }
`;
export function useUserBySlugQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserBySlugQuery, UserBySlugQueryVariables>
) {
  return ApolloReactHooks.useQuery<UserBySlugQuery, UserBySlugQueryVariables>(
    UserBySlugDocument,
    baseOptions
  );
}
export function useUserBySlugLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserBySlugQuery, UserBySlugQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UserBySlugQuery, UserBySlugQueryVariables>(
    UserBySlugDocument,
    baseOptions
  );
}
export type UserBySlugQueryHookResult = ReturnType<typeof useUserBySlugQuery>;
export type UserBySlugLazyQueryHookResult = ReturnType<typeof useUserBySlugLazyQuery>;
export type UserBySlugQueryResult = ApolloReactCommon.QueryResult<
  UserBySlugQuery,
  UserBySlugQueryVariables
>;
export const UserChangePasswordDocument = gql`
  mutation userChangePassword($oldPassword: String, $newPassword: String!, $user: ID!) {
    userChangePassword(
      input: {
        oldPassword: $oldPassword
        password: $newPassword
        rePassword: $newPassword
        id: $user
      }
    ) {
      errors {
        field
        message
      }
      success
    }
  }
`;
export type UserChangePasswordMutationFn = ApolloReactCommon.MutationFunction<
  UserChangePasswordMutation,
  UserChangePasswordMutationVariables
>;
export function useUserChangePasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserChangePasswordMutation,
    UserChangePasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UserChangePasswordMutation,
    UserChangePasswordMutationVariables
  >(UserChangePasswordDocument, baseOptions);
}
export type UserChangePasswordMutationHookResult = ReturnType<typeof useUserChangePasswordMutation>;
export type UserChangePasswordMutationResult = ApolloReactCommon.MutationResult<UserChangePasswordMutation>;
export type UserChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserChangePasswordMutation,
  UserChangePasswordMutationVariables
>;
export const UserChangePaymentMethodDocument = gql`
  mutation userChangePaymentMethod($input: UserChangePaymentMethodMutationInput!) {
    userChangePaymentMethod(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;
export type UserChangePaymentMethodMutationFn = ApolloReactCommon.MutationFunction<
  UserChangePaymentMethodMutation,
  UserChangePaymentMethodMutationVariables
>;
export function useUserChangePaymentMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserChangePaymentMethodMutation,
    UserChangePaymentMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UserChangePaymentMethodMutation,
    UserChangePaymentMethodMutationVariables
  >(UserChangePaymentMethodDocument, baseOptions);
}
export type UserChangePaymentMethodMutationHookResult = ReturnType<
  typeof useUserChangePaymentMethodMutation
>;
export type UserChangePaymentMethodMutationResult = ApolloReactCommon.MutationResult<UserChangePaymentMethodMutation>;
export type UserChangePaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserChangePaymentMethodMutation,
  UserChangePaymentMethodMutationVariables
>;
export const UserNewPlanDocument = gql`
  mutation userNewPlan($input: UserNewPlanMutationInput!) {
    userNewPlan(input: $input) {
      errors {
        field
        message
      }
      success
    }
  }
`;
export type UserNewPlanMutationFn = ApolloReactCommon.MutationFunction<
  UserNewPlanMutation,
  UserNewPlanMutationVariables
>;
export function useUserNewPlanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserNewPlanMutation,
    UserNewPlanMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UserNewPlanMutation, UserNewPlanMutationVariables>(
    UserNewPlanDocument,
    baseOptions
  );
}
export type UserNewPlanMutationHookResult = ReturnType<typeof useUserNewPlanMutation>;
export type UserNewPlanMutationResult = ApolloReactCommon.MutationResult<UserNewPlanMutation>;
export type UserNewPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserNewPlanMutation,
  UserNewPlanMutationVariables
>;
export const UserPasswordConfirmDocument = gql`
  mutation UserPasswordConfirm(
    $uid: String!
    $token: String!
    $password: String!
    $rePassword: String!
  ) {
    userPasswordConfirm(
      input: { uid: $uid, token: $token, password: $password, rePassword: $rePassword }
    ) {
      errors {
        field
        message
      }
      success
    }
  }
`;
export type UserPasswordConfirmMutationFn = ApolloReactCommon.MutationFunction<
  UserPasswordConfirmMutation,
  UserPasswordConfirmMutationVariables
>;
export function useUserPasswordConfirmMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserPasswordConfirmMutation,
    UserPasswordConfirmMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UserPasswordConfirmMutation,
    UserPasswordConfirmMutationVariables
  >(UserPasswordConfirmDocument, baseOptions);
}
export type UserPasswordConfirmMutationHookResult = ReturnType<
  typeof useUserPasswordConfirmMutation
>;
export type UserPasswordConfirmMutationResult = ApolloReactCommon.MutationResult<UserPasswordConfirmMutation>;
export type UserPasswordConfirmMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserPasswordConfirmMutation,
  UserPasswordConfirmMutationVariables
>;
export const UserPaymentMethodDeleteDocument = gql`
  mutation userPaymentMethodDelete($input: UserPaymentMethodDeleteMutationInput!) {
    userPaymentMethodDelete(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;
export type UserPaymentMethodDeleteMutationFn = ApolloReactCommon.MutationFunction<
  UserPaymentMethodDeleteMutation,
  UserPaymentMethodDeleteMutationVariables
>;
export function useUserPaymentMethodDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserPaymentMethodDeleteMutation,
    UserPaymentMethodDeleteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UserPaymentMethodDeleteMutation,
    UserPaymentMethodDeleteMutationVariables
  >(UserPaymentMethodDeleteDocument, baseOptions);
}
export type UserPaymentMethodDeleteMutationHookResult = ReturnType<
  typeof useUserPaymentMethodDeleteMutation
>;
export type UserPaymentMethodDeleteMutationResult = ApolloReactCommon.MutationResult<UserPaymentMethodDeleteMutation>;
export type UserPaymentMethodDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserPaymentMethodDeleteMutation,
  UserPaymentMethodDeleteMutationVariables
>;
export const UserPlanDocument = gql`
  query UserPlan($token: UUID!) {
    userPlan(token: $token) {
      id
      expiresAt
      kind
      token
      emailConfirmed
      name
      email
      kind
      userKind
      promotionKind
      doc
      price
      promotionPrice
      promotionMonths
      slotsTotal
      plan {
        id
        kind
        code
        country {
          id
          name
          code
        }
        name
        slug
        public
        price
        quantity
      }
      city {
        name
        state {
          name
          code
        }
      }
      postalCode
      district
      streetNumber
      complement
      user {
        firstName
        lastName
        fullName
        kind
        advertisingName
        phone
        cellphone
        city {
          name
          slug
          state {
            name
            code
          }
        }
        id
        email
        doc
      }
    }
  }
`;
export function useUserPlanQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserPlanQuery, UserPlanQueryVariables>
) {
  return ApolloReactHooks.useQuery<UserPlanQuery, UserPlanQueryVariables>(
    UserPlanDocument,
    baseOptions
  );
}
export function useUserPlanLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserPlanQuery, UserPlanQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UserPlanQuery, UserPlanQueryVariables>(
    UserPlanDocument,
    baseOptions
  );
}
export type UserPlanQueryHookResult = ReturnType<typeof useUserPlanQuery>;
export type UserPlanLazyQueryHookResult = ReturnType<typeof useUserPlanLazyQuery>;
export type UserPlanQueryResult = ApolloReactCommon.QueryResult<
  UserPlanQuery,
  UserPlanQueryVariables
>;
export const UserPlanCreateDocument = gql`
  mutation userPlanCreate($input: UserPlanCreateMutationInput!) {
    userPlanCreate(input: $input) {
      errors {
        field
        message
      }
      userPlan {
        id
        token
        createdAt
      }
    }
  }
`;
export type UserPlanCreateMutationFn = ApolloReactCommon.MutationFunction<
  UserPlanCreateMutation,
  UserPlanCreateMutationVariables
>;
export function useUserPlanCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserPlanCreateMutation,
    UserPlanCreateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UserPlanCreateMutation, UserPlanCreateMutationVariables>(
    UserPlanCreateDocument,
    baseOptions
  );
}
export type UserPlanCreateMutationHookResult = ReturnType<typeof useUserPlanCreateMutation>;
export type UserPlanCreateMutationResult = ApolloReactCommon.MutationResult<UserPlanCreateMutation>;
export type UserPlanCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserPlanCreateMutation,
  UserPlanCreateMutationVariables
>;
export const UserPlanCreateUserDocument = gql`
  mutation UserPlanCreateUser($input: UserPlanCreateUserMutationInput!) {
    userPlanCreateUser(input: $input) {
      errors {
        field
        message
      }
      success
      user {
        id
      }
      userPlan {
        id
        token
      }
    }
  }
`;
export type UserPlanCreateUserMutationFn = ApolloReactCommon.MutationFunction<
  UserPlanCreateUserMutation,
  UserPlanCreateUserMutationVariables
>;
export function useUserPlanCreateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserPlanCreateUserMutation,
    UserPlanCreateUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UserPlanCreateUserMutation,
    UserPlanCreateUserMutationVariables
  >(UserPlanCreateUserDocument, baseOptions);
}
export type UserPlanCreateUserMutationHookResult = ReturnType<typeof useUserPlanCreateUserMutation>;
export type UserPlanCreateUserMutationResult = ApolloReactCommon.MutationResult<UserPlanCreateUserMutation>;
export type UserPlanCreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserPlanCreateUserMutation,
  UserPlanCreateUserMutationVariables
>;
export const UserPlanSetPassswordDocument = gql`
  mutation UserPlanSetPasssword($input: UserPlanSetPasswordMutationInput!) {
    userPlanSetPassword(input: $input) {
      errors {
        field
        message
      }
      success
      userPlan {
        id
        token
      }
      user {
        id
      }
    }
  }
`;
export type UserPlanSetPassswordMutationFn = ApolloReactCommon.MutationFunction<
  UserPlanSetPassswordMutation,
  UserPlanSetPassswordMutationVariables
>;
export function useUserPlanSetPassswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserPlanSetPassswordMutation,
    UserPlanSetPassswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UserPlanSetPassswordMutation,
    UserPlanSetPassswordMutationVariables
  >(UserPlanSetPassswordDocument, baseOptions);
}
export type UserPlanSetPassswordMutationHookResult = ReturnType<
  typeof useUserPlanSetPassswordMutation
>;
export type UserPlanSetPassswordMutationResult = ApolloReactCommon.MutationResult<UserPlanSetPassswordMutation>;
export type UserPlanSetPassswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserPlanSetPassswordMutation,
  UserPlanSetPassswordMutationVariables
>;
export const UserPlanUpdateDocument = gql`
  mutation userPlanUpdate($input: UserPlanUpdateMutationInput!) {
    userPlanUpdate(input: $input) {
      errors {
        field
        message
      }
      userPlan {
        id
        createdAt
      }
    }
  }
`;
export type UserPlanUpdateMutationFn = ApolloReactCommon.MutationFunction<
  UserPlanUpdateMutation,
  UserPlanUpdateMutationVariables
>;
export function useUserPlanUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserPlanUpdateMutation,
    UserPlanUpdateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UserPlanUpdateMutation, UserPlanUpdateMutationVariables>(
    UserPlanUpdateDocument,
    baseOptions
  );
}
export type UserPlanUpdateMutationHookResult = ReturnType<typeof useUserPlanUpdateMutation>;
export type UserPlanUpdateMutationResult = ApolloReactCommon.MutationResult<UserPlanUpdateMutation>;
export type UserPlanUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserPlanUpdateMutation,
  UserPlanUpdateMutationVariables
>;
export const UserResetPasswordDocument = gql`
  mutation UserResetPassword($email: String!) {
    userResetPassword(input: { email: $email }) {
      errors {
        field
        message
      }
      success
    }
  }
`;
export type UserResetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  UserResetPasswordMutation,
  UserResetPasswordMutationVariables
>;
export function useUserResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserResetPasswordMutation,
    UserResetPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UserResetPasswordMutation,
    UserResetPasswordMutationVariables
  >(UserResetPasswordDocument, baseOptions);
}
export type UserResetPasswordMutationHookResult = ReturnType<typeof useUserResetPasswordMutation>;
export type UserResetPasswordMutationResult = ApolloReactCommon.MutationResult<UserResetPasswordMutation>;
export type UserResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserResetPasswordMutation,
  UserResetPasswordMutationVariables
>;
export const UserUpdateDocument = gql`
  mutation userUpdate($input: UserUpdateMutationInput!) {
    userUpdate(input: $input) {
      errors {
        field
        message
      }
      user {
        id
        username
        firstName
        lastName
        fullName
        advertisingName
        email
        phone
        cellphone
        street
        streetNumber
        district
        postalCode
        permissions
        sendContactEmails
        sendMessageEmails
        sendMonthlySummaryEmails
        contactSaleCcEmails
        contactRentCcEmails
        allowScheduling
        complement
        kind
      }
    }
  }
`;
export type UserUpdateMutationFn = ApolloReactCommon.MutationFunction<
  UserUpdateMutation,
  UserUpdateMutationVariables
>;
export function useUserUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserUpdateMutation,
    UserUpdateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(
    UserUpdateDocument,
    baseOptions
  );
}
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = ApolloReactCommon.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserUpdateMutation,
  UserUpdateMutationVariables
>;
export const UsersDocument = gql`
  query users {
    users(orderby: "advertisingName") {
      edges {
        node {
          id
          kind
          realtiesCached
          slug
          realtiesCounts {
            count
          }
          advertisingName
          avatarUrl
          optimized
          optimizedAvatarUrls {
            sm
          }
        }
      }
    }
  }
`;
export function useUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
}
export function useUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const VentureDocument = gql`
  query Venture(
    $id: ID!
    $state: String
    $city: String
    $district: String
    $category: String
    $isForSale: Boolean
    $isForRent: Boolean
    $isForExchange: Boolean
    $isForVacation: Boolean
    $street: String
    $bedrooms: Int
    $garages: Int
    $bathrooms: Int
    $suites: Int
    $salePriceMin: Float
    $salePriceMax: Float
    $rentPriceMin: Float
    $rentPriceMax: Float
    $condominiumPriceMin: Float
    $condominiumPriceMax: Float
    $iptuPriceMin: Float
    $iptuPriceMax: Float
    $areaMin: Float
    $areaMax: Float
    $first: Int = 18
    $cursor: String
    $orderby: [String]
    $geosort: GeoPoint
    $user: ID
    $forPartnership: Boolean
  ) {
    venture(id: $id) {
      id
      name
      slug
      location {
        id
        postalCode
        city {
          id
          name
          state {
            id
            code
          }
        }
        street
        district
      }
      realties(
        location_City_State_Slug: $state
        location_City_Slug: $city
        location_DistrictSlug: $district
        category: $category
        isForSale: $isForSale
        isForRent: $isForRent
        isForExchange: $isForExchange
        isForVacation: $isForVacation
        bedrooms_Gte: $bedrooms
        garages_Gte: $garages
        bathrooms_Gte: $bathrooms
        suites_Gte: $suites
        salePrice_Gte: $salePriceMin
        salePrice_Lte: $salePriceMax
        rentPrice_Gte: $rentPriceMin
        rentPrice_Lte: $rentPriceMax
        condominiumPrice_Gte: $condominiumPriceMin
        condominiumPrice_Lte: $condominiumPriceMax
        iptuPrice_Gte: $iptuPriceMin
        iptuPrice_Lte: $iptuPriceMax
        area_Gte: $areaMin
        area_Lte: $areaMax
        first: $first
        after: $cursor
        orderby: $orderby
        geosort: $geosort
        user: $user
        location_Street: $street
        forPartnership: $forPartnership
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        filters {
          minSalePrice
          minRentPrice
          minVacationPrice
          minCondomoniumPrice
          maxArea
          minArea
          maxBedrooms
          minBedrooms
          maxBathrooms
          minBathrooms
          maxSuites
          minSuites
          maxGarages
          minGarages
          fulltimeSecurity
          playground
          greenArea
        }
        edges {
          node {
            id
            ventureName
            salePrice
            rentPrice
            vacationPrice
            condominiumPrice
            iptuPrice
            isForSale
            isForRent
            isForVacation
            isForExchange
            allowNegotiation
            allowFinancing
            category
            area
            bedrooms
            suites
            bathrooms
            garages
            energyEfficiency
            distance
            user {
              id
              kind
            }
            location {
              city {
                id
                name
                slug
                state {
                  id
                  name
                  code
                  slug
                }
              }
              district
              districtSlug
              street
              id
            }
            commercialDistrict
            streetNumber
            medias(first: 12) {
              url
              caption
              description
              isMain
              optimizedUrls {
                md
                sm
                xs
              }
              optimized
            }
            isCurated
          }
        }
      }
    }
  }
`;
export function useVentureQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<VentureQuery, VentureQueryVariables>
) {
  return ApolloReactHooks.useQuery<VentureQuery, VentureQueryVariables>(
    VentureDocument,
    baseOptions
  );
}
export function useVentureLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VentureQuery, VentureQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<VentureQuery, VentureQueryVariables>(
    VentureDocument,
    baseOptions
  );
}
export type VentureQueryHookResult = ReturnType<typeof useVentureQuery>;
export type VentureLazyQueryHookResult = ReturnType<typeof useVentureLazyQuery>;
export type VentureQueryResult = ApolloReactCommon.QueryResult<VentureQuery, VentureQueryVariables>;
export const ZipcodeSearchDocument = gql`
  query zipcodeSearch($postalCode: String!, $token: String) {
    locationByPostalCode(postalCode: $postalCode, token: $token) {
      city {
        id
        name
        slug
        state {
          id
          name
          code
          slug
        }
      }
      street
      district
      id
    }
  }
`;
export function useZipcodeSearchQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ZipcodeSearchQuery, ZipcodeSearchQueryVariables>
) {
  return ApolloReactHooks.useQuery<ZipcodeSearchQuery, ZipcodeSearchQueryVariables>(
    ZipcodeSearchDocument,
    baseOptions
  );
}
export function useZipcodeSearchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ZipcodeSearchQuery,
    ZipcodeSearchQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ZipcodeSearchQuery, ZipcodeSearchQueryVariables>(
    ZipcodeSearchDocument,
    baseOptions
  );
}
export type ZipcodeSearchQueryHookResult = ReturnType<typeof useZipcodeSearchQuery>;
export type ZipcodeSearchLazyQueryHookResult = ReturnType<typeof useZipcodeSearchLazyQuery>;
export type ZipcodeSearchQueryResult = ApolloReactCommon.QueryResult<
  ZipcodeSearchQuery,
  ZipcodeSearchQueryVariables
>;
// Generated on 2021-11-17T22:12:45-03:00
